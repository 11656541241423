import React from "react";
import useColors from "../firebaseFiles/hooks/useColors";
const ColorsComponent = () => {
  const { colors } = useColors();

  // Update CSS variables dynamically when colors change
  if (colors) {
    document.documentElement.style.setProperty(
      "--click-color",
      colors.clickColor
    );

    document.documentElement.style.setProperty(
      "--heading-color",
      colors.headingColor
    );

    document.documentElement.style.setProperty("--card-bg", colors.cardBgColor);

    document.documentElement.style.setProperty(
      "--subheading-color",
      colors.subheadingColor
    );
  }

  return <div></div>;
};

export default ColorsComponent;
