import { React, useState, useEffect } from "react";
import "./IconsSettings.scss";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";

const IconsSettings = () => {
  const { user } = useAuthContext();

  const { updateDocument, deleteDocument } = useFirestore("settings");
  const [activeIcon, setActiveIcon] = useState("");

  const { document: iconsTypeDoc, error: iconsTypeDocerror } = useDocument(
    "settings",
    "Icons"
  );

  useEffect(() => {
    if (iconsTypeDoc) {
      setActiveIcon(iconsTypeDoc.activeIcon || "");
    }
  }, [iconsTypeDoc]);

  const iconsTypeLabels = {
    whatsapp: "Whatsapp Icon",
    addbooking: "Add Booking Icon",
    call: "Call Icon",
    support: "Help And Support Icon",
    notification: "Notification Icon",
    map: "Map Icon",
  };

  const handleToggleChange = async (fieldName, currentValue) => {
    // Compute the new value based on the current value
    const newValue = !currentValue;

    try {
      // Update the corresponding field in Firestore
      await updateDocument("Icons", {
        [`BottomRightFixedIcons.${fieldName}`]: newValue,
      });
    } catch (error) {
      console.error("Error updating icon status:", error);
    }
  };

  return (
    <div>
      <div className="container">
        {user && user.roles.includes("admin") && (
          <div className="role">
            <div className="section-title">
              <p>Floating Icons</p>
              <h2>You Can Change Visibility</h2>
            </div>
            <div className="radio-container">
              {Object.entries(iconsTypeLabels).map(
                ([fieldName, label], index) => {
                  // Define currentValue inside the map function
                  const currentValue =
                    !!iconsTypeDoc &&
                    !!iconsTypeDoc.BottomRightFixedIcons &&
                    !!iconsTypeDoc.BottomRightFixedIcons[fieldName];
                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div style={{ marginTop: "30px" }}>
                        <h4
                          style={{
                            fontWeight: "700",
                            color: "var(--heading-color)",
                          }}
                        >
                          {label}
                        </h4>
                        <p>Change Your Floating Icon Visibility</p>
                      </div>

                      {/* ACTIVE INACTIVE SWITCH */}
                      <div style={{ margin: "0px 0px 20px 15px" }}>
                        <MDBRow>
                          <MDBCol className="col-9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex align-items-center mt-2">
                                <div>
                                  <p
                                    className="active-inactive-text"
                                    style={{ color: "red" }}
                                  >
                                    Inactive
                                  </p>
                                </div>

                                <ReactSwitch
                                  checked={currentValue}
                                  onChange={() =>
                                    handleToggleChange(fieldName, currentValue)
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p
                                    className="active-inactive-text"
                                    style={{ color: "red" }}
                                  >
                                    Active
                                  </p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
        <br />
        <p style={{ fontSize: "13px" }}>
          <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
            Note :
          </span>
          Icon Settings: You can hide or unhide your bottom right corner
          floating icons according to your preference
        </p>
      </div>
    </div>
  );
};

export default IconsSettings;
