import React from 'react'

// import component 
import CorporateOneBanner from '../../corporateOneComponent/CorporateOneBanner'
import CorporateOneServices from '../../corporateOneComponent/CorporateOneServices'
import CorporateOneBitAbout from '../../corporateOneComponent/CorporateOneBitAbout'
import CorporateOneWhyUs from '../../corporateOneComponent/CorporateOneWhyUs'
import CorporateOnePartner from '../../corporateOneComponent/CorporateOnePartner'
import CorporateOneCounter from '../../corporateOneComponent/CorporateOneCounter'
import CorporateOneFeature from '../../corporateOneComponent/CorporateOneFeature'
import CorporateOneTestimonial from '../../corporateOneComponent/CorporateOneTestimonial'
import CorporateOneFooter from '../../corporateOneComponent/CorporateOneFooter'
import CorporateFooterBottom from '../../corporateOneComponent/CorporateFooterBottom'


const CorporateOneHome = () => {
  return (
    <div className='corporate_one_home'>
      <CorporateOneBanner />
      <section className="home_services">
      <CorporateOneServices/>
      <CorporateOneBitAbout/>
      <CorporateOneWhyUs/>
      <CorporateOnePartner/>
      <CorporateOneCounter/>
      <CorporateOneFeature/>
      <CorporateOneTestimonial/>
      <CorporateOneFooter/>
      <CorporateFooterBottom/>
      </section>
   
    </div>
  )
}

export default CorporateOneHome
