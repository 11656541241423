import React from "react";
import "./BusinessTwoTestimonials.scss";
import "aos/dist/aos.css";

const BusinessTwoTestimonials = () => {
  return (
    <div className="home_two_testimonials_parent_div">
      <div className="large_padding_div"></div>
      <div className="d-flex align-iems-center justify-content-center">
        <button className="ht_heading_btn_div">
          <h4 style={{ fontWeight: "700" }}>- Testimonials -</h4>
        </button>
      </div>
      <div className="large_padding_div"></div>
      <div className="large_padding_div"></div>
      <div className="container">
        <div className="row" data-aos="fade-top">
          <div className="col-6 col-md-3">
            <div className="ht-testimonials-div">
              <div className="ht_testimonials_profile_div">
                <img
                  src="https://w0.peakpx.com/wallpaper/107/46/HD-wallpaper-best-pose-for-profile-for-men-profile-pose-men-best-glasses.jpg"
                  alt="testimonials-img"
                />
              </div>
              <div className="ht-text-content-div d-flex flex-column">
                <h4>Abhay Jain</h4>
                <p>
                  This is Good opportunity to us, best platform in tech world.
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="ht-testimonials-div">
              <div className="ht_testimonials_profile_div">
                <img
                  src="https://3.bp.blogspot.com/-yiXYLoOguO8/XBbXQgpUTlI/AAAAAAAANXU/KwjZEHuYm6MNzFXJSO4DFkf8kQ1ZBCamgCLcBGAs/s1600/29104349_1911690185807799_6057801614364770304_o.jpg"
                  alt="testimonials-img"
                />
              </div>
              <div className="ht-text-content-div d-flex flex-column">
                <h4>Shrya Gupta</h4>
                <p>
                  This is Good opportunity to us, best platform in tech world.
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="ht-testimonials-div">
              <div className="ht_testimonials_profile_div">
                <img
                  src="https://2.bp.blogspot.com/-z6ek7gBX-MY/W_76SfGLf3I/AAAAAAAAd3o/sPts31Nx3Y0Gs5tscpXv3owdE1_sdkgZQCLcBGAs/s1600/Screen%2BShot%2B2018-11-29%2Bat%2B9.27.38%2BAM.png"
                  alt="testimonials-img"
                />
              </div>
              <div className="ht-text-content-div d-flex flex-column">
                <h4>Arjun Saxena</h4>
                <p>
                  This is Good opportunity to us, best platform in tech world.
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="ht-testimonials-div">
              <div className="ht_testimonials_profile_div">
                <img
                  src="https://www.pcclean.io/wp-content/gallery/beautiful-face-hd-wallpapers/555472.jpg"
                  alt="testimonials-img"
                />
              </div>
              <div className="ht-text-content-div d-flex flex-column">
                <h4>Anshika Goyal</h4>
                <p>
                  This is Good opportunity to us, best platform in tech world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="large_padding_div"></div>
      <div className="large_padding_div"></div>
    </div>
  );
};

export default BusinessTwoTestimonials;
