import React from 'react'

import './HTWhyUs.scss'

const HTWhyUs = () => {
  return (
    <section className='why_us sect_padding'>
            <div className="container">
                <div className="inline_title_subtitle title_div">
                    <div className="its_left">
                        <h6 className="small_title_with_line text_white">
                            WHY CHOOSE US
                        </h6>
                        <h3 className="df45 text_white semibold">
                            Why We're Awesome ERP System?
                        </h3>
                    </div>
                    <div className="its_right text_white regular lh26">
                        With more than 16 years of experience in this industry we know what it's like to be at every stage of a growing business
                    </div>
                </div>
                <div className="wu_inner">
                    <div className="wu_single">
                        <div className="div_first">
                            <img src="/assets/img/homethree/wu1.jpg" alt="" />
                            <h4 className="df25 text_white medium">
                                Simple Straight Forward User Interface Ect
                            </h4>
                        </div>
                        <div className="div_second">
                        <div>
                        <h4 className="df20 text_black lh26 medium mb-2">
                                Simple Straight Forward User Interface Ect
                            </h4>
                            <p className="regular df15 lh22 text_black">
                                Migration dolor sit amet conseetur diisci velit sed quia non nuam eius modi tempora incidunt
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="wu_single">
                        <div className="div_first">
                            <img src="/assets/img/homethree/wu2.jpg" alt="" />
                            <h4 className="df25 text_white medium">
                                Developer Platform That Tighter Integration
                            </h4>
                        </div>
                        <div className="div_second">
                        <div>
                        <h4 className="df20 text_black lh26 medium mb-2">
                                Developer Platform That Tighter Integration
                            </h4>
                            <p className="regular df15 lh22 text_black">
                            Migration dolor sit amet conseetur diisci velit sed quia non nuam eius modi tempora incidunt
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="wu_single">
                        <div className="div_first">
                            <img src="/assets/img/homethree/wu3.jpg" alt="" />
                            <h4 className="df25 text_white medium">
                                Migration Wizard To Data From Spreadly
                            </h4>
                        </div>
                        <div className="div_second">
                        <div>
                        <h4 className="df20 text_black lh26 medium mb-2">
                                Migration Wizard To Data From Spreadly
                            </h4>
                            <p className="regular df15 lh22 text_black">
                                Migration dolor sit amet conseetur diisci velit sed quia non nuam eius modi tempora incidunt
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="wu_single">
                        <div className="div_first">
                            <img src="/assets/img/homethree/wu4.jpg" alt="" />
                            <h4 className="df25 text_white medium">
                                Clear Pricing Plans Gentle Monthly Contract
                            </h4>
                        </div>
                        <div className="div_second">
                        <div>
                        <h4 className="df20 text_black lh26 medium mb-2">
                                Clear Pricing Plans Gentle Monthly Contract
                            </h4>
                            <p className="regular df15 lh22 text_black">
                                Migration dolor sit amet conseetur diisci velit sed quia non nuam eius modi tempora incidunt
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="wu_single">
                        <div className="div_first">
                            <img src="/assets/img/homethree/wu5.jpg" alt="" />
                            <h4 className="df25 text_white medium">
                                Consult, Onboarding And Training Programs
                            </h4>
                        </div>
                        <div className="div_second">
                        <div>
                        <h4 className="df20 text_black lh26 medium mb-2">
                                Consult, Onboarding And Training Programs
                            </h4>
                            <p className="regular df15 lh22 text_black">
                                Migration dolor sit amet conseetur diisci velit sed quia non nuam eius modi tempora incidunt
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="wu_single">
                        <div className="div_first">
                            <img src="/assets/img/homethree/wu6.jpg" alt="" />
                            <h4 className="df25 text_white medium">
                            Sell On The With Our Awarded Crm Apps
                            </h4>
                        </div>
                        <div className="div_second">
                        <div>
                        <h4 className="df20 text_black lh26 medium mb-2">
                                Sell On The With Our Awarded Crm Apps
                            </h4>
                            <p className="regular df15 lh22 text_black">
                                Migration dolor sit amet conseetur diisci velit sed quia non nuam eius modi tempora incidunt
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default HTWhyUs
