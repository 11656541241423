import React from "react";
import { Link } from "react-router-dom";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import "./NotificationIcon.css";

const NotificationIcon = () => {
  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  return (
    <div className="d_desktop">
      {iconTypeDocument &&
        iconTypeDocument.BottomRightFixedIcons.notification == true && (
          <Link to="/notifications" className="ab_whatsapp_icon">
            <img src="/assets/img/icons8-notification.gif" />
          </Link>
        )}
    </div>
  );
};

export default NotificationIcon;
