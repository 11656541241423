import React from 'react'
import { Link } from 'react-router-dom'

// import css 
import './BusinessOneCopyright.scss'

const BusinessOneCopyright = () => {
  return (
    <section className="copyright">
    <div className="container">
        <div className="copyright_inner">
            Design And Developed By 
            <Link>
            Hyper Cloud Digital Solution
            </Link>
        </div>
    </div>
  </section>
  )
}

export default BusinessOneCopyright
