import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

// import css 
import './HTCounter.scss'

const HTCounter = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [shouldCount, setShouldCount] = useState(false);
    const [counts, setCounts] = useState([0, 0, 0, 0]);
    const counterRef = useRef(null);

    // Counter configurations for each counter
    const countersConfig = [
        { start: 0, end: 75, duration: 2 },
        { start: 0, end: 10, duration: 3 },
        { start: 0, end: 5, duration: 1 },
        { start: 0, end: 100, duration: 4 },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                    setShouldCount(false); // Stop counting when section is not visible
                    setCounts(counts.map(() => 0)); // Reset all counts when section is not visible
                }
            });
        });

        observer.observe(counterRef.current);

        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, [counts]);

    useEffect(() => {
        if (isVisible) {
            setShouldCount(true); // Start counting when section becomes visible
        }
    }, [isVisible]);

    useEffect(() => {
        let interval;
        if (shouldCount) {
            interval = setInterval(() => {
                setCounts((prevCounts) =>
                    prevCounts.map((prevCount, index) =>
                        prevCount < countersConfig[index].end ? prevCount + 1 : prevCount
                    )
                );
            }, 10000000000); // Adjust the interval for smoother counting (lower values may result in faster counting)
        } else {
            clearInterval(interval); // Stop counting when the section is not visible
        }

        return () => clearInterval(interval); // Clean up the interval when the component unmounts or counting stops
    }, [shouldCount]);
    return (
        <div ref={counterRef}>
            {shouldCount && (
                <section className='counter_section sect_padding'>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <div className="counter_left">
                                    <h6 className="small_title_with_line">
                                        Happy ERP Support
                                    </h6>
                                    <h2 className='df45 text_black semibold'>Trust Your Sales</h2>
                                    <p className='df15 text_grey regular lh26'>Engage with customers and grow their revenue Transform your business with the world’s favorite customer relationship</p>
                                </div>
                                <div className="counter">
                                    <div className="counter_single">
                                        <div className="left">
                                            <img src="/assets/img/icon/headphones.png" alt="" />
                                        </div>
                                        <div className="right">
                                            <p className='regular df15 text_black mb-1'>Customer</p>
                                            <h6 className="semibold df25">
                                                <CountUp
                                                    start={countersConfig[0].start}
                                                    end={countersConfig[0].end}
                                                    duration={countersConfig[0].duration}
                                                />
                                                +
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="counter_single">
                                        <div className="left">
                                            <img src="/assets/img/icon/note.png" alt="" />
                                        </div>
                                        <div className="right">
                                            <p className='regular df15 text_black mb-1'>Employee</p>
                                            <h6 className="semibold df25">
                                                <CountUp
                                                    start={countersConfig[1].start}
                                                    end={countersConfig[1].end}
                                                    duration={countersConfig[1].duration}
                                                />+
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="counter_single">
                                        <div className="left">
                                            <img src="/assets/img/icon/volunteer.png" alt="" />
                                        </div>
                                        <div className="right">
                                            <p className='regular df15 text_black mb-1'>Professionals</p>
                                            <h6 className="semibold df25">
                                                <CountUp
                                                    start={countersConfig[2].start}
                                                    end={countersConfig[2].end}
                                                    duration={countersConfig[2].duration}
                                                />+
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="counter_single">
                                        <div className="left">
                                            <img src="/assets/img/icon/man.png" alt="" />
                                        </div>
                                        <div className="right">
                                            <p className='regular df15 text_black mb-1'>User</p>
                                            <h6 className="semibold df25">
                                                <CountUp
                                                    start={countersConfig[3].start}
                                                    end={countersConfig[3].end}
                                                    duration={countersConfig[3].duration}
                                                />+
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="counter_right">
                                    <div className="relative">
                                        <img className='img1' src="/assets/img/homethree/counter_right2.jpg" alt="" />
                                        <img className='img2' src="/assets/img/homethree/counter_right.jpg" alt="" />
                                    </div>
                                    <div className="relative">
                                        <img className='img3' src="/assets/img/homethree/counter_right.jpg" alt="" />
                                        <img className='img4' src="/assets/img/homethree/counter_right2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}

export default HTCounter
