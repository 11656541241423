import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PartnerSlider from "../../components/PartnerSlider";
import OfferBanners from "../../components/OfferBanners";
// import AddBookingIcon from "../../../../components/AddBookingIcon";
import "./PGServices.css";
import Services from "../../components/Services";

const PGServices = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      {/* Offer Banner Section */}
      <OfferBanners
        category="offers"
        pageName="home"
        section="offers"
        // addMoreImagesButton={false}
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
      <Services />

      <div className="medium_margin_div"></div>

      {/* Our Partner section */}
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default PGServices;
