import React, { useState } from "react";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import TextField from "@mui/material/TextField";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";

const ColorSettings = () => {
  const { updateDocument, response } = useFirestore("settings");
  // Fetch the Colors document from Firestore
  const { document: colorsDocument } = useDocument("settings", "Colors");
  // Get the existing LightScreenTheme object from the document
  const LightScreenTheme =
    (colorsDocument && colorsDocument.LightScreenTheme) || {};

  const [clickColor, setClickColor] = useState(""); // Initialize clickColor state variable
  const [headingColor, setHeadingColor] = useState(""); // Initialize headingColor state variable
  const [cardBgColor, setCardBgColor] = useState(""); // Initialize pinkColor state variable
  const [subheadingColor, setSubheadingColor] = useState(""); // Initialize pinkColor state variable

  const handleSubmit = async (colorName) => {
    try {
      // Determine which color is being updated based on the colorName parameter
      let colorValue;
      switch (colorName) {
        case "clickColor":
          colorValue = clickColor;
          break;
        case "headingColor":
          colorValue = headingColor;
          break;
        case "cardBgColor":
          colorValue = cardBgColor;
          break;
        case "subheadingColor":
          colorValue = subheadingColor;
          break;
        default:
          colorValue = "";
      }

      // Check if colorValue is empty or smaller than 2 letters
      if (!colorValue || colorValue.length < 2) {
        alert("Please enter a valid color name or code.");
        return; // Exit the function if the condition is met
      }

      // Get the existing LightScreenTheme object from the document
      const existingLightScreenTheme = colorsDocument.LightScreenTheme || {};

      // Update the corresponding color field in the LightScreenTheme map
      const updatedLightScreenTheme = {
        ...existingLightScreenTheme,
        [colorName]: colorValue,
      };

      // Update the Colors document with the updated LightScreenTheme map
      await updateDocument("Colors", {
        LightScreenTheme: updatedLightScreenTheme,
      });
      console.log(`${colorName} updated successfully!`);
    } catch (error) {
      console.error(`Error updating ${colorName}:`, error);
    }
  };

  return (
    <div className="container">
      <div className="section-title">
        <p>Colors Setting</p>
        <h2>Customize Colour Combinations</h2>
      </div>
      <div className="row">
        <div className="d-flex align-items-center justify-content-center gap-4 col-12 col-md-3 col-lg-4 mt-4">
          <TextField
            type="text"
            label="Click Color"
            variant="filled"
            value={clickColor}
            onChange={(e) => setClickColor(e.target.value)}
            style={{
              borderRight: `5px solid ${
                clickColor == ""
                  ? LightScreenTheme && LightScreenTheme.clickColor
                  : clickColor
              }`,
              borderLeft: `5px solid ${
                clickColor == ""
                  ? LightScreenTheme && LightScreenTheme.clickColor
                  : clickColor
              }`,
              borderRadius: "15px",
              borderBottom: "none",
            }}
          />
          <button
            className="btn_fill"
            onClick={() => handleSubmit("clickColor")}
          >
            Change
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-4 col-12 col-md-3 col-lg-4 mt-4">
          <TextField
            type="text"
            label="Heading Color"
            variant="filled"
            value={headingColor}
            onChange={(e) => setHeadingColor(e.target.value)}
            style={{
              borderRight: `5px solid ${
                headingColor == ""
                  ? LightScreenTheme && LightScreenTheme.headingColor
                  : headingColor
              }`,
              borderLeft: `5px solid ${
                headingColor == ""
                  ? LightScreenTheme && LightScreenTheme.headingColor
                  : headingColor
              }`,
              borderRadius: "15px",
              borderBottom: "none",
            }}
          />
          <button
            className="btn_fill"
            onClick={() => handleSubmit("headingColor")}
          >
            Change
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-4 col-12 col-md-3 col-lg-4 mt-4">
          <TextField
            type="text"
            label="Card background Color"
            variant="filled"
            value={cardBgColor}
            onChange={(e) => setCardBgColor(e.target.value)}
            style={{
              borderRight: `5px solid ${
                cardBgColor == ""
                  ? LightScreenTheme && LightScreenTheme.cardBgColor
                  : cardBgColor
              }`,
              borderLeft: `5px solid ${
                cardBgColor == ""
                  ? LightScreenTheme && LightScreenTheme.cardBgColor
                  : cardBgColor
              }`,
              borderRadius: "15px",
              borderBottom: "none",
            }}
          />
          <button
            className="btn_fill"
            onClick={() => handleSubmit("cardBgColor")}
          >
            Change
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-4 col-12 col-md-3 col-lg-4 mt-4">
          <TextField
            type="text"
            label="Sub-heading Color"
            variant="filled"
            value={subheadingColor}
            onChange={(e) => setSubheadingColor(e.target.value)}
            style={{
              borderRight: `5px solid ${
                subheadingColor == ""
                  ? LightScreenTheme && LightScreenTheme.subheadingColor
                  : subheadingColor
              }`,
              borderLeft: `5px solid ${
                subheadingColor == ""
                  ? LightScreenTheme && LightScreenTheme.subheadingColor
                  : subheadingColor
              }`,
              borderRadius: "15px",
              borderBottom: "none",
            }}
          />
          <button
            className="btn_fill"
            onClick={() => handleSubmit("subheadingColor")}
          >
            Change
          </button>
        </div>
      </div>
      <br />
      <p style={{ fontSize: "13px" }}>
        <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
          Note:{" "}
        </span>{" "}
        Click Color: The name "click color" is given to whatever is clickable on
        the website.
      </p>
    </div>
  );
};

export default ColorSettings;
