import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import "./NavbarBottom.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
export default function NavbarBottom() {
  const location = useLocation(); // Get the current location
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );
  const showProfile = () => {
    navigate("/profile");
  };

  const showHome = () => {
    if (user && user.role === "superadmin") {
      // console.log('in superadmin', user.role)
      navigate("/superadmindashboard");
    }

    if (user && user.role === "admin") {
      // console.log('in admin', user.role)
      navigate("/");
    }

    if (user && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/");
    }

    if (user && user.role === "frontdesk") {
      // console.log('in user', user.role)
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const showSecondPage = () => {
    if (user && user.role === "admin") {
      // console.log('in user', user.role)
      navigate("/adminproperties");
    }
    if (user && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/services");
    }
    if (user && user.role === "doctor") {
      // console.log('in user', user.role)
      navigate("/calendarbooking");
    } else {
      // console.log('in user', user.role)
      navigate("/services");
    }
  };

  const showThirdPage = () => {
    if (appTypeDetails && appTypeDetails.apptype === "lite") {
      if (user && user.role === "admin") {
        navigate("/adminuser");
      }
      if (user && user.role === "customer") {
        navigate("/contact");
      }
      if (!user) {
        navigate("/contact");
      }
    } else {
      if (user && user.role === "admin") {
        navigate("/adminuser");
      }
      if (user && user.role === "customer") {
        navigate("/bookinglist");
      }
    }
  };

  const showFourthPage = () => {
    navigate("/more");
  };

  //Menus as per role
  let firstMenuIcon = "";
  let firstMenu = ""; //This is for all user type
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";

  if (appTypeDetails && appTypeDetails.apptype === "lite") {
    if (user && user.role === "customer") {
      thirdMenuIcon = "perm_phone_msg";
      thirdMenu = "Contact";
    }
    if (user && user.role === "admin") {
      thirdMenuIcon = "analytics";
      thirdMenu = "Users";
    }
  } else {
    if (user && user.role === "customer") {
      thirdMenuIcon = "list_alt";
      thirdMenu = "Bookings";
    }
    if (user && user.role === "admin") {
      thirdMenuIcon = "analytics";
      thirdMenu = "Users";
    }
  }

  if (user && user.role === "frontdesk") {
    secondMenu = "Bills";
    thirdMenu = "Tickets";
  }
  if (user && user.role === "doctor") {
    secondMenuIcon = "free_cancellation";
    secondMenu = "Calendar";
    thirdMenuIcon = "edit_note";
    thirdMenu = "Setup";
  }
  if (!user) {
    firstMenuIcon = "home";
    firstMenu = "Home";
    secondMenuIcon = "checklist";
    secondMenu = "Services";
    thirdMenuIcon = "perm_phone_msg";
    thirdMenu = "contact";
    fourthMenuIcon = "deployed_code";
    fourthMenu = "More";
  } else {
    firstMenuIcon = "home";
    firstMenu = "Home";
    secondMenuIcon = "checklist";
    secondMenu = "Services";
    fourthMenuIcon = "deployed_code";
    fourthMenu = "More";
  }
  return (
    <div className="small navbar-mobile-bottom">
      <div className="navbar-mobile-bottom-menu" id="divBottomNavBar">
        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${
            location.pathname === "/" ? "active_bottom_menu" : ""
          } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showHome}
        >
          <span className="material-symbols-outlined">{firstMenuIcon}</span>
          <small>{firstMenu}</small>
        </div>

        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${
            location.pathname === "/services" ? "active_bottom_menu" : ""
          } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showSecondPage}
        >
          <span className="material-symbols-outlined">{secondMenuIcon}</span>
          <small>{secondMenu}</small>
        </div>

        <div className="bmp_single">
          <Link
            to={user && user ? "/profile" : "/login"}
            className="profile_icon bm_single"
          >
            <span className="material-symbols-outlined">person</span>
          </Link>
        </div>

        {appTypeDetails && appTypeDetails.apptype == "lite" ? (
          <>
            {user && user.role == "customer" ? (
              <>
                <div
                  className={`navbar-mobile-bottom-menu-a bm_single ${
                    location.pathname === "/contact" ? "active_bottom_menu" : ""
                  } `}
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={showThirdPage}
                >
                  <span className="material-symbols-outlined">
                    {thirdMenuIcon}
                  </span>
                  <small>{thirdMenu}</small>
                </div>
              </>
            ) : user && user.role == "admin" ? (
              <>
                <div
                  className={`navbar-mobile-bottom-menu-a bm_single ${
                    location.pathname === "/adminuser"
                      ? "active_bottom_menu"
                      : ""
                  } `}
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={showThirdPage}
                >
                  <span className="material-symbols-outlined">
                    {thirdMenuIcon}
                  </span>
                  <small>{thirdMenu}</small>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`navbar-mobile-bottom-menu-a bm_single ${
                    location.pathname === "/contact" ? "active_bottom_menu" : ""
                  } `}
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={showThirdPage}
                >
                  <span className="material-symbols-outlined">
                    {thirdMenuIcon}
                  </span>
                  <small>{thirdMenu}</small>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {user && user.role == "customer" ? (
              <>
                {" "}
                <div
                  className={`navbar-mobile-bottom-menu-a bm_single ${
                    location.pathname === "/bookinglist"
                      ? "active_bottom_menu"
                      : ""
                  } `}
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={showThirdPage}
                >
                  <span className="material-symbols-outlined">
                    {thirdMenuIcon}
                  </span>
                  <small>{thirdMenu}</small>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div
                  className={`navbar-mobile-bottom-menu-a bm_single ${
                    location.pathname === "/adminuser"
                      ? "active_bottom_menu"
                      : ""
                  } `}
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={showThirdPage}
                >
                  <span className="material-symbols-outlined">
                    {thirdMenuIcon}
                  </span>
                  <small>{thirdMenu}</small>
                </div>
              </>
            )}
          </>
        )}

        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${
            location.pathname === "/more" ? "active_bottom_menu" : ""
          } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showFourthPage}
        >
          <span className="material-symbols-outlined">{fourthMenuIcon}</span>
          <small>{fourthMenu}</small>
        </div>
      </div>
    </div>
  );
}
