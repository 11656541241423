import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import "./FooterUpper.css";
const FooterUpper = () => {
  const { document: contactDetails, error: contactDetailserror } = useDocument(
    "settings",
    "contactUs"
  );
  const { document: appTypeDocument, error: appTypeDocumentError } =
    useDocument("settings", "AppType");

  const { document: socialUrl, error: socialUrlError } = useDocument(
    "settings",
    "socialmedia"
  );

  const { document: dbOrganizationDoc, error: dbOrganizationDocError } =
    useDocument("settings", "organisation");
  // Check if dbOrganizationDoc is available and has the contact_number field
  const whatsappLink =
    dbOrganizationDoc && dbOrganizationDoc.contact_number
      ? `https://wa.me/${dbOrganizationDoc.contact_number}`
      : "";
  return (
    <div>
      <section
        className="footer_before relative"
        style={{
          backgroundImage:
            "url('https://firebasestorage.googleapis.com/v0/b/restrofy-f9716.appspot.com/o/master_data%2Ffooter%20upper%20bg%20img%2Fcontactusupperimg.png?alt=media&token=7c1116a2-e9b4-4659-9c63-7d3f6de518bd')",
        }}
      >
        <div className="container">
          <div className="row footer_upper_row">
            <div className="col-4 col-md-2 mt-2">
              <a
                href={
                  "mailto:" + (contactDetails && contactDetails.contactEmail)
                }
                style={{ color: "black" }}
              >
                <div className="d-flex flex-column footer_upper_boxes">
                  <div className="footer_uuper_icon">
                    <span class="material-symbols-outlined">mail</span>
                  </div>
                  <div>Email</div>{" "}
                </div>
              </a>
            </div>
            <div className="col-4 col-md-2 mt-2">
              <a
                href={"tel:" + (contactDetails && contactDetails.contactNumber)}
                style={{ color: "black" }}
              >
                <div className="d-flex flex-column footer_upper_boxes">
                  <div className="footer_uuper_icon">
                    <span class="material-symbols-outlined">
                      perm_phone_msg
                    </span>
                  </div>
                  <div>Phone</div>
                </div>{" "}
              </a>
            </div>

            {(appTypeDocument && appTypeDocument.apptype === "liteplus") ||
            (appTypeDocument && appTypeDocument.apptype === "pro") ? (
              <>
                <div className="col-4 col-md-2 mt-2">
                  <Link to="/ticketdetail">
                    {" "}
                    <div className="d-flex flex-column footer_upper_boxes">
                      <div className="footer_uuper_icon">
                        <span class="material-symbols-outlined">
                          support_agent
                        </span>
                      </div>
                      <div>Chat</div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-md-2 mt-2">
                  <Link to="/notifications">
                    <div className="d-flex flex-column footer_upper_boxes">
                      <div className="footer_uuper_icon">
                        <span class="material-symbols-outlined">campaign</span>
                      </div>
                      <div>Notification</div>
                    </div>
                  </Link>
                </div>
              </>
            ) : null}
            <div className="col-4 col-md-2 mt-2">
              <div className="d-flex flex-column footer_upper_boxes">
                <a href={whatsappLink} style={{ color: "var(--black-color)" }}>
                  <div className="footer_uuper_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                    </svg>{" "}
                    <div>Whatsapp</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-4 col-md-2 mt-2">
              <div className="d-flex flex-column footer_upper_boxes">
                <a
                  href={socialUrl && socialUrl.socialmedia.facebook}
                  target="_black"
                >
                  <div className="footer_uuper_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-messenger"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.64.64 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.64.64 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76m5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"
                        fill="var(--black-color)"
                      />
                    </svg>
                  </div>{" "}
                </a>

                <div>Messenger</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FooterUpper;
