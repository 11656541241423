import React, { useState } from "react";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Popup from "./Popup";
import AddMedia from "./AddMedia";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";
import { useFirestore } from "../firebaseFiles/hooks/useFirestore";
import { projectStorage } from "../firebase/config";
import { useCollection } from "../firebaseFiles/hooks/useCollection";
// import "./Testimonials.css";
const Testimonials = ({
  category,
  pageName,
  section,
  // addMoreImagesButton = true,
  addMoreVideosButton,
  addMoreTextContentButton,
}) => {
  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
    "media",
    ["category", "==", "testimonial"]
  );

  const testimonialsImagesDocuments =
    dbMediaDocuments &&
    dbMediaDocuments.filter((item) => item.mediaType.toUpperCase() === "IMAGE");

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting product ID:", productIdToDelete);
      const documentToDelete = testimonialsImagesDocuments.find(
        (doc) => doc.id === productIdToDelete
      );
      console.log("Document to delete:", documentToDelete);

      if (documentToDelete) {
        // Log the constructed storage path with the filename
        const storagePath = `images/${productIdToDelete}`;

        // Recursive function to delete all files and subfolders
        const deleteFolderRecursive = async (ref) => {
          const listResult = await ref.listAll();
          const deletePromises = listResult.items.map((item) => item.delete());
          await Promise.all(deletePromises);

          // Recursively delete subfolders
          const deleteFolderPromises = listResult.prefixes.map(
            deleteFolderRecursive
          );
          await Promise.all(deleteFolderPromises);
        };

        // Get the reference to the folder
        const storageRef = projectStorage.ref(storagePath);

        // Start the recursive deletion
        await deleteFolderRecursive(storageRef);

        // Delete the document from Firestore
        await deleteDocument(productIdToDelete);

        console.log("Offer deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div>
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this offer?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div className="offer_banner_parent">
        {testimonialsImagesDocuments &&
          testimonialsImagesDocuments.length > 0 && (
            <OwlCarousel
              className="owl-theme"
              items={1}
              loop
              dots={false}
              nav={false}
              autoplay={true} // Enable autoplay
              autoplayTimeout={3000}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 3,
                },
              }}
            >
              {testimonialsImagesDocuments &&
                testimonialsImagesDocuments
                  .filter((media) =>
                    isAdmin
                      ? !section || media.section == section
                      : media.status == "active" &&
                        (!section || media.section == section)
                  )
                  .map((offerImage) => (
                    <div key={offerImage.id}>
                      <div style={{ margin: "10px 10px 0px 10px" }}>
                        <img
                          src={offerImage.mediaUrl}
                          alt={`Offer image`}
                          className="offer_img_carousel"
                        />
                      </div>{" "}
                      {isAdmin && (
                        <>
                          <div style={{ margin: "0px 0px 20px 15px" }}>
                            <MDBRow>
                              <MDBCol className="col-9">
                                <MDBCardText className="text-muted">
                                  <div className="d-flex align-items-center mt-2">
                                    <div>
                                      <p
                                        className="active-inactive-text"
                                        style={{ color: "red" }}
                                      >
                                        Inactive
                                      </p>
                                    </div>
                                    <ReactSwitch
                                      checked={offerImage.status == "active"}
                                      onChange={() =>
                                        handleToggleChange(
                                          offerImage.id,
                                          offerImage.status
                                        )
                                      }
                                      onColor="#149AA2"
                                      offColor="#F05B83"
                                      className="products-status-switch"
                                    />
                                    <div>
                                      <p
                                        className="active-inactive-text"
                                        style={{ color: "red" }}
                                      >
                                        Active
                                      </p>
                                    </div>
                                  </div>
                                </MDBCardText>
                              </MDBCol>
                              <MDBCol className="col-3 d-flex justify-content-center mt-2">
                                <div>
                                  <MDBCardText
                                    onClick={() =>
                                      handleDeleteProduct(offerImage.id)
                                    }
                                  >
                                    <span
                                      className="material-symbols-outlined"
                                      style={{
                                        fontSize: "23px",
                                      }}
                                    >
                                      delete
                                    </span>
                                  </MDBCardText>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
            </OwlCarousel>
          )}
        <AddMedia
          category={category}
          pageName={pageName}
          section={section}
          addMoreVideosButton={addMoreVideosButton}
          addMoreTextContentButton={addMoreTextContentButton}
        />
      </div>
    </div>
  );
};

export default Testimonials;
