import React from "react";
import { Link } from "react-router-dom";
import { useCollection } from "../../../../firebaseFiles/hooks/useCollection";
// import css
import "./HTServices.scss";

const HTServices = () => {
  const { documents: dbServicesDocument, error: dbServicesDocumentError } =
    useCollection("services");

  // Ensure dbServicesDocument exists and has items
  const servicesToDisplay =
    dbServicesDocument && dbServicesDocument.slice(0, 3);

  return (
    <div className="services relative">
      <div className="container">
        <div className="services_card">
          {servicesToDisplay &&
            servicesToDisplay.map((service, index) => (
              <div
                className={`sc_single ${
                  index % 2 === 0 ? "with_top_img" : "with_bottom_img"
                }`}
                key={index}
              >
                <div className="scs_inner border_right">
                  {index % 2 !== 0 && ( // Content above image for even-indexed services
                    <div className="content">
                      <h2>{service && service.title}</h2>
                      <p>{service && service.description}</p>
                      <ul className="list">
                        <p>{service && service.short_description}</p>
                      </ul>
                      <Link className="theme_btn with_icon">
                        Know More
                        <span className="material-symbols-outlined btn_icon">
                          arrow_forward
                        </span>
                      </Link>
                    </div>
                  )}
                  <div className="images">
                    <img src={service && service.images[0]} alt="" />
                  </div>
                  {index % 2 === 0 && ( // Content below image for odd-indexed services
                    <div className="content">
                      <h2>{service && service.title}</h2>
                      <p>{service && service.description}</p>
                      <ul className="list">
                        <p>{service && service.short_description}</p>
                      </ul>
                      <Link className="theme_btn with_icon">
                        Know More
                        <span className="material-symbols-outlined btn_icon">
                          arrow_forward
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HTServices;
