import React, { useEffect, useState } from "react";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { timestamp } from "../../firebase/config";
// import "./PGCareer.css";

import { Link, useLocation } from "react-router-dom";
import Features from "../../components/Features";

const PGFeatures = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Start firebase code
  const { user } = useAuthContext();
  const { addDocument, error } = useFirestore("features");

  // ADD  NEW CAREER CODE START
  const addFeatures = async () => {
    const newCareer = {
      Title: "",
      Description: "",
      order: 0,
      status: "active",
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(newCareer);

    window.scrollTo(0, 0);

    if (error) {
      console.log("New career not added");
    }
  };

  // ADD  NEW CAREER CODE END

  return (
    <div>
      <div className="career-parent-div">
        <div className="career_img_banner">
          <img src="/assets/img/Join our team (2).png" alt="career banner" />
        </div>
        <br />
        <br />
        <Features />
        {user && user.role === "admin" && (
          <div className="d-flex justify-content-center align-items-center mb-5">
            <button className="btn_fill" onClick={addFeatures}>
              Add Features
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PGFeatures;
