import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./firebaseFiles/hooks/useAuthContext";
import { useEffect, useState } from "react";
// components
import Navbar from "./components/Navbar";
import More from "./components/More";
import NavbarBottom from "./components/NavbarBottom";
import Footer from "./components/Footer";
import PartnerSlider from "./components/PartnerSlider";
import Notification from "./commonRolePages/notifications/Notification";
import ColorsComponent from "./components/ColorsComponent";
import PGLoginPhone from "./firebaseFiles/login/PGLoginPhone";
// admin
import PGAdminDashboard from "./allRoles/admin/PGAdminDashboard";
import PGAdminUser from "./allRoles/admin/PGAdminUser";
import AppSettings from "./allRoles/admin/PGAppSettings/AppSettings";

// Home Two
import LaunchingPage from "./commonRolePages/launchingPage/LaunchingPage";
import PGProfile from "./commonRolePages/profile/PGProfile";
import AddPhoto from "./firebaseFiles/AddPhoto";
import AddDocument from "./firebaseFiles/AddDocument";
import ServiceDetails from "./commonRolePages/service details/ServiceDetails";
import BookingList from "./commonRolePages/bookings/BookingList";
import TicketDetail from "./chatboard/TicketDetail";
import CreateTicket from "./chatboard/CreateTicket";

// Home Three
import HomeThree from "./commonRolePages/home/homeThree/HomeThree";
// styles
import "./App.css";
import AdminSettings from "./allRoles/admin/AdminSettings";
import BasicAddBooking from "./commonRolePages/add booking/BasicAddBooking";
import CustomerDashboard from "./allRoles/customer/dashboard/CustomerDashboard";
import PGServices from "./commonRolePages/services/PGServices";
import PGFaq from "./commonRolePages/faq/PGFaq";
import PrivacyPolicy from "./commonRolePages/privacy policy/PrivacyPolicy";
import PGAboutUs from "./commonRolePages/about us/PGAboutUs";
import TermsAndCondition from "./commonRolePages/terms & condition/TermsAndCondition";
import ContactUs from "./commonRolePages/contact us/ContactUs";
import WhatsAppIcon from "./components/WhatsAppIcon";
import FooterUpper from "./components/FooterUpper";
import { useDocument } from "./firebaseFiles/hooks/useDocument";
import PGGallery from "./commonRolePages/gallery/PGGallery";
import FloatingSpeedDial from "./components/FloatingSpeedDial";
import PGCareer from "./commonRolePages/career/PGCareer";
import PGFeatures from "./commonRolePages/features/PGFeatures";
import SkeletonLoader from "./components/loaders/SkeletonLoader";

// Routes of all template start
import RouteOne from "./RouteOne";
import BusinessOneRoute from "./templates/business/businessOne/businessOneRoute/BusinessOneRoute";
import BusinessTwoRoute from "./templates/business/businessTwo/businessTwoRoute/BusinessTwoRoute";
import CorporateOneRoute from "./templates/corporate/corporateOne/corporateOneRoute/CorporateOneRoute";

// Routes of all template end

function App() {
  const { user } = useAuthContext();
  const upcomingBookings = 6;
  const confirmedBookings = 100;
  const cancelledBookings = 2;

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");

  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");

  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  // const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        // Access the activeTemplate value from the fetched document
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  return (
    <div className={currentModeStatus === "dark" ? "dark" : "light"}>
      <div className="App">
        <BrowserRouter>
          {loginPopupDocuments &&
            loginPopupDocuments &&
            loginPopupDocuments.LoginPopop == true &&
            !user && (
              <div className="home-popup">
                <div className="login-popup-overlay"></div>
                <div className="popup-content">
                  <PGLoginPhone />
                </div>
              </div>
            )}

          <div>
            <div>
              {/* PWA INSTALLATION CODE START */}
              {dbTextContentDocuments &&
              dbTextContentDocuments.status == "active" ? (
                <>
                  {deferredPrompt && (
                    <div
                      className={
                        installPopupDiv
                          ? "install-popup-div open"
                          : "install-popup-div"
                      }
                    >
                      <div>
                        <span
                          onClick={closeInstallPopup}
                          className="material-symbols-outlined close-button"
                        >
                          close
                        </span>
                        <img src="/assets/img/hc-logo.png" alt=""></img>
                        <h1>
                          For swift and efficient access, consider installing
                          the app on your device.
                        </h1>
                        <button id="btn_install" onClick={startChromeInstall}>
                          Install App
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {/* PWA INSTALLATION CODE END */}

              {!user &&
              dbLaunchingSoonDocuments &&
              dbLaunchingSoonDocuments.status === true ? (
                <>
                  <LaunchingPage />
                  <FloatingSpeedDial />
                  <ColorsComponent />
                </>
              ) : user &&
                user.role !== "admin" &&
                dbLaunchingSoonDocuments &&
                dbLaunchingSoonDocuments.status === true ? (
                <>
                  <LaunchingPage />
                  <FloatingSpeedDial />
                  <ColorsComponent />
                </>
              ) : (
                <>
                  {activeTemplate === "defaultTemplate" ? (
                    <RouteOne />
                  ) : activeTemplate === "firstTemplate" ? (
                    <BusinessOneRoute />
                  ) : activeTemplate === "secondTemplate" ? (
                    <BusinessTwoRoute />
                  ) : activeTemplate === "thirdTemplate" ? (
                    <CorporateOneRoute />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
