import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

// import css 
import './BusinessOneHeader.scss'

const BusinessOneHeader = () => {
    const [scrollClass, setScrollClass] = useState('');
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 500) {
                setScrollClass('headerfixedtop');
            } else if (scrollPosition > 30) {
                setScrollClass('headerhide');
            } else {
                setScrollClass('');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return (
    <section className={`header ${scrollClass}`}>
    <div className="container">
        <div className="header_inner">
            <div className="header_logo">
                restaurant
            </div>
            <div className="header_menus">
                <Link className='hm_single active'>
                    Home
                </Link>
                <Link className="hm_single">
                    about
                </Link>
                <Link className="hm_single">
                    services
                </Link>
                <Link className="hm_single">
                    gallery
                </Link>
                <Link className="hm_single">
                    blog
                </Link>
                <Link className="hm_single">
                    contact
                </Link>
            </div>
            <div className="header_right ">
                <div className="header_btn theme_btn">
                    Book Now
                </div>
            </div>
        </div>
    </div>

</section>
  )
}

export default BusinessOneHeader
