import { React, useState, useEffect } from "react";
import "./IconsSettings.scss";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";

const PageSetting = () => {
  const { user } = useAuthContext();
  const { updateDocument, deleteDocument } = useFirestore("settings");
  const [pageControlDocState, setPageControlDocState] = useState(null);

  const { document: pageControlDoc, error: pageControlDocerror } = useDocument(
    "settings",
    "pagesControl"
  );

  useEffect(() => {
    setPageControlDocState(pageControlDoc);
  }, [pageControlDoc]);

  const iconsTypeLabels = {
    career: "Career",
    home: "Home",
    features: "Features",
    returnpolicy: "Return Policy",
  };

  const handleToggleChange = async (fieldName, currentValue) => {
    try {
      await updateDocument("pagesControl", {
        [fieldName]: currentValue,
      });
    } catch (error) {
      console.error("Error updating page status:", error);
    }
  };

  return (
    <div>
      <div className="container">
        {user && user.roles.includes("admin") && (
          <div className="role">
            <div className="section-title">
              <p>Page Control</p>
              <h2>You Can Change Your Pages Visibility</h2>
            </div>
            <div className="radio-container">
              {pageControlDocState &&
                Object.entries(pageControlDocState).map(
                  ([fieldName, value]) =>
                    // Exclude rendering if the field name is 'id'
                    fieldName !== "id" && (
                      <div
                        key={fieldName}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div style={{ marginTop: "30px" }}>
                          <h4
                            style={{
                              fontWeight: "700",
                              color: "var(--heading-color)",
                            }}
                          >
                            {iconsTypeLabels[fieldName]}
                          </h4>
                          <p>Change Your Pages Visibility</p>
                        </div>

                        {/* ACTIVE INACTIVE SWITCH */}
                        <div style={{ margin: "0px 0px 20px 15px" }}>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex align-items-center mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "red" }}
                                    >
                                      Inactive
                                    </p>
                                  </div>

                                  <ReactSwitch
                                    checked={value}
                                    onChange={() =>
                                      handleToggleChange(fieldName, !value)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "red" }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        )}
        <br />
        <p style={{ fontSize: "13px" }}>
          <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
            Note :
          </span>
          Page Settings : You can customize the visibility settings for the
          icons on your page according to your preference
        </p>
      </div>
    </div>
  );
};

export default PageSetting;
