import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./firebaseFiles/hooks/useAuthContext";
import { useEffect, useState } from "react";
// components
import Navbar from "./components/Navbar";
import More from "./components/More";
import NavbarBottom from "./components/NavbarBottom";
import Footer from "./components/Footer";
import PartnerSlider from "./components/PartnerSlider";
import Notification from "./commonRolePages/notifications/Notification";
import ColorsComponent from "./components/ColorsComponent";
import PGLoginPhone from "./firebaseFiles/login/PGLoginPhone";
// admin
import PGAdminDashboard from "./allRoles/admin/PGAdminDashboard";
import PGAdminUser from "./allRoles/admin/PGAdminUser";
import AppSettings from "./allRoles/admin/PGAppSettings/AppSettings";

// css
import "./TemplateOneCss/CssOne.scss";
import "./TemplateOneCss/CssTwo.scss";
import "./TemplateOneCss/CssThree.scss";

// other pages
import Home from "./commonRolePages/home/home one/Home";
// Home Two
// import HomeTwo from "./commonRolePages/home/home two/HomeTwo";
import LaunchingPage from "./commonRolePages/launchingPage/LaunchingPage";
import PGProfile from "./commonRolePages/profile/PGProfile";
import AddPhoto from "./firebaseFiles/AddPhoto";
import AddDocument from "./firebaseFiles/AddDocument";
import ServiceDetails from "./commonRolePages/service details/ServiceDetails";
import BookingList from "./commonRolePages/bookings/BookingList";
import TicketDetail from "./chatboard/TicketDetail";
import CreateTicket from "./chatboard/CreateTicket";

// Home Three
import HomeThree from "./commonRolePages/home/homeThree/HomeThree";
// styles
import "./App.css";
import AdminSettings from "./allRoles/admin/AdminSettings";
import BasicAddBooking from "./commonRolePages/add booking/BasicAddBooking";
import CustomerDashboard from "./allRoles/customer/dashboard/CustomerDashboard";
import PGServices from "./commonRolePages/services/PGServices";
import PGFaq from "./commonRolePages/faq/PGFaq";
import PrivacyPolicy from "./commonRolePages/privacy policy/PrivacyPolicy";
import ReturnPolicy from "./commonRolePages/return policy/ReturnPolicy";
import PGAboutUs from "./commonRolePages/about us/PGAboutUs";
import TermsAndCondition from "./commonRolePages/terms & condition/TermsAndCondition";
import ContactUs from "./commonRolePages/contact us/ContactUs";
import WhatsAppIcon from "./components/WhatsAppIcon";
import FooterUpper from "./components/FooterUpper";
import { useDocument } from "./firebaseFiles/hooks/useDocument";
import PGGallery from "./commonRolePages/gallery/PGGallery";
import FloatingSpeedDial from "./components/FloatingSpeedDial";
import PGCareer from "./commonRolePages/career/PGCareer";
import PGFeatures from "./commonRolePages/features/PGFeatures";
import SkeletonLoader from "./components/loaders/SkeletonLoader";
function RouteOne() {
  const { user } = useAuthContext();
  const upcomingBookings = 6;
  const confirmedBookings = 100;
  const cancelledBookings = 2;

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");

  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");

  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  // const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        // Access the activeTemplate value from the fetched document
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  return (
    <>
      <Navbar />
      <div className={"full-content"}>
        <Routes>
          <Route path="/adminsettings" element={<AdminSettings />}></Route>

          <Route
            path="/customerdashboard"
            element={
              <CustomerDashboard
                upcomingBookings={upcomingBookings}
                confirmedBookings={confirmedBookings}
                canceledBookings={cancelledBookings}
              />
            }
          />
          <Route
            path="/notifications"
            element={
              (user &&
                user.status === "active" &&
                dbAppTypeDocuments &&
                dbAppTypeDocuments.apptype === "liteplus") ||
              (dbAppTypeDocuments && dbAppTypeDocuments.apptype === "pro") ? (
                <Notification />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route path="/services" element={<PGServices />} />
          <Route
            path="/service-details/:productId"
            element={<ServiceDetails />}
          />
          {/* CHANGE HOME PATH BASE ON ACTIVE TEMPLATE */}
          {/* <Route
            path="/"
            element={
              // isLoading||
              activeTemplate === null ? ( // Display skeleton loader if isLoading is true or activeTemplate is null
                <div>
                  <SkeletonLoader />
                </div>
              ) : activeTemplate === "firstTemplate" ? (
                <Home />
              ) : (
                <HomeTwo />
              )
            }
          /> */}
          <Route path="/" element={<Home />} />

          <Route path="/homethree" element={<HomeThree />} />
          <Route
            path="/career"
            element={
              dbPagesControlDocuments &&
              dbPagesControlDocuments.career === true && <PGCareer />
            }
          ></Route>
          <Route
            path="/features"
            element={
              dbPagesControlDocuments &&
              dbPagesControlDocuments.features === true && <PGFeatures />
            }
          ></Route>

          <Route
            path="/admindashboard"
            element={
              user && user.role === "admin" ? (
                <PGAdminDashboard />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/sitesettings"
            element={
              user && user.role === "admin" ? (
                <AppSettings />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/adminuser"
            element={
              user && user.role === "admin" ? (
                <PGAdminUser />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/addphoto"
            element={
              user && user.role === "admin" ? (
                <AddPhoto />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/adddocument"
            element={
              user && user.role === "admin" ? (
                <AddDocument />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route path="/aboutus" element={<PGAboutUs />}></Route>
          <Route path="/profile" element={<PGProfile />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/returnpolicy" element={<ReturnPolicy />}></Route>

          <Route
            path="/termandcondition"
            element={<TermsAndCondition />}
          ></Route>
          <Route path="/contact" element={<ContactUs />}></Route>
          <Route
            path="/ticketdetail"
            element={
              (user &&
                user.status === "active" &&
                dbAppTypeDocuments &&
                dbAppTypeDocuments.apptype === "liteplus") ||
              (dbAppTypeDocuments && dbAppTypeDocuments.apptype === "pro") ? (
                <TicketDetail />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/createticket"
            element={
              user && user.status === "active" ? (
                <CreateTicket />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/bookinglist"
            element={user ? <BookingList /> : <Navigate to="/login" />}
          ></Route>
          <Route path="/gallery" element={<PGGallery />}></Route>
          <Route path="/faq" element={<PGFaq />}></Route>
          <Route path="/partnerslider" element={<PartnerSlider />}></Route>
          <Route path="/more" element={<More />}></Route>
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <PGLoginPhone />}
          ></Route>
          <Route
            path="/basicaddbooking/:productId"
            element={user ? <BasicAddBooking /> : <PGLoginPhone />}
          ></Route>
        </Routes>
      </div>
      {/* {dbAppTypeDocuments &&
                    (dbAppTypeDocuments.apptype === "liteplus" ||
                      dbAppTypeDocuments.apptype === "pro") && (
                      <>
                        <NotificationIcon />
                        <HelpAndSupportIcon />
                      </>
                    )} */}
      {/* <WhatsAppIcon /> */}
      <NavbarBottom />
      <FooterUpper />
      <FloatingSpeedDial />
      <Footer />
      <ColorsComponent />
    </>
  );
}

export default RouteOne;
