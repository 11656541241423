import React from "react";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";

const WhatsAppIcon = () => {
  const { document: appTypeDocument, error: appTypeDocumentError } =
    useDocument("settings", "organisation");

  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  // Check if appTypeDocument is available and has the contact_number field
  const whatsappLink =
    appTypeDocument && appTypeDocument.contact_number
      ? `https://wa.me/${appTypeDocument.contact_number}`
      : "";
  return (
    <div>
      {iconTypeDocument &&
        iconTypeDocument.BottomRightFixedIcons.whatsapp == true && (
          <a href={whatsappLink} className="ab_whatsapp_icon">
            <img src="/assets/img/icons8-whatsapp.gif" />
          </a>
        )}
    </div>
  );
};

export default WhatsAppIcon;
