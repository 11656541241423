import React from "react";
import { useDocument } from "../firebaseFiles/hooks/useDocument";

const CallIcon = () => {
  const { document: appTypeDocument, error: appTypeDocumentError } =
    useDocument("settings", "organisation");

  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  // Check if appTypeDocument is available and has the contact_number field
  const phoneNumber = appTypeDocument && appTypeDocument.contact_number;
  return (
    <div>
      {iconTypeDocument &&
        iconTypeDocument.BottomRightFixedIcons.call == true && (
          <div>
            <a href={`tel:${phoneNumber}`} className="ab_whatsapp_icon">
              <img src="./assets/img/phone-call.gif" alt="icon" />
            </a>
          </div>
        )}
    </div>
  );
};

export default CallIcon;
