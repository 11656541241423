import React from "react";
import { useDocument } from "../firebaseFiles/hooks/useDocument";

const MapIcon = () => {
  const { document: MapTypeDocument, error: MapTypeDocumentError } =
    useDocument("settings", "contactUs");

  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  const handleIconClick = () => {
    if (
      MapTypeDocument &&
      MapTypeDocument.contactmap &&
      MapTypeDocument.contactmap[0]
    ) {
      window.open(MapTypeDocument.contactmap[0], "_blank"); // Open link in new tab
    }
  };

  return (
    <div>
      {iconTypeDocument &&
        iconTypeDocument.BottomRightFixedIcons.map == true && (
          <a href="#" className="ab_whatsapp_icon" onClick={handleIconClick}>
            <img src="/assets/img/icons8-location.gif" />
          </a>
        )}
    </div>
  );
};

export default MapIcon;
