import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCollection } from "../firebaseFiles/hooks/useCollection";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import OwlCarousel from "react-owl-carousel";
import "aos/dist/aos.css";
import AOS from "aos";
import { useFirestore } from "../firebaseFiles/hooks/useFirestore";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";

// import AddBookingIcon from "../../components/AddBookingIcon";
import "./Services.css";
import { FaEdit } from "react-icons/fa";

const ratings = ["⭐", "⭐⭐", "⭐⭐⭐", "⭐⭐⭐⭐", "⭐⭐⭐⭐⭐"];

const Services = ({ maxServicesToShow }) => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  // Fetch data from the "services" collection
  const { documents: services, error: servicesError } = useCollection(
    "services",
    null,
    ["order"]
    // ["status", "==", "active"],
    // ["createdAt", "desc"]
  );

  // Fetch data from the "admin services" collection
  // const { documents: services, error: servicesError } =
  //   useCollection("services");

  //   .collection("services")
  // .orderBy("createdAt", "desc")

  const { document: dbServiceDetailsDocuments, error: dbServiceDetailsError } =
    useDocument("settings", "serviceDetails");

  // Ensure only the specified number of services are rendered
  const limitedServices = services && services.slice(0, maxServicesToShow);

  const { updateDocument, deleteDocument } = useFirestore("services");

  const { document, error } = useDocument("settings", "currency");
  const { document: apptype, error: apptypeerror } = useDocument(
    "settings",
    "AppType"
  );

  // Add services in firebase
  const [isEditing, setIsEditing] = useState(false);
  const [productName, setProductName] = useState("Product Name");
  const [selectedProductImage, setProductSelectedImage] = useState(null);

  const handleNameClick = () => {
    setIsEditing(true);
    // Reset the product name input field when clicked
    setProductName("");
  };

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleProductImageChange = (event) => {
    const file = event.target.files[0];
    setProductSelectedImage(file);
  };

  const handleAddProduct = async () => {
    // Validation: Check if product name is provided
    if (!productName || productName === "Product Name") {
      alert("Please provide product name.");
      return;
    }

    try {
      // Create a new product document in the "services" collection
      const newProductRef = await projectFirestore.collection("services").add({
        name: productName,
        price: "",
        discount: "",
        rating: "⭐⭐⭐⭐",
        order: 0,
        external_url: "",
        ratings: ratings,
        short_description: "Add Short Description",
        description: "Add Full Description About Product",
        status: "active",
        createdAt: timestamp.fromDate(new Date()),
        images: [], // Initialize images array
      });

      // Perform actions with the selected image, e.g., store in local storage
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // Store imageData in local storage or handle as needed
        localStorage.setItem("productImage", imageData);
      };
      reader.readAsDataURL(selectedProductImage);

      // Upload the image to the "product_img" folder in Firebase Storage
      if (selectedProductImage) {
        // Get the ID of the newly created document
        const newProductId = newProductRef.id;

        // Use the document ID as the folder name
        const storageRef = projectStorage.ref(`product_img/${newProductId}`);

        // Upload the image to the created folder in Firebase Storage
        const imageRef = storageRef.child(selectedProductImage.name);
        await imageRef.put(selectedProductImage);

        // Get the URL of the uploaded image
        const imageUrl = await imageRef.getDownloadURL();

        // Update the document with the image URL
        await newProductRef.update({
          images: [imageUrl],
        });
      }

      // Reset product name and selected image after adding the product
      setProductName("Product Name");
      setProductSelectedImage(null);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // Reset product name and selected image
  const handleCancel = () => {
    setProductName("Product Name");
    setProductSelectedImage(null);
  };

  useEffect(() => {
    AOS.init({
      duration: 1500, // You can adjust  the duration as needed
      // once: true // Animation only occurs once
    });
  }, []);

  // START CODE FOR DRAG SERVICES
  const [isDragging, setIsDragging] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = async (droppedIndex) => {
    try {
      if (draggedIndex !== droppedIndex) {
        const draggedService = services[draggedIndex];
        const droppedService = services[droppedIndex];
        const updatedServices = [...services];
        updatedServices[droppedIndex] = draggedService;
        updatedServices[draggedIndex] = droppedService;
        await updateDocument(draggedService.id, { order: droppedIndex });
        await updateDocument(droppedService.id, { order: draggedIndex });
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setDraggedIndex(null);
    }
  };

  const handleDrag = (e) => {
    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const threshold = 50; // Adjust this threshold as needed

    // Calculate distance from top and bottom edges of the viewport
    const distanceToTop = clientY;
    const distanceToBottom = viewportHeight - clientY;

    // Check if the item is close to the top edge
    if (distanceToTop < threshold) {
      // Scroll up
      window.scrollBy(0, -10); // Adjust scroll speed as needed
    } else if (distanceToBottom < threshold) {
      // Check if the item is close to the bottom edge
      // Scroll down
      window.scrollBy(0, 10); // Adjust scroll speed as needed
    }
  };
  // END CODE FOR DRAG SERVICES

  return (
    <div className="booking-services-parent-div">
      <section className="doctors-area">
        <div className="container">
          <div className="row">
            {user && user.role === "admin" && (
              <div className="col-6 col-sm-6 col-lg-3 services-cards-parent-div">
                <div className="doctor-item ss">
                  <div className="small_margin_div"></div>
                  <div className="doctor-top product-thumb-img d-flex align-items-center justify-content-center">
                    {selectedProductImage ? (
                      <img
                        src={URL.createObjectURL(selectedProductImage)}
                        alt="Product"
                      />
                    ) : (
                      <img
                        src="https://securitysolutions.me/wp-content/uploads/2018/12/dummy-person.jpg"
                        alt="Product"
                      />
                    )}
                    <div className="status-button-container">
                      <div style={{ margin: "auto" }}>
                        <input
                          type="file"
                          accept="image/*"
                          id="serviceimageInput"
                          onChange={handleProductImageChange}
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="serviceimageInput"
                          style={{ cursor: "pointer" }}
                        >
                          Upload
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="booking_services_name_haedings_parent">
                    {isEditing ? (
                      <input
                        type="text"
                        value={productName}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        required
                      />
                    ) : (
                      <h3>
                        <a
                          className="booking_services_name_haedings"
                          onClick={handleNameClick}
                        >
                          <h5 className="doctors-name-h5">{productName}</h5>
                          <FaEdit
                            style={{
                              fontSize: 20,
                              cursor: "pointer",
                              marginLeft: "10px",
                              color: "var(--pink)",
                            }}
                          />
                        </a>
                      </h3>
                    )}
                    <div className="booking_services_two_buttons">
                      <button
                        type="button"
                        className="booking_services_book_now_btn"
                        onClick={handleAddProduct}
                      >
                        Add Product
                      </button>
                      <button
                        type="button"
                        className="d-flex booking_services_see_all_btn"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {limitedServices &&
              limitedServices.map((doc, index) =>
                (user && user.role === "admin") ||
                (doc && doc.status === "active") ? (
                  <div
                    className="col-6 col-sm-6 col-lg-3 products-cards-parent-div"
                    key={index}
                  >
                    <div className="small_margin_div"></div>

                    <Link
                      to={
                        dbServiceDetailsDocuments &&
                        dbServiceDetailsDocuments.status === true
                          ? `/service-details/${doc.id}`
                          : ""
                      }
                    >
                      <div
                        className={`doctor-item ss ${
                          isDragging ? "dragging" : ""
                        }`}
                        data-aos="flip-right"
                      >
                        <div
                          className={`doctor-item ss ${
                            draggedIndex === index ? "dragging" : ""
                          }`}
                          draggable={user && user.role === "admin" && true}
                          onDragStart={() => handleDragStart(index)}
                          onDrag={(e) => handleDrag(e)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => handleDrop(index)}
                        >
                          <div className="doctor-top product-thumb-img">
                            <OwlCarousel
                              nav={false}
                              dots={false}
                              loop
                              autoplay
                              autoplayTimeout={3000}
                              items={1}
                            >
                              {doc.images &&
                                doc.images.map((imageUrl, index) => (
                                  <div key={index}>
                                    <img
                                      src={imageUrl}
                                      alt={`Product Image ${index}`}
                                    />
                                  </div>
                                ))}
                            </OwlCarousel>
                            <div className="d-flex space-between">
                              <div>
                                {user &&
                                user.role === "admin" &&
                                user.status === "active" ? (
                                  <>
                                    <div className="status-button-container">
                                      <button
                                        className={`booking_services_book_now_btn ${doc.status}`}
                                        style={{
                                          backgroundColor:
                                            doc.status === "active"
                                              ? "var(--click-color)"
                                              : "var(--pink)",
                                        }}
                                      >
                                        {doc.status}
                                      </button>
                                    </div>
                                    <div className="drag-button-container">
                                      <span
                                        class="material-symbols-outlined"
                                        style={{
                                          color: "var(--click-color)",
                                          fontSize: "30px",
                                          cursor: "grab",
                                        }}
                                      >
                                        apps
                                      </span>
                                      <span className="tooltip">
                                        Drag and swap cards position
                                      </span>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {apptype && apptype.apptype === "lite" ? null : (
                            <>
                              <div className="booking_services_price_location">
                                {doc.price && doc.price != "" ? (
                                  <div className="d-flex align-items-center justify-content-center flex-column">
                                    <div className="d-flex align-items-center">
                                      <h6
                                        style={{
                                          fontWeight: "700",
                                        }}
                                      >
                                        {document && document.symbol}
                                      </h6>
                                      &nbsp;
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: doc.price.toString("html"),
                                        }}
                                      ></p>
                                      &nbsp;
                                      <strike
                                        style={{
                                          color: "var(--red-color)",
                                        }}
                                      >
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              doc.discount.toString("html"),
                                          }}
                                        ></p>
                                      </strike>
                                    </div>
                                  </div>
                                ) : (
                                  <h5>{doc.rating}</h5>
                                )}
                              </div>
                            </>
                          )}
                          <div className="booking_services_name_haedings_parent">
                            <h1
                              className="doctors-name-h5"
                              dangerouslySetInnerHTML={{
                                __html: doc.name.toString("html"),
                              }}
                            ></h1>
                            <p
                              className="product-short-detail"
                              dangerouslySetInnerHTML={{
                                __html: doc.short_description.toString("html"),
                              }}
                            ></p>

                            {dbServiceDetailsDocuments &&
                              dbServiceDetailsDocuments.status == true && (
                                <div className="booking_services_two_buttons">
                                  <div className="d-flex">
                                    {doc.price && doc.price != "" && (
                                      <div className="d-flex align-items-center">
                                        {document && document.symbol}
                                        &nbsp;
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: doc.price.toString("html"),
                                          }}
                                        ></p>
                                      </div>
                                    )}
                                  </div>

                                  <div className="d-flex">
                                    Details
                                    <span className="material-symbols-outlined booking_services_see_all_btn">
                                      chevron_right
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : null
              )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
