import { React, useState, useEffect } from "react";
import "swiper/swiper-bundle.css";
import OwlCarousel from "react-owl-carousel";
import "./HomeHeroBanners.css";
//start imports of firebase
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
import { useCollection } from "../firebaseFiles/hooks/useCollection";
import { useFirestore } from "../firebaseFiles/hooks/useFirestore";
import { projectStorage } from "../firebase/config";
// End imports of firebase
import Popup from "./Popup";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";
import CircularLoader from "./loaders/CircularLoader";
import AddMedia from "./AddMedia";
import WaterWavesLoader from "./loaders/WaterWavesLoader";
import DottedSpinner from "./loaders/DottedSpinner";
import CubeLoader from "./loaders/CubeLoader";
import PGLoaders from "../commonRolePages/PGLoaders/PGLoaders";
const HomeHeroBanners = ({
  category,
  pageName,
  section,
  addMoreVideosButton,
  addMoreTextContentButton,
}) => {
  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbBannersDocuments, error: dbBannersError } =
    useCollection("media", ["section", "==", "homeherobanners"]);

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(!dbBannersDocuments);
  }, [dbBannersDocuments]);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("banner status updated successfully");
    } else {
      console.error("Error updating banner status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting product ID:", productIdToDelete);
      const documentToDelete = dbBannersDocuments.find(
        (doc) => doc.id === productIdToDelete
      );
      console.log("Document to delete:", documentToDelete);

      if (documentToDelete) {
        // Log the constructed storage path with the filename
        const storagePath = `images/${productIdToDelete}`;

        // Recursive function to delete all files and subfolders
        const deleteFolderRecursive = async (ref) => {
          const listResult = await ref.listAll();
          const deletePromises = listResult.items.map((item) => item.delete());
          await Promise.all(deletePromises);

          // Recursively delete subfolders
          const deleteFolderPromises = listResult.prefixes.map(
            deleteFolderRecursive
          );
          await Promise.all(deleteFolderPromises);
        };

        // Get the reference to the folder
        const storageRef = projectStorage.ref(storagePath);

        // Start the recursive deletion
        await deleteFolderRecursive(storageRef);

        // Delete the document from Firestore
        await deleteDocument(productIdToDelete);

        console.log("banner deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div className="home_hero_banners_parent">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this banner?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <PGLoaders />
        </div>
      ) : (
        <>
          <div>
            <OwlCarousel
              items={1}
              loop
              dots={false}
              nav={false}
              autoplay={true} // Enable autoplay
              autoplayTimeout={8000}
              smartSpeed={4000}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 1,
                },
              }}
            >
              {dbBannersDocuments &&
                dbBannersDocuments.map((bannerImages) =>
                  (user && user.role === "admin") ||
                  (bannerImages && bannerImages.status === "active") ? (
                    <div key={bannerImages.id}>
                      {bannerImages && bannerImages.mediaType === "image" ? (
                        <img
                          src={bannerImages.mediaUrl}
                          alt="banner"
                          className="home_baneer_img"
                        />
                      ) : (
                        <video
                          width="100%"
                          autoPlay
                          loop
                          muted
                          className="home_baneer_img"
                        >
                          <source
                            src={bannerImages.mediaUrl}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {isAdmin && (
                        <div className="active_inactive_home_banner">
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex align-items-center mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "var(--red-color)" }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={bannerImages.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(
                                        bannerImages.id,
                                        bannerImages.status
                                      )
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "var(--click-color)" }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 d-flex justify-content-center mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() =>
                                    handleDeleteProduct(bannerImages.id)
                                  }
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{ fontSize: "23px" }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      )}
                    </div>
                  ) : null
                )}
            </OwlCarousel>
          </div>
          <div>
            <AddMedia
              category={category}
              pageName={pageName}
              section={section}
              addMoreVideosButton={addMoreVideosButton}
              addMoreTextContentButton={addMoreTextContentButton}
            />{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default HomeHeroBanners;
