import React from "react";

// import components
import HTBanner from "./homeThreeComponent/HTBanner";
import HTServices from "./homeThreeComponent/HTServices";
import HTABitAboutUs from "./homeThreeComponent/HTABitAboutUs";
import HTWhyUs from "./homeThreeComponent/HTWhyUs";
import HTPartner from "./homeThreeComponent/HTPartner";
import HTCounter from "./homeThreeComponent/HTCounter";
import HTFeature from "./homeThreeComponent/HTFeature";
import PartnerSlider from "../../../components/PartnerSlider";
import HomeHeroBanners from "../../../components/HomeHeroBanners";

const HomeThree = () => {
  return (
    <div className="home_three">
      {/* <HTBanner /> */}
      <HomeHeroBanners />
      <section className="home_services">
        <HTServices />
      </section>
      <HTABitAboutUs />
      <HTWhyUs />
      {/* <HTPartner /> */}
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
      <HTCounter />
      <HTFeature />
    </div>
  );
};

export default HomeThree;
