import React, { useState } from "react";
import { useAuthContext } from "../../../firebaseFiles/hooks/useAuthContext";
import { useFirestore } from "../../../firebaseFiles/hooks/useFirestore";
import { timestamp } from "../../../firebase/config";
// import "./AddMedia.css";

const AddNotification = () => {
  const { user } = useAuthContext();
  const { addDocument, response } = useFirestore("notifications");
  const [newTextContent, setNewTextContent] = useState("");
  const [newHeadingTextContent, setNewHeadingTextContent] = useState("");
  const [newSelectedOptionContent, setNewSelectedOptionContent] = useState("");
  // Add text content in firesbase
  const handleAddTextContent = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const newNotification = {
      pageName: "notification",
      mediaType: "text",
      heading: newHeadingTextContent,
      longDescription: newTextContent,
      category: newSelectedOptionContent,
      section: "notification",
      status: "active",
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(newNotification);

    if (!response.error) {
      // Clear the input field after adding the video
      setNewTextContent("");
      setNewHeadingTextContent("");
      setNewSelectedOptionContent(""); // Clear the selected option
    }
  };
  return (
    <div className="add_notification_parent">
      <div className="container">
        <div className="row">
          {/* start add text content */}
          {user && user.role == "admin" && (
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "10px",
                marginBottom: "20px",
                borderRadius: "10px",
                border: "1px solid #c4c4c4",
              }}
            >
              <form onSubmit={handleAddTextContent}>
                <div className="faq_input_parent">
                  <div className="col-md-6">
                    <select
                      className="faq_add_options styled-input-text"
                      onChange={(e) =>
                        setNewSelectedOptionContent(e.target.value)
                      }
                      required
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>{" "}
                      <option value="alert">alert</option>
                      <option value="offer">offer</option>
                      <option value="update">update</option>
                      <option value="support">support</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    placeholder="Write Heading"
                    className="styled-input-text"
                    value={newHeadingTextContent}
                    required
                    onChange={(e) => setNewHeadingTextContent(e.target.value)}
                  ></input>
                </div>
                <input
                  type="text"
                  placeholder="Write Description"
                  className="styled-input-text faq_long_descr"
                  value={newTextContent}
                  required
                  onChange={(e) => setNewTextContent(e.target.value)}
                ></input>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "10px" }}
                >
                
                  <button type="submit" className="btn_fill">
                    Add Notification
                  </button>{" "}
                </div>
              </form>
            </div>
          )}
          {/* end add text content */}
        </div>
      </div>
    </div>
  );
};

export default AddNotification;
