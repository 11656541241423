import React from "react";
import Gallery from "../../components/Gallery";
const PGGallery = () => {
  return (
    <div>
      <Gallery
        category="gallery"
        pageName="gallery"
        section="gallery"
        // addMoreImagesButton={false}
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default PGGallery;
