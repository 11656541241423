import React from 'react'
import { Route, Routes } from "react-router-dom";

// import css 
import '../corporateOneCss/CorporateOneColor.scss'
import '../corporateOneCss/CorporateOneFont.scss'
import '../corporateOneCss/CorporateOneStyle.scss'
import '../corporateOneCss/CorporateOneForm.scss'



// import pages 
import CorporateOneHeader from '../corporateOneComponent/CorporateOneHeader';
import CorporateOneHome from '../corporateOnePages/corporateOneHome/CorporateOneHome';

const CorporateOneRoute = () => {
    return (
        <div className='corporate_one' >
            <CorporateOneHeader/>
            <Routes>
                <Route path="/" element={<CorporateOneHome />}></Route>
            </Routes>
        </div>
    )
}

export default CorporateOneRoute
