import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// BusinessTwo css start
// import "../businessTwoCss/BusinessTwoColors.scss";
// import "../businessTwoCss/BusinessTwoFont.scss";
// import "../businessTwoCss/BusinessTwoStyle.scss";
// BusinessTwo css end
import { useAuthContext } from "../../../../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../../../../firebaseFiles/hooks/useDocument";
// components
import BusinessTwoHome from "../businessTwoPage/businessTwoHome/BusinessTwoHome";
import Navbar from "../../../../components/Navbar";
import More from "../../../../components/More";
import NavbarBottom from "../../../../components/NavbarBottom";
import Footer from "../../../../components/Footer";
import PartnerSlider from "../../../../components/PartnerSlider";
import Notification from "../../../../commonRolePages/notifications/Notification";
import ColorsComponent from "../../../../components/ColorsComponent";
import PGLoginPhone from "../../../../firebaseFiles/login/PGLoginPhone";
// admin
import PGAdminUser from "../../../../allRoles/admin/PGAdminUser";
import AppSettings from "../../../../allRoles/admin/PGAppSettings/AppSettings";
import PGAdminDashboard from "../../../../allRoles/admin/PGAdminDashboard";
// other pages
import Home from "../../../../commonRolePages/home/home one/Home";
import LaunchingPage from "../../../../commonRolePages/launchingPage/LaunchingPage";
import PGProfile from "../../../../commonRolePages/profile/PGProfile";
import AddPhoto from "../../../../firebaseFiles/AddPhoto";
import AddDocument from "../../../../firebaseFiles/AddDocument";
import ServiceDetails from "../../../../commonRolePages/service details/ServiceDetails";
import BookingList from "../../../../commonRolePages/bookings/BookingList";
import TicketDetail from "../../../../chatboard/TicketDetail";
import CreateTicket from "../../../../chatboard/CreateTicket";
// Home Three
import HomeThree from "../../../../commonRolePages/home/homeThree/HomeThree";
import AdminSettings from "../../../../allRoles/admin/AdminSettings";
import BasicAddBooking from "../../../../commonRolePages/add booking/BasicAddBooking";
import CustomerDashboard from "../../../../allRoles/customer/dashboard/CustomerDashboard";
import PGFaq from "../../../../commonRolePages/faq/PGFaq";
import PGServices from "../../../../commonRolePages/services/PGServices";
import PrivacyPolicy from "../../../../commonRolePages/privacy policy/PrivacyPolicy";
import PGAboutUs from "../../../../commonRolePages/about us/PGAboutUs";
import TermsAndCondition from "../../../../commonRolePages/terms & condition/TermsAndCondition";
import ContactUs from "../../../../commonRolePages/contact us/ContactUs";
import WhatsAppIcon from "../../../../components/WhatsAppIcon";
import FooterUpper from "../../../../components/FooterUpper";
import PGGallery from "../../../../commonRolePages/gallery/PGGallery";
import FloatingSpeedDial from "../../../../components/FloatingSpeedDial";
import PGCareer from "../../../../commonRolePages/career/PGCareer";
import PGFeatures from "../../../../commonRolePages/features/PGFeatures";
import SkeletonLoader from "../../../../components/loaders/SkeletonLoader";

function BusinessTwoRoute() {
  const { user } = useAuthContext();
  const upcomingBookings = 6;
  const confirmedBookings = 100;
  const cancelledBookings = 2;

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");
  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");
  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");
  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");
  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );
  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );
  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        } else {
          console.error("No active template found in the database.");
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  useEffect(() => {
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      setCurrentModeStatus(dbDisplayModeDocuments?.displayMode);
      localStorage.setItem("mode", dbDisplayModeDocuments?.displayMode);
    }

    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installPopupDiv, setInstallPopupDiv] = useState(true);

  const startChromeInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setInstallPopupDiv(false);
  };

  const closeInstallPopup = () => {
    setInstallPopupDiv(false);
  };

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<BusinessTwoHome />} />
        <Route path="/adminsettings" element={<AdminSettings />} />
        <Route
          path="/customerdashboard"
          element={
            <CustomerDashboard
              upcomingBookings={upcomingBookings}
              confirmedBookings={confirmedBookings}
              canceledBookings={cancelledBookings}
            />
          }
        />
        <Route
          path="/notifications"
          element={
            user?.status === "active" &&
            (dbAppTypeDocuments?.apptype === "liteplus" ||
              dbAppTypeDocuments?.apptype === "pro") ? (
              <Notification />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/services" element={<PGServices />} />
        <Route
          path="/service-details/:productId"
          element={<ServiceDetails />}
        />
        <Route path="/homethree" element={<HomeThree />} />
        <Route
          path="/career"
          element={dbPagesControlDocuments?.career ? <PGCareer /> : null}
        />
        <Route
          path="/features"
          element={dbPagesControlDocuments?.features ? <PGFeatures /> : null}
        />
        <Route
          path="/admindashboard"
          element={
            user?.role === "admin" ? (
              <PGAdminDashboard />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/sitesettings"
          element={
            user?.role === "admin" ? <AppSettings /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/adminuser"
          element={
            user?.role === "admin" ? <PGAdminUser /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/addphoto"
          element={
            user?.role === "admin" ? <AddPhoto /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/adddocument"
          element={
            user?.role === "admin" ? <AddDocument /> : <Navigate to="/login" />
          }
        />
        <Route path="/aboutus" element={<PGAboutUs />} />
        <Route path="/profile" element={<PGProfile />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termandcondition" element={<TermsAndCondition />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/ticketdetail"
          element={
            user?.status === "active" &&
            (dbAppTypeDocuments?.apptype === "liteplus" ||
              dbAppTypeDocuments?.apptype === "pro") ? (
              <TicketDetail />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/createticket"
          element={
            user?.status === "active" ? (
              <CreateTicket />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/bookinglist"
          element={user ? <BookingList /> : <Navigate to="/login" />}
        />
        <Route path="/gallery" element={<PGGallery />} />
        <Route path="/faq" element={<PGFaq />} />
        <Route path="/partnerslider" element={<PartnerSlider />} />
        <Route path="/more" element={<More />} />
        <Route
          path="/login"
          element={user ? <Navigate to="/" /> : <PGLoginPhone />}
        />
        <Route
          path="/basicaddbooking/:productId"
          element={user ? <BasicAddBooking /> : <PGLoginPhone />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default BusinessTwoRoute;
