import React, { useState, useEffect } from "react";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
const PGSettings = () => {
  const { user } = useAuthContext();

  const { updateDocument, deleteDocument } = useFirestore("settings");
  const [activeNav, setActiveNav] = useState("");

  const { document: navTypeDoc, error: navTypeDocerror } = useDocument(
    "settings",
    "navbarType"
  );

  useEffect(() => {
    if (navTypeDoc) {
      setActiveNav(navTypeDoc.activeNav || "");
    }
  }, [navTypeDoc]);

  const navTypeLabels = {
    LeftNav: "Left Navigation",
    RightNav: "Right Navigation",
    BottomNav: "Bottom Navigation",
    TopNav: "Top Navigation",
    FixNav: "Fixed Navigation",
  };

  const handleRoleChange = async (navTypeLabel) => {
    try {
      const fieldName = navTypeLabel;
      await updateDocument("navbarType", { activeNav: fieldName });
    } catch (error) {
      console.error("Error updating user nav type:", error);
    }
  };

  return (
    <div>
      {user && user.roles.includes("admin") && (
        <div className="container role">
          <div className="section-title">
            <p>Navbar Setting</p>
            <h2>Select Your Nav Style</h2>
          </div>
          <div className="radio-container">
            {Object.keys(navTypeLabels).map((navType, index) => (
              <label
                key={index}
                className={`radio-container ${
                  activeNav === navType
                    ? "radio_btn_checked"
                    : "service_details_radion_btn"
                }`}
                style={{
                  color:
                    activeNav === navType ? "var(--white-color)" : "inherit",
                  backgroundColor:
                    activeNav === navType ? "var(--click-color)" : "inherit",
                }}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="nav"
                    value={navType}
                    onChange={() => {
                      setActiveNav(navType);
                      handleRoleChange(navType);
                    }}
                    className="radio-button"
                  />
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "21px" }}
                  >
                    {activeNav === navType ? "done" : "add"}
                  </span>
                  {navTypeLabels[navType]}
                </div>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PGSettings;
