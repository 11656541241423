import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import "aos/dist/aos.css";

// import css
import "./BusinessTwoWhyChooseUs.scss";

const images = [
  "https://www.greatamericaninsurancegroup.com/images/default-source/career-seekers/nonprofits-and-social-services-unscreen.gif?sfvrsn=df9955b1_1",
  "https://media4.giphy.com/media/bmoKSwzYNVLLnsZARg/giphy.gif?cid=6c09b95297vlpwuexpnw3sy36ojwzgp2hk2ss8924bcg5gwi&ep=v1_stickers_related&rid=giphy.gif&ct=s",
  "https://static.wixstatic.com/media/6e6166_cec12188412a4e72a90d31e2b2be6932~mv2.gif",
  "https://www.qomplx.com/assets/images/ms-icon.gif?v=145cff52da",
];
const expertiseSpecializationTopics = [
  "Medical Expertise",
  "State of the Art Facilities",
  "Convenient Location",
  "Tourism insurance",
];
const expertiseSpecializationcontent = [
  "The center's team consists of highly trained and specialized professionals, ensuring that patients receive the best care for their specific needs.",
  "Equipped with modern, state-of-the-art medical equipment and facilities, allowing for advanced and precise diagnostics and treatment.",
  "The location in Dubai offers convenience for residents and visitors alike. Easy accessibility from Dubai & Sharjah Airport.",
  "Accept various insurance plans, making healthcare more affordable and accessible to a broader range of patients.",
];

const options = {
  items: 3,
  loop: true,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1, // Show only 1 item on small screens (phone view)
    },
    768: {
      items: 3, // Show 3 items on larger screens
    },
  },
};
const BusinessTwoWhyChooseUs = () => {
  return (
    <div>
      {/* why choose us Section only for desktop */}
      <div className="international_why_choose_parent_div">
        <div className="large_margin_div"></div>
        <div className="">
          <div className="">
            <div>
              <div className="ip_middle_circle">
                <img
                  src="https://www.eziosolutions.com/assets/fbb7c97a.gif"
                  style={{ width: "350px", marginLeft: "40px" }}
                  alt="icon"
                  data-aos="fade-right"
                />
                <div className="d-flex flex-column" data-aos="fade-right">
                  <div style={{ marginLeft: "40px" }}>
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "50px",
                        color: "white",
                      }}
                    >
                      WHY
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "45px",
                        color: "white",
                      }}
                    >
                      CHOOSE US
                    </span>
                  </div>
                  <div className="ip_middle_div_text">
                    We stay up-to-date with the latest advancements in medical
                    technology and treatments. This commitment to innovation
                    ensures that patients have access to the most effective and
                    minimally invasive options.
                  </div>
                </div>
                {/* <div style={{ display: "flex" }}>
                  <div className="ip_last_circles-1">
                    <img
                      className="ip_airplane_icon"
                      src="https://media4.giphy.com/media/bmoKSwzYNVLLnsZARg/giphy.gif?cid=6c09b95297vlpwuexpnw3sy36ojwzgp2hk2ss8924bcg5gwi&ep=v1_stickers_related&rid=giphy.gif&ct=s"
                    ></img>
                  </div>
                  <div className="ip_whychoose_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        1. Expertise & Specialization
                      </h5>
                      <p
                        style={{
                          color: "var(--theme-lightgrey)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        The center's team consists of highly trained and
                        specialized professionals, ensuring that patients
                        receive the best care for their specific needs.
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="ip_last_circles-2" data-aos="fade-left">
                  <img
                    className="ip_hand_icon"
                    src="https://www.greatamericaninsurancegroup.com/images/default-source/career-seekers/nonprofits-and-social-services-unscreen.gif?sfvrsn=df9955b1_1"
                  ></img>
                  <div className="ip_whychoose_second_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        State of the Art Facilities
                      </h5>
                      <p
                        style={{
                          color: "var(--theme-lightgrey)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        Equipped with modern, state of the art medical equipment
                        and facilities, allowing for advanced and precise
                        diagnostics and treatment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ip_last_circles-3" data-aos="fade-left">
                  <div>
                    <img
                      className="ip_time_icon"
                      src="https://static.wixstatic.com/media/6e6166_cec12188412a4e72a90d31e2b2be6932~mv2.gif"
                    ></img>
                  </div>
                  <div className="ip_whychoose_third_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        Convenient Location
                      </h5>
                      <p
                        style={{
                          color: "var(--theme-lightgrey)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        The location in Dubai offers convenience for residents
                        and visitors alike. Easy accessibility from Dubai &
                        Sharjah Airport.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ip_last_circles-4" data-aos="fade-left">
                  <img
                    className="ip_securty_icon"
                    src="https://www.qomplx.com/assets/images/ms-icon.gif?v=145cff52da"
                  ></img>
                  <div className="ip_whychoose_fourth_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        Insurance Acceptance
                      </h5>
                      <p
                        style={{
                          color: "var(--theme-lightgrey)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        Accept various insurance plans, making healthcare more
                        affordable and accessible to a broader
                        range of patients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* why choose us Section only for phone & tab */}
      <div className="ip_whychoose_phone_div_parent">
        <OwlCarousel {...options}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`item ${index === 1 ? "highlighted" : ""}`}
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
              }} // Add margin and center-align
            >
              <Card
                data-resizable
                sx={{
                  width: 343,
                  overflow: "auto",
                  resize: "horizontal",
                  "--icon-size": "100px",
                }}
              >
                <CardOverflow
                  // variant="solid"
                  className="ip_whychoose_upper_bg_color"
                >
                  {/* <h3>Why Choose Us Because</h3> */}
                  <AspectRatio
                    ratio="1"
                    sx={{
                      m: "auto",
                      transform: "translateY(50%)",
                      borderRadius: "50%",
                      width: "var(--icon-size)",
                      boxShadow: "sm",
                      // bgcolor: "background.surface",
                      // position: "relative",
                    }}
                  >
                    <div>
                      {/* Use the image URL from the array */}
                      <img src={images[index]} alt={`Image ${index + 1}`} />
                    </div>
                  </AspectRatio>
                </CardOverflow>
                <Typography
                  level="title-lg"
                  sx={{
                    mt: "calc(var(--icon-size) / 2)",
                    textAlign: "center",
                    fontWeight: "700",
                    color: "var(--pink)",
                  }}
                >
                  {expertiseSpecializationTopics[index]}
                </Typography>

                <CardContent
                  sx={{ maxWidth: "40ch", textAlign: "center", color: "grey" }}
                >
                  {expertiseSpecializationcontent[index]}
                </CardContent>
              </Card>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default BusinessTwoWhyChooseUs;
