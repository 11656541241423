import React, { useState, useEffect } from "react";
import { projectFirestore, timestamp } from "../../firebase/config";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import "./ContactUs.css";
import PartnerSlider from "../../components/PartnerSlider";
const ContactUs = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  const { document: contactDetails, error: contactDetailserror } = useDocument(
    "settings",
    "contactUs"
  );
  const { document, seterror } = useDocument("settings", "organisation");

  const [formData, setFormData] = useState({
    message: "",
  });
  const { addDocument, response } = useFirestore("enquiries");

  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [optionType, setOptionType] = useState("General Inquiries");
  const [formError, setFormError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsPending(true);
    // Confirm the contactconfirm here
    const contactconfirm = {
      ...formData,
      issueType: optionType,
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(contactconfirm);
    setIsPending(false);
    setSuccessMessage(
      "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
    );
    setFormData({
      message: "",
    });
    if (!response.error) {
      //   navigate("/contactconfirmlist");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="pg_contact_us">
        <section className="loc_em_ph">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2
              className="map_heading"
              style={{ textAlign: "center", color: "white" }}
            >
              {document && document.full_name}
            </h2>
          </div>
          <div className="container">
            <div
              className="loc_em_ph_inner"
              style={{
                backgroundImage: "url('./assets/img/counterbg.png')",
                color: "var(--black-color)",
              }}
            >
              <div className="lep_single">
                <div>
                  <div className="icon_div ">
                    <span className="material-symbols-outlined ">home</span>
                  </div>
                  <h4>Address</h4>
                </div>
                <h6 className="lep_single_address">
                  {document && document.address}
                </h6>
              </div>
              <div className="lep_single">
                <div>
                  <div className="icon_div">
                    <span className="material-symbols-outlined ">
                      outgoing_mail
                    </span>
                  </div>
                  <h4>Email</h4>
                </div>
                <h6>
                  <a
                    href={
                      "mailto:" +
                      (contactDetails && contactDetails.contactEmail)
                    }
                    style={{ color: "var(--black-color)" }}
                  >
                    {contactDetails && contactDetails.contactEmail}
                  </a>
                </h6>
              </div>
              <div className="lep_single">
                <div>
                  <div className="icon_div">
                    <span className="material-symbols-outlined ">
                      deskphone
                    </span>
                  </div>
                  <h4>Phone</h4>
                </div>
                <h6>
                  <a
                    href={
                      "tel:" + (contactDetails && contactDetails.contactNumber)
                    }
                    style={{ color: "var(--black-color)" }}
                  >
                    {contactDetails && contactDetails.contactNumber}
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="form_sec">
          <div
            className="right_form"
            style={{
              backgroundImage:
                "url('https://thumbs.dreamstime.com/b/e-healthcare-concept-hand-holding-smart-phone-74254973.jpg')",
            }}
          >
            <form onSubmit={handleSubmit} className="form-w">
              <h4 className="title" style={{ color: "var(--black-color)" }}>
                Contact Us
              </h4>
              <div className="fl_form_field st-2 top_margin">
                <label htmlFor="" className="no-floating">
                  For
                </label>
                <select
                  onChange={(e) => {
                    setOptionType(e.target.value);
                  }}
                >
                  <option>General Inquiries</option>
                  <option>Service Bookings</option>
                  <option>Career Related</option>
                  <option>Technical Issues</option>
                </select>
              </div>
              <div className="form_field st-2">
                <div className="field_inner">
                  <textarea
                    id="id_message"
                    required
                    maxLength={500}
                    type="text"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleInputChange}
                    name="message"
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">chat</span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                {user && user ? (
                  <>
                    <div className="col-sm-12">
                      <div className="submit_btn">
                        <button
                          type="submit"
                          className="btn_fill"
                          disabled={isPending}
                        >
                          {isPending ? "Sending..." : "Send"}
                        </button>
                      </div>
                      {error && <p className="error">{error}</p>}
                      {successMessage && (
                        <p className="contact_success">{successMessage}</p>
                      )}
                    </div>
                    {formError && <p className="error">{formError}</p>}
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center">
                      <Link to="/login">
                        <button type="submit" className="btn_fill">
                          Login
                        </button>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
          <h2 className="map_top_heading">{document && document.full_name}</h2>
          <iframe
            src={contactDetails && contactDetails.contactmap}
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </section>
        <PartnerSlider
          category="partners"
          pageName="home"
          section="partners"
          addMoreVideosButton={false}
          addMoreTextContentButton={false}
        />
      </div>
    </>
  );
};

export default ContactUs;
