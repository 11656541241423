import { React, useState, useEffect } from "react";
import { useCollection } from "../../../../../firebaseFiles/hooks/useCollection";
import { useDocument } from "../../../../../firebaseFiles/hooks/useDocument";
import HomeHeroBanners from "../../../../../components/HomeHeroBanners";
import "aos/dist/aos.css";
import "./HomeTwo.scss";
import AOS from "aos";
import BusinessTwoServices from "../../businessTwoComponents/BusinessTwoServices";
import BusinessTwoCounter from "../../businessTwoComponents/BusinessTwoCounter";
import BusinessTwoWhyChooseUs from "../../businessTwoComponents/BusinessTwoWhyChooseUs";
import BusinessTwoTestimonials from "../../businessTwoComponents/BusinessTwoTestimonials";
const Home = () => {
  const { document: organisationDocument, errors: organisationDocumentError } =
    useDocument("settings", "organisation");

  const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
    "media",
    ["pageName", "==", "home"]
  );
  const { documents: dbServicesDocument, error: dbServicesDocumentError } =
    useCollection("services", ["status", "==", "active"]);

  const [faqCount, setFaqCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);

  // START COUNT CODE FOR SERVICES
  useEffect(() => {
    if (dbServicesDocument) {
      setServiceCount(dbServicesDocument.length);
    }
  }, [dbServicesDocument]);
  // END COUNT CODE FOR SERVICES

  // START COUNT CODE FOR FAQS
  useEffect(() => {
    if (dbMediaDocuments) {
      setFaqCount(dbMediaDocuments.length);
    }
  }, [dbMediaDocuments]);
  // END COUNT CODE FOR FAQS

  const aboutusvideo =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (e) => e.section == "aboutus" && e.mediaType == "video"
    );

  useEffect(() => {
    AOS.init({
      duration: 1500, // You can adjust the duration as needed
      // once: true // Animation only occurs once
    });
  }, []);

  return (
    <div className="home_two_page_parent">
      {/* Home Hero Banners */}
      <HomeHeroBanners
        category="banner"
        pageName="home"
        section="homeherobanners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
      <div data-aos="fade-up">
        <div
          style={{
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <div className="clipPath_home_two_ellipse"></div>{" "}
          <div style={{ zIndex: 999 }}>
            <BusinessTwoCounter />
          </div>
          {/* Services */}
          <div style={{ zIndex: 999 }}>
            <BusinessTwoServices />
          </div>
        </div>
      </div>
      <div className="large_padding_div"></div>
      {/* why hoose us */}
      <BusinessTwoWhyChooseUs />
      <div className="large_margin_div"></div>
      <div className="large_padding_div"></div>
      {/* why hoose us */}
      <BusinessTwoTestimonials />
      <div className="large_margin_div"></div>
    </div>
  );
};

export default Home;
