import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import Popup from "./Popup";
import { useEffect, useState } from "react";
import { useLogout } from "../firebaseFiles/hooks/useLogout";
import { useLocation } from "react-router-dom";
import { useFirestore } from "../firebaseFiles/hooks/useFirestore";
import DarkModeToggle from "react-dark-mode-toggle";

import "./More.css";

export default function More() {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { document: appTypeDocument, error: appTypeDocumentError } =
    useDocument("settings", "AppType");
  const { document: appDisplayMode, error: appDisplayModeerror } = useDocument(
    "settings",
    "mode"
  );
  const { updateDocument, deleteDocument } = useFirestore("settings");

  // START CODE FOR LIGHT/DARK MODE
  const toggleDarkMode = async (productId, currentModeStatus) => {
    // Calculate the new mode based on the current mode status
    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";

    // Update the mode in Firestore
    const updatedDocument = await updateDocument(productId, {
      displayMode: newDisplayMode,
    });

    // If the update is successful, update the local state
    if (updatedDocument && updatedDocument.success) {
      // setIsDarkMode(newDisplayMode === "dark");
      console.log("Mode status updated successfully");
    } else {
      console.error("Error updating mode status");
    }
  };

  // DARK\LIGHT CODE FOR CUSTOMER LOCAL STAORAGE
  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };
  // END CODE FOR LIGHT/DARK MODE

  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  const { logout, isPending } = useLogout();
  //Popup Flags
  useEffect(() => {
    if (popupReturn) {
      //Logout
      logout();
    }
  }, [popupReturn]);
  //Popup Flags
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };
  return (
    <>
      <div className="more-expand-div">
        <div className="container-fluid">
          <div className="more-div-big-icon-div">
            <div className="more-div-big-icon-div-inner pointer">
              <Link
                to={user ? "/profile" : "/login"}
                className="d-flex flex-column align-items-center"
              >
                <div>
                  <span className="material-symbols-outlined">person</span>
                </div>
                <h1 style={{ color: "var(--black-color)" }}>My Account</h1>
              </Link>
            </div>
            {(appTypeDocument && appTypeDocument.apptype === "liteplus") ||
            (appTypeDocument && appTypeDocument.apptype === "pro") ? (
              <div className="more-div-big-icon-div-inner pointer">
                <Link
                  to="/notifications"
                  className="d-flex flex-column align-items-center"
                >
                  <div>
                    <span className="material-symbols-outlined">
                      notifications
                    </span>
                  </div>
                  <h1 style={{ color: "var(--black-color)" }}>Notifications</h1>
                </Link>
              </div>
            ) : (
              <div className="more-div-big-icon-div-inner pointer">
                <Link
                  to="/contact"
                  className="d-flex flex-column align-items-center"
                >
                  <div>
                    <span className="material-symbols-outlined">
                      connect_without_contact
                    </span>
                  </div>
                  <h1 style={{ color: "var(--black-color)" }}>Contact</h1>
                </Link>
              </div>
            )}

            <div className="more-div-big-icon-div-inner pointer">
              {(appTypeDocument && appTypeDocument.apptype === "liteplus") ||
              (appTypeDocument && appTypeDocument.apptype === "pro") ? (
                <>
                  <Link
                    to="/ticketdetail"
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">
                        support_agent
                      </span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>Support</h1>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/gallery"
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">photo</span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>Gallery</h1>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* if apptype is lite */}
      {appTypeDocument && appTypeDocument.apptype === "lite" ? (
        <>
          {user && user.role === "customer" ? (
            <div className="more-expand-div">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pp_card rightside mlauto mauto">
                      <h6 className="more">Quick Links</h6>
                      <div className="">
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    home
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Home
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/services">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    linked_services
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Services
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/faq">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    quiz
                                  </span>
                                </div>
                              </div>
                              <h6>
                                FAQ
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/termandcondition">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    gavel
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Term & Condition
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/aboutus">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    info
                                  </span>
                                </div>
                              </div>
                              <h6>
                                About Us
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pp_card rightside mauto">
                      <h6 className="more">Useful Links</h6>
                      <div className="">
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/contact">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    connect_without_contact
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Contact Us
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        {(appTypeDocument &&
                          appTypeDocument.apptype === "liteplus") ||
                        (appTypeDocument &&
                          appTypeDocument.apptype === "pro") ? (
                          <div className="myli_single bglight mt-3 pointer">
                            <Link to="/ticketdetail">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      support_agent
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Help & Support
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </Link>
                          </div>
                        ) : null}
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/privacypolicy">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Privacy Policy
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/termandcondition">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    verified_user
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Disclaimer
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  display_settings
                                </span>
                              </div>
                            </div>
                            <div
                              className="d-flex more_display_mode_card"
                              style={{ width: "100%" }}
                            >
                              <div>
                                <h6>Display Mode</h6>
                              </div>
                              <div>
                                <DarkModeToggle
                                  onChange={() => toggleUserDarkMode()}
                                  checked={
                                    localStorage.getItem("mode") === "dark"
                                  }
                                  size={50}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="myli_single bglight pointer mt-3"
                          onClick={showPopup}
                        >
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  logout
                                </span>
                              </div>
                            </div>
                            <h6>
                              Logout
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="more-expand-div">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pp_card rightside mlauto mauto">
                      <h6 className="more">Quick Links</h6>
                      <div className="">
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    home
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Home
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/services">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    linked_services
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Services
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          {user &&
                          user.role === "admin" &&
                          user.status === "active" ? (
                            <>
                              <Link to="/adminuser">
                                <div className="profile-page-content">
                                  <div className="icon">
                                    <div className="icon_bg">
                                      <span class="material-symbols-outlined">
                                        group_add
                                      </span>
                                    </div>
                                  </div>
                                  <h6>
                                    All Users
                                    <span class="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </h6>
                                </div>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link to="/contact">
                                <div className="profile-page-content">
                                  <div className="icon">
                                    <div className="icon_bg">
                                      <span class="material-symbols-outlined">
                                        connect_without_contact
                                      </span>
                                    </div>
                                  </div>
                                  <h6>
                                    Contact Us
                                    <span class="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </h6>
                                </div>
                              </Link>
                            </>
                          )}
                        </div>

                        {user &&
                          user.role === "admin" &&
                          user.status === "active" && (
                            <div className="myli_single bglight mt-3 pointer">
                              <Link to="/sitesettings">
                                <div className="profile-page-content">
                                  <div className="icon">
                                    <div className="icon_bg">
                                      <span class="material-symbols-outlined">
                                        group_add
                                      </span>
                                    </div>
                                  </div>
                                  <h6>
                                    Site Settings
                                    <span class="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </h6>
                                </div>
                              </Link>
                            </div>
                          )}

                        {(appTypeDocument &&
                          appTypeDocument.apptype === "liteplus") ||
                        (appTypeDocument &&
                          appTypeDocument.apptype === "pro") ? (
                          <div className="more-div-big-icon-div-inner pointer">
                            <Link
                              to="/notifications"
                              className="d-flex flex-column align-items-center"
                            >
                              <div>
                                <span className="material-symbols-outlined">
                                  notifications
                                </span>
                              </div>
                              <h1 style={{ color: "var(--black-color)" }}>
                                Notifications
                              </h1>
                            </Link>
                          </div>
                        ) : null}

                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/faq">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    quiz
                                  </span>
                                </div>
                              </div>
                              <h6>
                                FAQ
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/aboutus">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    info
                                  </span>
                                </div>
                              </div>
                              <h6>
                                About Us
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pp_card rightside mauto">
                      <h6 className="more">Useful Links</h6>
                      <div className="">
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/contact">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    connect_without_contact
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Contact Us
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/termandcondition">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    gavel
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Term & Conditions
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        {(appTypeDocument &&
                          appTypeDocument.apptype === "liteplus") ||
                        (appTypeDocument &&
                          appTypeDocument.apptype === "pro") ? (
                          <div className="myli_single bglight mt-3 pointer">
                            <Link to="/ticketdetail">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      support_agent
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Help & Support
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </Link>
                          </div>
                        ) : null}
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/privacypolicy">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Privacy Policy
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/termandcondition">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    verified_user
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Disclaimer
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  display_settings
                                </span>
                              </div>
                            </div>
                            <div
                              className="d-flex more_display_mode_card"
                              style={{ width: "100%" }}
                            >
                              <div>
                                <h6>Display Mode</h6>
                              </div>
                              <div>
                                {user && user.role == "admin" ? (
                                  <>
                                    <DarkModeToggle
                                      onChange={() =>
                                        toggleDarkMode(
                                          appDisplayMode && appDisplayMode.id,
                                          appDisplayMode &&
                                            appDisplayMode.displayMode
                                        )
                                      }
                                      checked={
                                        appDisplayMode &&
                                        appDisplayMode.displayMode == "dark"
                                      }
                                      size={50}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <DarkModeToggle
                                      onChange={() => toggleUserDarkMode()}
                                      checked={
                                        localStorage.getItem("mode") === "dark"
                                      }
                                      size={50}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {user && user ? (
                          <>
                            <div
                              className="myli_single bglight pointer mt-3"
                              onClick={showPopup}
                            >
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      logout
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Logout
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="myli_single bglight pointer mt-3">
                              <Link to="/login">
                                <div className="profile-page-content">
                                  <div className="icon">
                                    <div className="icon_bg">
                                      <span class="material-symbols-outlined">
                                        logout
                                      </span>
                                    </div>
                                  </div>
                                  <h6>
                                    Login
                                    <span class="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </h6>
                                </div>
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {user && user.role === "customer" ? (
            <div className="more-expand-div">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pp_card rightside mlauto mauto">
                      <div className="">
                        <Link to="/services">
                          <div className="myli_single bglight pointer">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    add_shopping_cart
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Add Bookings
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/bookinglist">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    shopping_cart
                                  </span>
                                </div>
                              </div>
                              <h6>
                                My Bookings
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  shopping_bag
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Bookings
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                        <h6 className="more">More</h6>
                        <Link to="/sitesettings">
                          <div className="myli_single bglight">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    payments
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Site Settings
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <div className="myli_single bglight mt-3">
                          <Link to="/customerdashboard">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    dashboard
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Dashboard
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pp_card rightside mauto">
                      <div className="">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  flag
                                </span>
                              </div>
                            </div>
                            <h6>
                              Country
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  g_translate
                                </span>
                              </div>
                            </div>
                            <h6>
                              Language
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div
                          className="myli_single bglight pointer mt-3"
                          onClick={showPopup}
                        >
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  logout
                                </span>
                              </div>
                            </div>
                            <h6>
                              Logout
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                        <h6 className="more">More</h6>
                        {(appTypeDocument &&
                          appTypeDocument.apptype === "liteplus") ||
                        (appTypeDocument &&
                          appTypeDocument.apptype === "pro") ? (
                          <div className="myli_single bglight mt-3 pointer">
                            <Link to="/ticketdetail">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      support_agent
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Help & Support
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </Link>
                          </div>
                        ) : null}
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              About App
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="more-expand-div">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pp_card rightside mlauto mauto">
                      <div className="">
                        <Link to="/services ">
                          <div className="myli_single bglight pointer">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    add_shopping_cart
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Add Bookings
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <div className="myli_single bglight mt-3 pointer">
                          <Link to="/bookinglist">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    shopping_cart
                                  </span>
                                </div>
                              </div>
                              <h6>
                                My Bookings
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  shopping_bag
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Bookings
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                        <h6 className="more">More</h6>
                        <Link to="/sitesettings">
                          <div className="myli_single bglight">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    payments
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Site Settings
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <div className="myli_single bglight mt-3">
                          <Link to="/login">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    dashboard
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Dashboard
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pp_card rightside mauto">
                      <div className="">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  brush
                                </span>
                              </div>
                            </div>
                            <h6>
                              Logo
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div className="myli_single bglight pointer mt-3">
                          <Link to="/login">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    signature
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Name
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  g_translate
                                </span>
                              </div>
                            </div>
                            <h6>
                              Language
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>

                        <h6 className="more">More</h6>
                        {(appTypeDocument &&
                          appTypeDocument.apptype === "liteplus") ||
                        (appTypeDocument &&
                          appTypeDocument.apptype === "pro") ? (
                          <div className="myli_single bglight mt-3 pointer">
                            <Link to="/ticketdetail">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      support_agent
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Help & Support
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </Link>
                          </div>
                        ) : null}
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              About App
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Popup Component */}
              <Popup
                showPopupFlag={showPopupFlag}
                setShowPopupFlag={setShowPopupFlag}
                setPopupReturn={setPopupReturn}
                msg={"Are you sure you want to logout?"}
              />
            </div>
          )}
        </>
      )}

      <br />
      <br />
    </>
  );
}
