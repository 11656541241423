import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PartnerSlider from "../../components/PartnerSlider";
// import "./PrivacyPolicy.css";
const ReturnPolicy = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <div className="top_header_pg pa_bg">
        <div className="pa_inner_page info_in_ul_li max-991">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2>Return Policy</h2>
            <p>Refunds, Returns, Exchanges, and Cancellations</p>
          </div>
          <ul className="pvcy_polcy_head_ul_div">
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">info</span> &nbsp;
                <b>Damages and Issues:</b>
              </div>

              <p>
                Inspect your order upon receipt. Contact us at immediately if
                the item is defective, damaged, incorrect, or if items are
                missing. We will assist with an exchange or shipment of missing
                parts.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              {" "}
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">quiz</span> &nbsp;
                <b>Partial Deliveries:</b>{" "}
              </div>
              <p>
                If products are missing from your order, contact us with order
                details and missing items. We will ship the missing items. No
                returns, exchanges, or refunds are accepted for incomplete
                orders, except when Ecohome cannot fulfill the missing items.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">lock</span> &nbsp;
                <b>Returns and Exchanges:</b>{" "}
              </div>
              <p>
                Returns or exchanges are only allowed for damaged or incorrect
                products, with a 48-hour return policy. Items must be unused,
                with tags, in original packaging, and the request must be made
                within 48 hours of delivery.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">cookie</span> &nbsp;
                <b>Reason for return</b>
              </div>
              <p>
                Copy of invoice <br /> Picture of the product received <br />{" "}
                Product Category Specific Policies:
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Live Plants:</b>
              </div>
              <p>
                Variations in live plants are normal and not covered under the
                return, refund, and exchange policy.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">update</span> &nbsp;
                <b>Customized Products:</b>
              </div>
              <p>Customized products cannot be returned.</p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Live Plants:</b>
              </div>
              <p>
                Variations in live plants are normal and not covered under the
                return, refund, and exchange policy.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Delayed Deliveries:</b>
              </div>
              <p>
                We aim for next-day delivery, but delays may occur. No
                cancellations or exchanges are accepted due to delays unless for
                "Gifting Category" products. Delays due to uncontrollable
                circumstances (e.g., COVID-19, weather) are not grounds for
                cancellations or exchanges.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Customer Unavailability:</b>
              </div>
              <p>
                If unavailable at delivery, the delivery executive will contact
                you. Only one reattempt is allowed. Further delivery attempts
                will be chargeable.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Refunds:</b>
              </div>
              <p>
                Refunds are accepted only if:
                <br />
                Double payment or duplicate orders canceled on the same day.
                <br />
                Unable to fulfill your order.
                <br /> Unable to fulfill an eligible exchange.
                <br /> Approved return requests.
                <br /> Refunds will be processed to your original payment
                method. Processing time varies by bank or credit card company.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Third-Party Terms:</b>
              </div>
              <p>
                Ecohome is not responsible for refunds/returns from third-party
                sellers. Ecohome acts as a platform and does not
                guarantee/warranty third-party products.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b>Disputes:</b>
              </div>
              <p>Ecohome's decision is final in case of policy disputes.</p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <p>
                For questions or concerns, contact us at
                customersupport@ecohome.com.
              </p>
            </li>
          </ul>
        </div>
      </div>
      {/* Our Partner section */}
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default ReturnPolicy;
