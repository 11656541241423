import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLogout } from "../firebaseFiles/hooks/useLogout";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import { useFirestore } from "../firebaseFiles/hooks/useFirestore";
import DarkModeToggle from "react-dark-mode-toggle";
import { useRef } from "react";
import { projectStorage } from "../firebase/config";
import SidebarNew from "./SidebarNew";
// styles & images
import "./Navbar.css";
// import Temple from "../assets/img/logo.png";
export default function Navbar() {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );
  const { document: appDisplayMode, error: appDisplayModeerror } = useDocument(
    "settings",
    "mode"
  );

  const { document: navTypeDoc, error: navTypeDocerror } = useDocument(
    "settings",
    "navbarType"
  );

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const { updateDocument, deleteDocument } = useFirestore("settings");

  const { document, error } = useDocument("settings", "organisation");
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };

  // END CODE FOR LIGHT/DARK MODE

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { logout, isPending } = useLogout();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  // for show active tab with diff color
  const [activeMenu, setActiveMenu] = useState("dashboard");

  function logoutSetPadding() {
    logout();
  }

  const showHome = () => {
    if (user && user.role && user.role === "superadmin") {
      // console.log('in superadmin', user.role)
      navigate("/");
    }

    if (user && user.role && user.role === "admin") {
      // console.log('in admin', user.role)
      navigate("/");
    }

    if (user && user.role && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/");
    }

    if (user && user.role && user.role === "frontdesk") {
      // console.log('in user', user.role)
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const showSecondPage = () => {
    if (user && user.role && user.role === "admin") {
      // console.log('in user', user.role)
      navigate("/services");
    }
    if (user && user.role && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/services");
    } else {
      navigate("/services");
    }
  };

  const showThirdPage = () => {
    if (appTypeDetails && appTypeDetails.apptype === "lite") {
      if (user && user.role === "admin") {
        navigate("/adminuser");
      }
      if (user && user.role === "customer") {
        navigate("/contact");
      }
      if (!user) {
        navigate("/contact");
      }
    } else {
      if (user && user.role === "admin") {
        navigate("/adminuser");
      }
      if (user && user.role === "customer") {
        navigate("/bookinglist");
      }
    }
  };

  const showFourthPage = () => {
    navigate("/more");
  };

  const showFifthPage = () => {
    if (user && user.role && user.role === "admin") {
      navigate("/career");
    }
    if (user && user.role && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/career");
    } else {
      navigate("/career");
    }
  };

  const showSixthPage = () => {
    if (user && user.role && user.role === "admin") {
      navigate("/features");
    }
    if (user && user.role && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/features");
    } else {
      navigate("/features");
    }
  };
  //Menus as per role
  let firstMenuIcon = "";
  let firstMenu = ""; //This is for all user type
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";
  let fifthMenu = "";
  let fifthMenuIcon = "";
  let sixthMenu = "";
  let sixthMenuIcon = "";

  if (user) {
    firstMenuIcon = "home";
    firstMenu = "Home";
    fourthMenuIcon = "apps";
    fourthMenu = "More";
    fifthMenuIcon = "celebration";
    fifthMenu = "Careers";
    sixthMenuIcon = "featured_play_list";
    sixthMenu = "Features";
  }
  if (!user) {
    firstMenuIcon = "home";
    firstMenu = "Home";
    secondMenuIcon = "category";
    secondMenu = "Services";
    thirdMenuIcon = "perm_phone_msg";
    thirdMenu = "Contact";
    fourthMenuIcon = "apps";
    fourthMenu = "More";
    fifthMenuIcon = "celebration";
    fifthMenu = "Careers";
    sixthMenuIcon = "featured_play_list";
    sixthMenu = "Features";
  }

  if (appTypeDetails && appTypeDetails.apptype === "lite") {
    if (user && user.role === "customer") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "perm_phone_msg";
      thirdMenu = "Contact";
      fifthMenuIcon = "celebration";
      fifthMenu = "Careers";
      sixthMenuIcon = "featured_play_list";
      sixthMenu = "Features";
    }
    if (user && user.role === "admin") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "confirmation_number";
      thirdMenu = "Users";
      fifthMenuIcon = "celebration";
      fifthMenu = "Careers";
      sixthMenuIcon = "featured_play_list";
      sixthMenu = "Features";
    }
  } else {
    if (user && user.role === "customer") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "list_alt";
      thirdMenu = "Bookings";
      fifthMenuIcon = "celebration";
      fifthMenu = "Careers";
      sixthMenuIcon = "featured_play_list";
      sixthMenu = "Features";
    }
    if (user && user.role === "admin") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "confirmation_number";
      thirdMenu = "Users";
      fifthMenuIcon = "celebration";
      fifthMenu = "Careers";
      sixthMenuIcon = "featured_play_list";
      sixthMenu = "Features";
    }
  }

  if (user && user.role === "frontdesk") {
    secondMenu = "Bills";
    thirdMenu = "Tickets";
  }

  // CHANGE LOGO CODE START
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false); // State to track if the Confirm button should be disabled

  const handlePlusIconClick = () => {
    fileInputRef.current.click(); // Programmatically click the file input
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleConfirmButtonClick = async () => {
    if (!selectedFile) return;
    setIsConfirmButtonDisabled(true); // Enable the Confirm button once a file is selected

    const storageRef = projectStorage.ref();
    const logoRef = storageRef.child(`master_data/logo/${selectedFile.name}`);

    try {
      const snapshot = await logoRef.put(selectedFile);
      const logoUrl = await snapshot.ref.getDownloadURL();
      await updateDocument("organisation", { logoUrl: logoUrl });
      setSelectedFile("");
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsConfirmButtonDisabled(false);
    }
  };
  const handleCancelButtonClick = () => {
    setSelectedFile("");
  };
  // CHANGE LOGO CODE END
  return (
    <>
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
        <>
          <nav className={`navbar sticky-top ${isNavbarFixed ? "fixed" : ""}`}>
            <div className="container-fluid">
              <ul>
                <li
                  className={`col-6 col-md-3 col-lg-2  ${
                    user && user.role === "admin"
                      ? "navbar-logo-only-admin"
                      : "navbar-logo-only"
                  }`}
                  onClick={
                    user && user.role === "admin" ? handlePlusIconClick : null
                  }
                >
                  <div className="navbar-logo-container d-flex align-items-center">
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                    <img
                      src={
                        selectedFile && selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : document && document.logoUrl
                      }
                      alt="logo"
                      className="logo-img"
                    />
                    <p className="nav_logo_text">
                      {document && document.full_name}
                    </p>
                  </div>
                </li>
                {selectedFile && (
                  <>
                    <button
                      className="btn_fill"
                      onClick={handleConfirmButtonClick}
                      disabled={isConfirmButtonDisabled}
                    >
                      {isConfirmButtonDisabled ? "Changing..." : "confirm"}
                    </button>
                    &nbsp;
                    <button
                      className="btn_border"
                      onClick={handleCancelButtonClick}
                    >
                      cancel
                    </button>
                  </>
                )}
                <div className="small">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    {(appTypeDetails &&
                      appTypeDetails.apptype === "liteplus") ||
                      (appTypeDetails && appTypeDetails.apptype === "pro" && (
                        <div className="d-flex">
                          <Link to="/ticketdetail">
                            <li>
                              <div className="navbar-notification-div">
                                <span class="material-symbols-outlined">
                                  support_agent
                                </span>

                                <div></div>
                              </div>
                            </li>
                          </Link>
                          <Link to="/notifications">
                            <li>
                              <div className="navbar-notification-div">
                                <span className="material-symbols-outlined">
                                  notifications
                                </span>
                                <div></div>
                              </div>
                            </li>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
                <div className=""></div>
                <div className="large">
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div className="navbar-laptop-menu-links-div">
                      <div className="navbar-laptop-menu-links">
                        <div
                          onClick={showHome}
                          className={`${
                            location.pathname === "/" ? "active_menu" : ""
                          } `}
                        >
                          <span className="material-symbols-outlined">
                            {firstMenuIcon}
                          </span>
                          <h1>{firstMenu}</h1>
                        </div>
                        <div
                          onClick={showSecondPage}
                          className={`${
                            location.pathname === "/services"
                              ? "active_menu"
                              : ""
                          } `}
                        >
                          <span className="material-symbols-outlined">
                            {secondMenuIcon}
                          </span>
                          <h1>{secondMenu}</h1>
                        </div>

                        {user && user.role === "customer" ? (
                          <div
                            onClick={showThirdPage}
                            className={`${
                              location.pathname === "/contact"
                                ? "active_menu"
                                : ""
                            } `}
                          >
                            <span className="material-symbols-outlined">
                              {thirdMenuIcon}
                            </span>
                            <h1>{thirdMenu}</h1>
                          </div>
                        ) : user && user.role === "admin" ? (
                          <div
                            onClick={showThirdPage}
                            className={`${
                              location.pathname === "/adminuser"
                                ? "active_menu"
                                : ""
                            } `}
                          >
                            <span className="material-symbols-outlined">
                              {thirdMenuIcon}
                            </span>
                            <h1>{thirdMenu}</h1>
                          </div>
                        ) : (
                          <div
                            onClick={showThirdPage}
                            className={`${
                              location.pathname === "/contact"
                                ? "active_menu"
                                : ""
                            } `}
                          >
                            <span className="material-symbols-outlined">
                              {thirdMenuIcon}
                            </span>
                            <h1>{thirdMenu}</h1>
                          </div>
                        )}

                        {pageControlDoc && pageControlDoc.career === true && (
                          <div
                            onClick={showFifthPage}
                            className={`${
                              location.pathname === "/career"
                                ? "active_menu"
                                : ""
                            } `}
                          >
                            <span className="material-symbols-outlined">
                              {fifthMenuIcon}
                            </span>
                            <h1>{fifthMenu}</h1>
                          </div>
                        )}

                        {pageControlDoc && pageControlDoc.features === true && (
                          <div
                            onClick={showSixthPage}
                            className={`${
                              location.pathname === "/features"
                                ? "active_menu"
                                : ""
                            } `}
                          >
                            <span className="material-symbols-outlined">
                              {sixthMenuIcon}
                            </span>
                            <h1>{sixthMenu}</h1>
                          </div>
                        )}

                        {/*START FOURTH PAGE */}
                        <div
                          onClick={showFourthPage}
                          className={`${
                            location.pathname === "/more" ? "active_menu" : ""
                          } `}
                        >
                          <span className="material-symbols-outlined">
                            {fourthMenuIcon}
                          </span>
                          <h1>{fourthMenu}</h1>
                        </div>
                        {/*END FOURTH PAGE */}

                        <DarkModeToggle
                          onChange={() => toggleUserDarkMode()}
                          checked={localStorage.getItem("mode") === "dark"}
                          size={80}
                        />

                        {user ? (
                          ""
                        ) : (
                          <Link to="/login">
                            <div
                              className={`menu_single login pointer ${
                                location.pathname === "/login" ? "active" : ""
                              }`}
                            >
                              <span className="material-symbols-outlined ba_animation">
                                login
                              </span>
                              Login
                            </div>
                          </Link>
                        )}
                      </div>
                      {user ? (
                        <Link
                          to="/profile"
                          className={`menu_single profile pointer ${
                            location.pathname === "/profile" ? "active" : ""
                          }`}
                          style={{ display: "flex" }}
                        >
                          <span>Hi, {user.fullName}</span>
                          <div className="user_img">
                            {user.photoURL === "" ? (
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2&_gl=1*1bbo31y*_ga*MTEyODU2MDU1MS4xNjc3ODEwNzQy*_ga_CW55HF8NVT*MTY4NjIzODcwMC42OS4xLjE2ODYyMzkwMjIuMC4wLjA."
                                alt=""
                                className="nav_profile_icon"
                              />
                            ) : (
                              <img src={user.photoURL} alt="" />
                            )}
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </nav>
        </>
      ) : (
        <SidebarNew
          navigate={navigate}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      )}
    </>
  );
}
