import React from 'react'
import { Link, useLocation } from "react-router-dom";

// import css 
import './CorporateOneHeader.scss'

const CorporateOneHeader = () => {
    const location = useLocation(); // Get the current location
  return (
    <div className='header'>
    <div className="container">
        <div className="header_inner">
            <div className="brand_logo">
                <h6 className="medium df20 text_white">
                    Happy ERP
                </h6>
            </div>
            <div className="menus">
                <Link className={`menu_single ${location.pathname === "/" ? "active" : ""
                    }`}
                     to='/'>
                    <div className="menu_icon">
                        <span class="material-symbols-outlined">
                            home
                        </span>
                    </div>
                    <h6>
                        Home
                    </h6>
                </Link>
                <Link className={`menu_single ${location.pathname === "/dashboard" ? "active" : ""
                    }`} to='/dashboard'>
                    <div className="menu_icon">
                        <span class="material-symbols-outlined">
                            dashboard
                        </span>
                    </div>

                    Dashboard
                </Link>
                <Link className="menu_single">
                    <div className="menu_icon">
                        <span class="material-symbols-outlined">
                            menu_book
                        </span>
                    </div>

                    About Us
                </Link>
                <Link className={`menu_single ${location.pathname === "/more" ? "active" : ""
                    }`} to="/more">
                    <div className="menu_icon">
                        <span class="material-symbols-outlined">
                            apps
                        </span>
                    </div>

                    More
                </Link>
                <Link className={`profile ${location.pathname === "/profile" ? "profile_active" : ""
                    }`} to='/profile'>
                    Hi, Shreya
                    <img src="/assets/img/profile_dummy.jpg" alt="" />
                </Link>
            </div>
        </div>
    </div>
</div>
  )
}

export default CorporateOneHeader
