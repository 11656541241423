import { React, useEffect } from "react";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./Footer.css";

export default function Footer() {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { document, error } = useDocument("settings", "organisation");
  const { document: appTypeDocument, error: appTypeDocumentError } =
    useDocument("settings", "AppType");

  const { document: socialMedia, error: socialMediaError } = useDocument(
    "settings",
    "socialmedia"
  );

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );
  return (
    <MDBFooter
      style={{
        backgroundColor: "var(--white-color)",
        padding: "20px",
        color: "var(--white-color)",
        borderTop: "1px solid #898989",
        position: "absolute",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="text-center text-lg-start text-muted"
    >
      <section className="text-color d-flex justify-content-center justify-content-lg-between border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span style={{ color: "#ffffff", fontWeight: "700" }}>
            Get connected with us on {document && document.full_name}
          </span>
        </div>

        <div className="d-flex">
          <div style={{ width: "25px", margin: "0px 5px" }}>
            <a
              href={socialMedia && socialMedia.socialmedia.facebook}
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>{" "}
          <div style={{ width: "25px", margin: "0px 5px" }}>
            <a
              href={socialMedia && socialMedia.socialmedia.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>{" "}
          <div style={{ width: "25px", margin: "0px 5px" }}>
            <a
              href={socialMedia && socialMedia.socialmedia.instagram}
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>{" "}
          <div style={{ width: "32px", margin: "0px 5px" }}>
            {/* <h2>{socialMedia.socialmedia}</h2> */}
            <a
              href={socialMedia && socialMedia.socialmedia.youtube}
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M282 256.2l-95.2-54.1V310.3L282 256.2zM384 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm14.4 136.1c7.6 28.6 7.6 88.2 7.6 88.2s0 59.6-7.6 88.1c-4.2 15.8-16.5 27.7-32.2 31.9C337.9 384 224 384 224 384s-113.9 0-142.2-7.6c-15.7-4.2-28-16.1-32.2-31.9C42 315.9 42 256.3 42 256.3s0-59.7 7.6-88.2c4.2-15.8 16.5-28.2 32.2-32.4C110.1 128 224 128 224 128s113.9 0 142.2 7.7c15.7 4.2 28 16.6 32.2 32.4z"
                    fill="#ffffff"
                  />
                </svg>
              </svg>
            </a>
          </div>{" "}
        </div>
      </section>

      <section className="text-color footer_content_div">
        <MDBContainer className="text-start text-md-start mt-6">
          <MDBRow className="mt-6 ">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img
                  src={document && document.logoUrl}
                  alt="logo"
                  className="footer_logo_design"
                />
                <br />
                {document && document.full_name}
              </h6>
              <p>{document && document.short_details}</p>
            </MDBCol>

            <MDBCol
              md="2"
              lg="2"
              xl="2"
              className="mx-auto mb-4 footer_link_div"
            >
              <h6 className="d-flex align-items-center text-uppercase fw-bold mb-4">
                <div className="footer_headings_vertical_line"></div> Quick
                Links
              </h6>
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/faq"> FAQ</Link>
              </p>
              {pageControlDoc && pageControlDoc.career === true && (
                <p>
                  <Link to="/career">Career</Link>
                </p>
              )}

              <p>
                <Link to="/gallery"> Gallery</Link>
              </p>
              {pageControlDoc && pageControlDoc.career === true && (
                <p>
                  <Link to="/features">Features</Link>
                </p>
              )}
              {appTypeDocument && appTypeDocument.apptype == "lite" ? null : (
                <>
                  <p>
                    <Link to="/bookinglist">Bookings</Link>
                  </p>
                  <p>
                    <Link to="/customerdashboard"> Dashboard</Link>
                  </p>
                </>
              )}
              {(appTypeDocument && appTypeDocument.apptype === "liteplus") ||
              (appTypeDocument && appTypeDocument.apptype === "pro") ? (
                <p>
                  <Link to="/notifications"> Noifications</Link>
                </p>
              ) : null}

              <p>
                <Link to="/services"> Products & Services</Link>
              </p>
            </MDBCol>

            <MDBCol
              md="3"
              lg="2"
              xl="2"
              className="mx-auto mb-4 footer_link_div"
            >
              <h6 className="d-flex align-items-center text-uppercase fw-bold mb-4">
                <div className="footer_headings_vertical_line"></div> Useful
                Links
              </h6>{" "}
              <p>
                <Link to="/aboutus">About Us</Link>
              </p>
              <p>
                <Link to="termandcondition">Disclaimer</Link>
              </p>
              <p>
                <Link to="/contact">Contact Us</Link>
              </p>
              {pageControlDoc && pageControlDoc.returnpolicy === true && (
                <p>
                  <Link to="/returnpolicy">Return Policy</Link>
                </p>
              )}
              <p>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </p>
              <p>
                <Link to="/contact"> Help & Support </Link>
              </p>
              <p>
                <Link to="termandcondition"> Terms & Conditions</Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="d-flex align-items-center text-uppercase fw-bold mb-4">
                <div className="footer_headings_vertical_line"></div>Contacts
              </h6>{" "}
              <p>
                <MDBIcon icon="home" />
                {document && document.address}
              </p>
              <p>
                <MDBIcon icon="envelope" />
                {document && document.email}
              </p>
              <p>
                <MDBIcon icon="phone" /> {document && document.contact_number}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className="text-color text-center p-4 footer-copyright-div">
        © 2024 {document && document.full_name}. All rights reserved
        <br />
        <a href="https://www.hyperclouddigital.com/" target="_blank">
          {" "}
          Design & Developed by
          <br />
          HyperCloud Digital Solutions
        </a>{" "}
      </div>
    </MDBFooter>
  );
}
