import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useCollection } from "../../firebaseFiles/hooks/useCollection";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import BSSearchAndFilter from "../../components/BSSearchAndFilters";
// import AddBill from '../pages/create/AddBill'
import { useNavigate } from "react-router-dom";
import "./BookingList.css";
import Popup from "../../components/Popup";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { format } from "date-fns";
import OfferBanners from "../../components/OfferBanners";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
const bookingFilter = ["PENDING", "COMPLETED", "CANCELLED", "INACTIVE"];

export default function BookingList() {
  const { user } = useAuthContext();
  const userRole = user.role;

  const navigate = useNavigate();
  const { documents: bookingdocuments, error: bookingerror } = useCollection(
    "bookings",
    ["userID", "==", user.uid],
    ["bookedDate", "asc"]
  );
  const { document: currencydocument, currencydocumenterror } = useDocument(
    "settings",
    "currency"
  );
  const { updateDocument, deleteDocument } = useFirestore("bookings");

  const [showSharingCard, setShowSharingCard] = useState(false);
  const [fourCategoryfilter, setfourCategoryFilter] = useState("PENDING");
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [cancelBookingId, setCancelBookingId] = useState(null);
  const changeFilter = (newFilter) => {
    setfourCategoryFilter(newFilter);
  };
  const [query, setQuery] = useState("");

  const bookings = bookingdocuments || [];
  const filteredBookings = useMemo(() => {
    const currentFilter = fourCategoryfilter.toLowerCase();
    const currentQuery = query.toLowerCase();

    return bookings.filter((document) => {
      const customerName = document.userName.toLowerCase();

      if (currentFilter === "pending" && document.status !== "pending") {
        return false;
      }

      if (currentFilter === "completed" && document.status !== "completed") {
        return false;
      }

      if (currentFilter === "cancelled" && document.status !== "cancelled") {
        return false;
      }

      if (customerName.includes(currentQuery)) {
        return true;
      }

      return false;
    });
  }, [bookings, fourCategoryfilter, query]);

  const [filter, setFilter] = useState("");

  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
    console.log("input text", searchQuery);
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  // expand more expand less start
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCancelBooking = async (bookingId) => {
    setCancelBookingId(bookingId);
    setIsStatusPopupOpen(true);
  };

  const handleConfirmStatusProduct = async () => {
    try {
      const updatedDocument = await updateDocument(cancelBookingId, {
        status: "cancelled",
      });

      if (updatedDocument && updatedDocument.success) {
        console.log("Booking status updated successfully");
      } else {
        console.error("Error updating booking status");
      }
      setIsStatusPopupOpen(false);
    } catch (error) {
      console.error("Error cancelling booking:", error);
    }
  };

  // popup sharing card
  const handleWhatsAppClick = () => {
    setShowSharingCard(true);
  };

  const closeSharingCard = () => {
    setShowSharingCard(false);
  };
  return (
    <div className="bookinglist">
      <Popup
        showPopupFlag={isStatusPopupOpen}
        setShowPopupFlag={setIsStatusPopupOpen}
        msg={
          <>
            Are you sure you want to cancel this booking?
            <br />
            <p style={{ fontSize: "14px" }}>
              Note: Cancellation cannot be reverted!
            </p>
          </>
        }
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmStatusProduct();
          }
        }}
      />{" "}
      <OfferBanners
        category="offers"
        pageName="bookinglist"
        section="bookinglistoffers"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
      <div className="container-fluid">
        {" "}
        <BSSearchAndFilter
          onSearch={handleSearch}
          onFilterChange={handleFilterChange}
          changeFilter={changeFilter}
          filterList={bookingFilter}
          filterLength={bookings.length}
        />
        <div className="row">
          {filteredBookings && filteredBookings.length === 0 && (
            <p className="nobooking">No Booking Yet!</p>
          )}
          {filteredBookings &&
            filteredBookings.map((document) => (
              <div className="col-12 col-md-6 col-lg-4">
                {" "}
                <div className="property_cards_parent">
                  <div className="property_single_card">
                    <div className="top relative">
                      <div className="img_container">
                        <img src={document.doctorImageURL} alt="" />
                      </div>
                      {/* <div className="left_side relative"> */}
                      <div className="booked_shring_card_details">
                        <h6>{document.productName}</h6>
                        <p>Time : {document.selectedShifts}</p>
                        <p>
                          Date :{" "}
                          {format(
                            new Date(document.bookedDate.toDate()),
                            "MMM dd, yyyy"
                          )}
                        </p>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="middle relative">
                      <div className="show_more_arrow" onClick={handleExpand}>
                        <span class="material-symbols-outlined">
                          {expanded
                            ? "keyboard_arrow_down"
                            : "keyboard_arrow_up"}
                        </span>
                      </div>
                      <div className="middle_single">
                        <div className="ms_child">
                          <div class="icon_container">
                            <span class="material-symbols-outlined">
                              update
                            </span>{" "}
                          </div>
                          <div class="left">
                            <h6>Status</h6>
                            <h5 style={{ textTransform: "capitalize" }}>
                              {document.status}
                            </h5>
                          </div>
                        </div>
                        <div class="ms_child">
                          <div class="icon_container">
                            <span class="material-symbols-outlined">
                              account_balance_wallet
                            </span>{" "}
                          </div>
                          <div class="left">
                            <h6>payment</h6>
                            <h5 style={{ textTransform: "capitalize" }}>
                              {document.paymentstatus}
                            </h5>
                          </div>
                        </div>
                        <div class="ms_child">
                          <div class="icon_container">
                            <span class="material-symbols-outlined">timer</span>
                          </div>
                          <div class="left">
                            <h6>Booked Shift</h6>
                            <h5 style={{ textTransform: "capitalize" }}>
                              {document.selectedShifts}{" "}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {expanded ? (
                        ""
                      ) : (
                        <div class="middle_single">
                          <div class="ms_child">
                            <div class="icon_container">
                              <span class="material-symbols-outlined">
                                mail
                              </span>
                            </div>
                            <div class="left">
                              <h6>email</h6>
                              <h5 style={{ textTransform: "capitalize" }}>
                                {document.customerEmail}
                              </h5>{" "}
                            </div>
                          </div>
                          <div class="ms_child">
                            <div class="icon_container">
                              <span class="material-symbols-outlined">
                                call
                              </span>{" "}
                            </div>
                            <div class="left">
                              <h6>phone</h6>
                              <h5 style={{ textTransform: "capitalize" }}>
                                {document.customerPhone}
                              </h5>{" "}
                            </div>
                          </div>
                        </div>
                      )}
                      {expanded ? (
                        ""
                      ) : (
                        <div class="middle_single addtional_rooms">
                          <div class="ms_child">
                            <div class="icon_container">
                              <span class="material-symbols-outlined">
                                auto_awesome_mosaic
                              </span>{" "}
                            </div>
                            <div class="left">
                              <h6>Booking Purpose</h6>
                              <h5>
                                <span>{document.addOnMessage}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="booked_card_bottom_details">
                      <div className="d-flex justify-content-center">
                        <div className="fav">
                          <span class="material-symbols-outlined">
                            favorite
                          </span>
                        </div>
                        <div>
                          <span
                            class="material-symbols-outlined"
                            onClick={handleWhatsAppClick}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          >
                            share
                          </span>
                        </div>
                      </div>

                      <div className="booked_card_amount_div">
                        {document.status === "pending" && (
                          <div>
                            {" "}
                            <button
                              className="btn_fill"
                              onClick={() => handleCancelBooking(document.id)}
                              style={{
                                padding: "2px 5px",
                                backgroundColor: "var(--red-color)",
                              }}
                            >
                              cancel
                            </button>
                          </div>
                        )}
                        &nbsp;
                        {document.paymentstatus === "unpaid" ? (
                          <button
                            className="btn_fill"
                            style={{
                              padding: "2px 5px",
                              backgroundColor: "green",
                            }}
                          >
                            pay {currencydocument && currencydocument.symbol}{" "}
                            {document.price}
                          </button>
                        ) : (
                          <button
                            className="btn_fill"
                            style={{
                              padding: "2px 5px",
                              color: "black",
                              backgroundColor: "var(--grey-light)",
                            }}
                          >
                            Paid
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>{" "}
        {showSharingCard && (
          <div className="overlay">
            <div className="booked_sharing_card_parent">
              <div className="booked_sharing_card_parent_detals">
                <div>
                  <h4 className="booked_sharing_card_parent_heading">
                    Share Booking Details
                  </h4>
                </div>
                <div>
                  <span
                    className="material-symbols-outlined booked-details-close-icon"
                    onClick={closeSharingCard}
                  >
                    close
                  </span>
                </div>
              </div>
              <div className="booked_sharing_card_child">
                <div className="row">
                  <div className="col-4">
                    <img
                      className="booked_sharing_card_child_popup"
                      src="https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg"
                    />
                  </div>
                  <div className="col-6">
                    <div className="booked_shring_card_details">
                      <h6>Hijama Booking</h6>
                      <p>Patient : Naman Gaur</p>
                      <p>Doctor : Nidhi Mathur</p>
                      <p>Date : 05/02/2024</p>
                      <p>Time : 9.30AM</p>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="booked_sharing_card_child_side_text">
                      {" "}
                      <h6>⭐pending</h6>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="booked_sharing_card_qr_serction">
                    <p>
                      Booking Id : <b>w92xeq</b>
                    </p>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0o9dqcjvnlYNGXFiApLM3Mj9WsMEjgsDutQ&usqp=CAU"></img>
                    <p>Scan for more details</p>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </div>
    </div>
  );
}
