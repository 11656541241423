import React from "react";
import "./Counter.css";
import CountUp from "react-countup";
import { useAuthContext } from "../firebaseFiles/hooks/useAuthContext";
const Counter = () => {
  const { user } = useAuthContext();

  const startBedNumber = 0;
  const targetBedNumber = 10;
  const startPatientNumber = 0;
  const targetPatientNumber = 10;
  const startDoctorNumber = 0;
  const targetDoctorNumber = 10;
  const startYearNumber = 0;
  const targetYearNumber = 10;

  return (
    <div className="counter">
      <div className="container mobilecontainer">
        <div
          className={`row ${
            user && user.role === "admin" ? "counter-bg-admin" : "counter-bg"
          }`}
          style={{
            backgroundImage:
              "url('https://firebasestorage.googleapis.com/v0/b/restrofy-f9716.appspot.com/o/master_data%2Fcounter%20bg%20img%2Fcounterbg.png?alt=media&token=23205801-3489-4e63-9d01-eb9020d7ffa2')",
            backgroundColor: "var(--card-bg)",
            color: "var(--black-color)",
          }}
        >
          <div className="col-sm-3 mobwidth">
            <div className="counter-item ptop">
              <span
                class="material-symbols-outlined"
                style={{ fontSize: "35px", color: "var(--grey-dark)" }}
              >
                workspace_premium
              </span>{" "}
              <h3 className="counter">
                <span>
                  <CountUp
                    start={startYearNumber}
                    end={targetYearNumber}
                    duration={3.5}
                    separator=","
                  />
                </span>
                +
              </h3>
              <p>Years Of Experience</p>
            </div>
          </div>
          <div className="col-sm-3 mobwidth mbod_right">
            <div className="counter-item mbod_bottom">
              <span
                class="material-symbols-outlined"
                style={{ fontSize: "35px", color: "var(--grey-dark)" }}
              >
                sentiment_satisfied
              </span>
              <h3>
                <span>
                  <CountUp
                    start={startPatientNumber}
                    end={targetPatientNumber}
                    duration={3.5}
                    separator=","
                  />
                  K
                </span>
                +
              </h3>
              <p>Happy Customers</p>
            </div>
          </div>
          <div className="col-sm-3 mobwidth">
            <div className="counter-item mbod_bottom">
              <span
                class="material-symbols-outlined"
                style={{ fontSize: "35px", color: "var(--grey-dark)" }}
              >
                support_agent
              </span>{" "}
              <h3 className="counter">
                <span>
                  <CountUp
                    start={startBedNumber}
                    end={targetBedNumber}
                    duration={3.5}
                    separator=","
                  />
                </span>
                +
              </h3>
              <p>Customer Services</p>
            </div>
          </div>

          <div className="col-sm-3 mobwidth mbod_right">
            <div className="counter-item ptop">
              <span
                class="material-symbols-outlined"
                style={{ fontSize: "35px", color: "var(--grey-dark)" }}
              >
                diversity_3
              </span>
              <h3 className="counter">
                <span>
                  <CountUp
                    start={startDoctorNumber}
                    end={targetDoctorNumber}
                    duration={3.5}
                    separator=","
                  />
                </span>
                +
              </h3>
              <p>Helpful Staff</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
