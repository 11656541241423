import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./CustomerDashboard.css";
import { Link } from "react-router-dom";

const CustomerDashboard = ({
  upcomingBookings,
  confirmedBookings,
  canceledBookings,
}) => {
  const chartRef = useRef(null);
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalBookingsPercentage, setTotalBookingsPercentage] = useState(0);
  const [confirmedBookingsPercentage, setConfirmedBookingsPercentage] =
    useState(0);
  const [canceledBookingsPercentage, setCanceledBookingsPercentage] =
    useState(0);
  const [upcomingBookingsPercentage, setUpcomingBookingsPercentage] =
    useState(0);

  useEffect(() => {
    if (!chartRef.current) return;

    // Calculate total bookings
    const total = confirmedBookings + canceledBookings + upcomingBookings;
    setTotalBookings(total);

    const ctx = chartRef.current.getContext("2d");

    const data = {
      labels: [
        "Total Bookings",
        "Upcoming Bookings",
        "Confirmed Bookings",
        "Cancelled Bookings",
      ],
      datasets: [
        {
          data: [total, upcomingBookings, confirmedBookings, canceledBookings],
          backgroundColor: [
            "rgb(0, 123, 255)",
            "#0DCAF0",
            "#28a745",
            "#dc3545",
          ],
          hoverBackgroundColor: [
            "rgb(106 137 234)",
            " rgb(45 181 183)",
            "#218838",
            "#c82333",
          ],
        },
      ],
    };

    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type: "pie",
      data: data,
      options: {
        cutout: "70%",
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || "";
                const value = context.parsed;
                const total = context.dataset.data.reduce(
                  (acc, data) => acc + data,
                  0
                );
                const percentage = ((value / total) * 100).toFixed(2);
                return `${label}: ${percentage}%`;
              },
            },
          },
        },
      },
    });

    // Calculate and set the state for each percentage
    const totalPercentage = (
      (upcomingBookings + confirmedBookings + canceledBookings) *
      100
    ).toFixed(2);
    setTotalBookingsPercentage(totalPercentage);

    const confirmedPercentage = ((confirmedBookings / total) * 100).toFixed(2);
    setConfirmedBookingsPercentage(confirmedPercentage);

    const canceledPercentage = ((canceledBookings / total) * 100).toFixed(2);
    setCanceledBookingsPercentage(canceledPercentage);

    const upcomingPercentage = ((upcomingBookings / total) * 100).toFixed(2);
    setUpcomingBookingsPercentage(upcomingPercentage);
  }, [confirmedBookings, canceledBookings, upcomingBookings, totalBookings]);
  return (
    <div className="customer_dashboard_parent_div">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex align-items-center customer_dash_welcome_heading">
            <span class="material-symbols-outlined">bar_chart_4_bars</span>{" "}
            <h4>Dashboard</h4>
          </div>
          <div className="col-12 col-md-6">
            <div className="customer_dash_left_sec">
              <div className="row">
                <div className="row">
                  <div className="row trophy_div_top_heading">
                    <h4>
                      Congratulations <span>Naman</span>⭐
                    </h4>
                    <p>You completed your first booking</p>
                  </div>
                  <div className="col-8 d-flex flex-column mt-auto customer_dash_wallet_div">
                    <div className="d-flex align-items-center">
                      <h4>
                        Balance : <span>5000</span>
                      </h4>
                      &nbsp;
                      <span
                        class="material-symbols-outlined"
                        style={{
                          backgroundColor: "#dcdcdc",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "var(--click-color)",
                        }}
                      >
                        payments
                      </span>
                    </div>
                    <Link to="/bookinglist">
                      <button
                        className="btn_fill"
                        style={{ margin: "20px 0px", width: "200px" }}
                      >
                        View History
                      </button>
                    </Link>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <img
                      src="https://static.vecteezy.com/system/resources/previews/022/228/569/non_2x/grand-prize-transparent-trophy-free-png.png"
                      alt="trophy img"
                      className="customer_dash_trophy_img"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                // items={2}
                className="owl-theme"
                loop
                autoplay
                dots={false}
                autoplayTimeout={4000}
                autoplayHoverPause
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 1,
                  },
                  1000: {
                    items: 1,
                  },
                }}
              >
                <div className="item customer_booking_offer_carosel">
                  <img
                    src="https://img.pikbest.com/backgrounds/20210920/booking-luxury-hotel-banner-background-eps_6126596.jpg!bw700"
                    alt="Image 1"
                  />
                </div>
                <div className="item customer_booking_offer_carosel">
                  <img
                    src="https://marketplace.canva.com/EAFn6tpfL9A/1/0/1600w/canva-dark-blue-gradient-travel-banner-landscape-tvPpLjISh9c.jpg"
                    alt="Image 2"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="customer_dash_right_sec">
              <div className="row">
                <div className="col-6 col-md-6 mt-4">
                  <div
                    className="card text-white"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 0.3125rem 0.3125rem 0 #523f690d",
                      background:
                        "linear-gradient(360deg, rgb(106 137 234), rgb(0, 123, 255))",
                    }}
                  >
                    <div className="row card-body">
                      <div className="col-9 col-md-8 booking_type_heading">
                        <h5 className="card-title">Total Bookings</h5>
                        <p className="card-text">{totalBookings}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center col-md-4 col-3">
                        <span
                          class="material-symbols-outlined booking_type_icons"
                          style={{
                            color: "rgb(0, 123, 255)",
                          }}
                        >
                          calendar_month
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 mt-4">
                  <div
                    className="card text-white"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 0.3125rem 0.3125rem 0 #523f690d",
                      background:
                        "linear-gradient(360deg, rgb(44 231 234), #0DCAF0",
                    }}
                  >
                    <div className="row card-body">
                      <div className="col-9 col-md-8 booking_type_heading">
                        <h5 className="card-title">Upcoming Bookings</h5>
                        <p className="card-text">{upcomingBookings}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center col-md-4 col-3">
                        <span
                          class="material-symbols-outlined booking_type_icons"
                          style={{
                            color: "#0DCAF0",
                          }}
                        >
                          event_upcoming
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 mt-4">
                  <div
                    className="card text-white"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 0.3125rem 0.3125rem 0 #523f690d",
                      background:
                        "linear-gradient(360deg, rgb(99 198 93), #198754",
                    }}
                  >
                    <div className="row card-body">
                      <div className="col-9 col-md-8 booking_type_heading">
                        <h5 className="card-title">Confirmed Bookings</h5>
                        <p className="card-text">{confirmedBookings}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center col-md-4 col-3">
                        <span
                          class="material-symbols-outlined booking_type_icons"
                          style={{
                            color: "#198754",
                          }}
                        >
                          event_available
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 mt-4">
                  <div
                    className="card text-white"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 0.3125rem 0.3125rem 0 #523f690d",
                      background:
                        "linear-gradient(360deg, rgb(221 130 130), red",
                    }}
                  >
                    <div className="row card-body">
                      <div className="col-9 col-md-8 booking_type_heading">
                        <h5 className="card-title">Cancelled Bookings</h5>
                        <p className="card-text">{canceledBookings}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center col-md-4 col-3">
                        <span
                          class="material-symbols-outlined booking_type_icons"
                          style={{
                            color: "red",
                          }}
                        >
                          event_busy
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4 col-md-4">
                  <div className="d-flex flex-column">
                    {" "}
                    <div className="d-flex align-items-center customer_icon_with_percent">
                      <span
                        class="material-symbols-outlined"
                        style={{
                          color: "rgb(0, 123, 255)",
                        }}
                      >
                        calendar_month
                      </span>
                      <h4
                        style={{
                          marginLeft: "10px",
                          color: "rgb(0, 123, 255)",
                        }}
                      >
                        {/* {totalBookingsPercentage} */}
                        50.00%
                      </h4>
                    </div>
                    <div className="d-flex align-items-center customer_icon_with_percent">
                      <span
                        class="material-symbols-outlined"
                        style={{
                          color: "#0DCAF0",
                        }}
                      >
                        event_upcoming
                      </span>
                      <h4 style={{ marginLeft: "10px", color: "#0DCAF0" }}>
                        {upcomingBookingsPercentage}%
                      </h4>
                    </div>
                    <div className="d-flex align-items-center customer_icon_with_percent">
                      <span
                        class="material-symbols-outlined"
                        style={{
                          color: "#198754",
                        }}
                      >
                        event_available
                      </span>
                      <h4 style={{ marginLeft: "10px", color: "#198754" }}>
                        {confirmedBookingsPercentage}%
                      </h4>
                    </div>{" "}
                    <div className="d-flex align-items-center customer_icon_with_percent">
                      <span
                        class="material-symbols-outlined"
                        style={{
                          color: "red",
                        }}
                      >
                        event_busy
                      </span>
                      <h4 style={{ marginLeft: "10px", color: "red" }}>
                        {canceledBookingsPercentage}%
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-8 col-md-8">
                  <canvas ref={chartRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
