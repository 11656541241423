import React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import AppsIcon from "@mui/icons-material/Apps";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WhatsAppIcon from "./WhatsAppIcon";
import HelpAndSupportIcon from "./HelpAndSupportIcon";
import NotificationIcon from "./NotificationIcon";
import MapIcon from "./MapIcon";
import CallIcon from "./CallIcon";
import { useDocument } from "../firebaseFiles/hooks/useDocument";
import "./FloatingSpeedDial.scss";

const FloatingSpeedDial = () => {
  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Dynamically update the actions array based on the appType
  let updatedActions = [
    { icon: <WhatsAppIcon />, name: "Whatsapp" },
    { icon: <MapIcon />, name: "Map" },
    { icon: <CallIcon />, name: "Call" },
  ];

  // Conditionally add the NotificationIcon based on the appType
  if (
    dbAppTypeDocuments &&
    (dbAppTypeDocuments.apptype === "liteplus" ||
      dbAppTypeDocuments.apptype === "pro")
  ) {
    updatedActions.unshift(
      {
        icon: <NotificationIcon />,
        name: "Notification",
      },
      { icon: <HelpAndSupportIcon />, name: "Support" }
    );
  }

  // Filter out the inactive icons based on the Firebase document
  const activeActions = updatedActions.filter(
    (action) =>
      iconTypeDocument &&
      iconTypeDocument.BottomRightFixedIcons[action.name.toLowerCase()]
  );

  return (
    <div className="floating_Speed_dial_icon">
      <Box sx={{ height: 330, transform: "translateZ(0px)", flexGrow: 1 }}>
        <Backdrop open={open} />
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: "absolute", bottom: 30, right: 1 }}
          icon={<AppsIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {activeActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={handleClose}
            />
          ))}
        </SpeedDial>
      </Box>
    </div>
  );
};

export default FloatingSpeedDial;
