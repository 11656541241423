import React from 'react'
import { Route, Routes } from "react-router-dom";
// BusinessOne css start 
import '../businessOneCss/BusinessOneColors.scss'
import '../businessOneCss/BusinessOneFont.scss'
import '../businessOneCss/BusinessOneStyle.scss'
// BusinessOne css end 

// import pages 
import BusinessOneHeader from '../businessOneComponents/BusinessOneHeader';
import BusinessOneHome from '../businessOnePages/businessOneHome/BusinessOneHome'

const BusinessOneRoute = () => {
  return (
    <>
    <BusinessOneHeader/>
       <Routes>
            <Route path="/" element={<BusinessOneHome />}></Route>
        </Routes>
    </>
  )
}

export default BusinessOneRoute
