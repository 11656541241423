import React from "react";
import "./BusinessTwoCounter.scss";
import CountUp from "react-countup";
import { useAuthContext } from "../../../../firebaseFiles/hooks/useAuthContext";
const BusinessTwoCounter = () => {
  const { user } = useAuthContext();

  const startBedNumber = 0;
  const targetBedNumber = 50;
  const startPatientNumber = 0;
  const targetPatientNumber = 10;
  const startDoctorNumber = 0;
  const targetDoctorNumber = 100;
  const startYearNumber = 0;
  const targetYearNumber = 10;

  return (
    <div className="home_two_counter_parent">
      <div className="container mobilecontainer">
        <div
          className={`row ${
            user && user.role === "admin"
              ? "counter-bg-admin"
              : "home_two_counter_counter_bg"
          }`}
          style={{
            backgroundImage:
              "url('https://firebasestorage.googleapis.com/v0/b/restrofy-f9716.appspot.com/o/master_data%2Fcounter%20bg%20img%2Fcounterbg.png?alt=media&token=23205801-3489-4e63-9d01-eb9020d7ffa2')",
            backgroundColor: "var(--card-bg)",
            color: "var(--subheading-color)",
          }}
        >
          <div
            className="col-sm-3 mobwidth"
            style={{
              backgroundColor: "var(--click-color)",
              color: "var(--white-color)",
            }}
          >
            <div className="home_two_counter_counter_item ptop">
              <div>
                <span class="material-symbols-outlined">workspace_premium</span>{" "}
                <h3 className="counter">
                  <span>
                    <CountUp
                      start={startYearNumber}
                      end={targetYearNumber}
                      duration={3.5}
                      separator=","
                    />
                  </span>
                  +
                </h3>
              </div>
              <p>Experience</p>
            </div>
          </div>
          <div className="col-sm-3 mobwidth mbod_right">
            <div className="home_two_counter_counter_item mbod_bottom">
              <div>
                <span class="material-symbols-outlined">
                  sentiment_satisfied
                </span>
                <h3>
                  <span>
                    <CountUp
                      start={startPatientNumber}
                      end={targetPatientNumber}
                      duration={3.5}
                      separator=","
                    />
                    K
                  </span>
                  +
                </h3>
              </div>
              <p>Happy</p>
            </div>
          </div>
          <div className="col-sm-3 mobwidth">
            <div className="home_two_counter_counter_item mbod_bottom">
              <div>
                <span class="material-symbols-outlined">support_agent</span>{" "}
                <h3 className="counter">
                  <span>
                    <CountUp
                      start={startBedNumber}
                      end={targetBedNumber}
                      duration={3.5}
                      separator=","
                    />
                  </span>
                  +
                </h3>
              </div>
              <p>Services</p>
            </div>
          </div>

          <div className="col-sm-3 mobwidth mbod_right">
            <div className="home_two_counter_counter_item ptop">
              <div>
                {" "}
                <span class="material-symbols-outlined">diversity_3</span>
                <h3 className="counter">
                  <span>
                    <CountUp
                      start={startDoctorNumber}
                      end={targetDoctorNumber}
                      duration={3.5}
                      separator=","
                    />
                  </span>
                  +
                </h3>
              </div>
              <p>Staff</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTwoCounter;
