import { useState } from "react";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useLogout } from "../../firebaseFiles/hooks/useLogout";
import { useCollection } from "../../firebaseFiles/hooks/useCollection";
// components
import BookingList from "../../commonRolePages/bookings/BookingList";
import AddBookingIcon from "../../components/HelpAndSupportIcon";
// styles
// import './UserDashboard.css'
const bookingFilter = ["PENDING", "COMPLETED", "CANCELLED", "INACTIVE"];

export default function PGCustomerBooking() {
  const { user } = useAuthContext();
  const { logout, isPending } = useLogout();
  const { documents: bookingdocuments, error: bookingerror } =
    // use this for descending order
    useCollection("bookings", null, ["bookingDate", "desc"]);
  // useCollection("bookings", ["userid", "==", user.uid]);
  // useCollection("bookings", ["createdBy:id", "==", user.uid]);

  // use this for ascending order
  // useCollection("bookings", null, ["bookingDate", "asc"]);

  // if we want to arrange order with more than one field then the syntax is
  // useCollection("bookings", null, [["bookingDate", "asc"], ["bookingTime", "desc"]]);

  const [filter, setFilter] = useState("PENDING");

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const bookings = bookingdocuments
    ? bookingdocuments.filter((document) => {
        let filteredProperty = false;
        switch (filter) {
          case "PENDING":
            if (document.status === "pending") {
              filteredProperty = true;
            }
            return filteredProperty;
          case "COMPLETED":
            if (document.status === "completed") {
              filteredProperty = true;
            }
            return filteredProperty;
          case "CANCELLED":
            if (document.status === "cancelled") {
              filteredProperty = true;
            }
            return filteredProperty;
          default:
            return true;
        }
      })
    : null;

  // console.log('bookings:', bookings)

  return (
    <div className="pgadmindasboard pg_padding">
      <AddBookingIcon />
      <div className="container-fluid">
        <div class="usp_data">
          <div class="box_single t_user">
            <span class="value">200</span>
            <h4>Total Users</h4>
          </div>
          <div class="box_single t_search_product">
            <span class="value">65</span>
            <h4>Today Bookings</h4>
          </div>
          <div class="box_single t_product">
            <span class="value">20</span>
            <h4>Due Amounts</h4>
          </div>
        </div>
      </div>
      <div>
        {/* {bookingerror && <p className="error">{bookingerror}</p>}
        {bookingdocuments && (
          <Filters
            changeFilter={changeFilter}
            filterList={bookingFilter}
            filterLength={bookings.length}
          />
        )}      */}
        {bookings && <BookingList bookings={bookings} />}
      </div>
    </div>
  );
}
