import React, { useState } from "react";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useCollection } from "../../firebaseFiles/hooks/useCollection";
import { useNavigate } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import AddNotification from "../../allRoles/admin/addNotification/AddNotification";
import { projectStorage } from "../../firebase/config";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import Popup from "../../components/Popup";
import ReactSwitch from "react-switch";

import "./Notification.css";

const Notification = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const [expandedIds, setExpandedIds] = useState([]);

  const handleShowMoreClick = (notificationId) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(notificationId)
        ? prevIds.filter((id) => id !== notificationId)
        : [...prevIds, notificationId]
    );
  };

  const isExpanded = (notificationId) => expandedIds.includes(notificationId);

  const { documents: dbnotifications, error: dbnotificationserror } =
    useCollection(
      "notifications",
      ["status", "==", "active"],
      ["createdAt", "desc"]
    );

  //     .collection("notifications")
  // .orderBy("createdAt", "desc")

  // update document
  const { updateDocument, deleteDocument } = useFirestore("notifications");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting product ID:", productIdToDelete);
      const documentToDelete = dbnotifications.find(
        (doc) => doc.id === productIdToDelete
      );
      console.log("Document to delete:", documentToDelete);

      if (documentToDelete) {
        // Log the constructed storage path with the filename
        const storagePath = `images/${productIdToDelete}`;

        // Recursive function to delete all files and subfolders
        const deleteFolderRecursive = async (ref) => {
          const listResult = await ref.listAll();
          const deletePromises = listResult.items.map((item) => item.delete());
          await Promise.all(deletePromises);

          // Recursively delete subfolders
          const deleteFolderPromises = listResult.prefixes.map(
            deleteFolderRecursive
          );
          await Promise.all(deleteFolderPromises);
        };

        // Get the reference to the folder
        const storageRef = projectStorage.ref(storagePath);

        // Start the recursive deletion
        await deleteFolderRecursive(storageRef);

        // Delete the document from Firestore
        await deleteDocument(productIdToDelete);

        console.log("Offer deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };
  return (
    <div className="container">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this notification?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div className="row notification_div_parent">
        <AddNotification />
        <div className="pg_header">
          <h2 className="p_title">Alert Center!</h2>
          <h4 className="p_subtitle">Your Notifications, Stay Updated</h4>
        </div>
        {dbnotifications &&
          dbnotifications.map((notification) => (
            <div className="col-12 col-md-6 col-lg-4" key={notification.id}>
              <div
                className={`propagentusersingle ${
                  isExpanded(notification.id) ? "showmore" : ""
                }`}
              >
                <div className="left">
                  <div>
                    <div className="img_div">
                      <img src="./assets/img/loudspeaker.jpg" alt="" />
                    </div>
                  </div>
                  <div className="">
                    <h5 className="name">{notification.heading}</h5>
                    <h6
                      className={`phone_number ${
                        isExpanded(notification.id) ? "expanded" : ""
                      }`}
                    >
                      {notification.longDescription}
                    </h6>
                  </div>
                </div>
                <h4 className="notification_date">
                  <span>
                    {formatDistanceToNow(notification.createdAt.toDate(), {
                      addSuffix: true,
                    })}
                  </span>
                </h4>
                {notification.longDescription.length > 110 && (
                  <h6
                    onClick={() => handleShowMoreClick(notification.id)}
                    className="expand_line pointer"
                  >
                    {isExpanded(notification.id) ? "show less" : "show more"}
                  </h6>
                )}
              </div>
              {isAdmin && (
                <>
                  <div style={{ margin: "0px 0px 20px 15px" }}>
                    <MDBRow>
                      <MDBCol className="col-9">
                        <MDBCardText className="text-muted">
                          <div className="d-flex align-items-center mt-2">
                            <div>
                              <p
                                className="active-inactive-text"
                                style={{ color: "red" }}
                              >
                                Inactive
                              </p>
                            </div>
                            <ReactSwitch
                              checked={notification.status == "active"}
                              onChange={() =>
                                handleToggleChange(
                                  notification.id,
                                  notification.status
                                )
                              }
                              onColor="#149AA2"
                              offColor="#F05B83"
                              className="products-status-switch"
                            />
                            <div>
                              <p
                                className="active-inactive-text"
                                style={{ color: "red" }}
                              >
                                Active
                              </p>
                            </div>
                          </div>
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="col-3 d-flex justify-content-center mt-2">
                        <div>
                          <MDBCardText
                            onClick={() => handleDeleteProduct(notification.id)}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{
                                fontSize: "23px",
                              }}
                            >
                              delete
                            </span>
                          </MDBCardText>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Notification;
