import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useEffect, useState } from "react";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useLogout } from "../../firebaseFiles/hooks/useLogout";
import Popup from "../../components/Popup";
import PhoneInput from "react-phone-input-2";
import { useImageUpload } from "../../firebaseFiles/hooks/useImageUpload";
import {
  projectStorage,
  projectAuth,
  projectAuthObj,
  projectFirestore,
} from "../../firebase/config";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";

// styles
import "./PGProfile.css";

export default function PGProfile() {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  const [userFullName, setUserFullName] = useState();

  const { updateDocument, response } = useFirestore("users");

  const { document: appTypeDocument, error: appTypeDocumentError } =
    useDocument("settings", "AppType");

  const { document: careerDoc, error: careerDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const [formError, setFormError] = useState(null);
  const { logout, isPending } = useLogout();

  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  const [changeNumberScroller, setchangeNumberScroller] = useState(false);

  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [error, setError] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    user && user.phoneNumber
  );
  const [changeNumberDisplay, setchangeNumberDisplay] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const { imgUpload, isImgCompressPending, imgCompressedFile } =
    useImageUpload();

  const [AddRole, setAddRole] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selctedRole, setRole] = useState([]);
  const [selectedRoleIndex, setSelectedRolesIndex] = useState(null);
  const [isAddRole, setIsAddRole] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const navigate = useNavigate();

  const handleSendVerificationCode = async (e) => {
    e.preventDefault();
    setError("");
    // console.log("phone:", phone);
    if (
      newPhoneNumber === "" ||
      newPhoneNumber === undefined ||
      newPhoneNumber.length < 10
    ) {
      return setError("Please enter valid Phone Number");
    }
    try {
      // console.log('newPhoneNumber: ', newPhoneNumber)

      // hide buttons
      // id_sendotpButton
      document.getElementById("id_sendotpButton").style.display = "none";

      const phoneProvider = new projectAuthObj.PhoneAuthProvider();
      // console.log('phoneProvider: ', phoneProvider)
      const recaptchaVerifier = new projectAuthObj.RecaptchaVerifier(
        "recaptcha-container"
      );
      // console.log('recaptchaVerifier: ', recaptchaVerifier)
      var newPhoneNumberWithPlus = "+" + newPhoneNumber;
      const id = await phoneProvider.verifyPhoneNumber(
        newPhoneNumberWithPlus,
        recaptchaVerifier
      );
      // console.log('id: ', id)
      setVerificationId(id);

      setchangeNumberScroller(true);
      // alert('Verification code sent!');
    } catch (error) {
      console.error("Error sending verification code:", error.message);
      setError("Error sending verification code");
    }
  };
  const handleChangePhoneNumber = async () => {
    // console.log('In handleChangePhoneNumber')
    try {
      const credential = projectAuthObj.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const currentUser = projectAuth.currentUser;
      await currentUser.updatePhoneNumber(credential);

      // console.log('Phone number updated successfully!')

      //Logout and redirect to login page
      logout();
    } catch (error) {
      // Handle any errors
      console.error("Error changing phone number:", error.message);
      setError("Error changing phone number");
    }
  };
  //Popup Flags
  useEffect(() => {
    if (popupReturn) {
      //Logout
      logout();
    }
  }, [popupReturn]);

  //Popup Flags
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };

  const showDashboard = () => {
    if (user && user.role === "superadmin") {
      // console.log('in superadmin', user.role)
      navigate("/superadmindashboard");
    }

    if (user && user.role === "admin") {
      // console.log('in admin', user.role)
      navigate("/admindashboard");
    }

    if (user && user.role === "owner") {
      // console.log('in user', user.role)
      navigate("/ownerdashboard");
    }

    if (user && user.role === "tenant") {
      // console.log('in user', user.role)
      navigate("/tenantdashboard");
    }

    if (user && user.role === "customer") {
      // console.log('in user', user.role)
      navigate("/customerdashboard");
    }

    if (user && user.role === "user") {
      // console.log('in user', user.role)
      navigate("/userdashboard");
    }
  };

  const handleFileChange = async (e) => {
    // setThumbnail(null)
    let file = e.target.files[0];
    // console.log('file original selected:', file)
    // console.log('file size original selected:', file.size)

    const compressedImage = await imgUpload(file, 300, 300);

    if (!compressedImage) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!compressedImage.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    // setThumbnailError(null)

    let imgUrl = "";
    const thumbnailName = "profile.png";
    if (compressedImage) {
      const uploadPath = `userThumbnails/${user.uid}/${thumbnailName}`;
      const img = await projectStorage.ref(uploadPath).put(compressedImage);
      imgUrl = await img.ref.getDownloadURL();
      // console.log('imgUrl:', imgUrl)
      await updateDocument(user.uid, {
        photoURL: imgUrl,
      });
      // await user.updateProfile({ photoURL: imgUrl })
    } else {
      //if thumbnail is null
      imgUrl =
        "https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2";
    }
  };

  // for edit name
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  // for edit phonenumber
  const [isnProfileEdit, setnisProfileEdit] = useState(false);

  // for cursor in fields
  const fullNameInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  // for cursor in fields

  // handel for edit name
  const handleProfileEdit = async (e) => {
    setIsProfileEdit(true);
  };

  // Function to save changes to user's full name
  const handleProfileSave = async () => {
    setIsProfileEdit(false);

    try {
      // Update the user's full name in Firestore
      await updateDocument(user.uid, {
        fullName: userFullName,
      });
      console.log("User's full name updated successfully:", userFullName);
    } catch (error) {
      console.error("Error updating user's full name:", error);
      // Handle the error as needed
    }
  };
  //end handel for edit name

  // START CODE FOR ROLE
  const handleAddRoleClick = () => {
    setIsAddRole(true);
  };
  const handleSaveAddRole = async () => {
    const lowercaseRole = AddRole.toLowerCase(); // Convert role name to lowercase

    try {
      console.log("New role to add:", lowercaseRole); // Check if the new role is set correctly

      // Get the current roles array from the document
      const currentRoles = (user && user.roles) || [];

      // Add the new option to the array
      const updatedRoles = [...currentRoles, lowercaseRole];

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRoles });

      console.log("Roles updated successfully"); // Log success message

      // Reset the AddRole state and close the input field
      setAddRole("");
      setIsAddRole(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  const handleCancelAddRole = () => {
    setIsAddRole(false);
  };

  // const handleRoleChange = (index) => {
  //   setSelectedRole(!selectedRole);
  //   setIsRoleSelected(true);
  //   setSelectedRolesIndex(index);
  // };
  const handleRoleChange = async (roles) => {
    setSelectedRole(roles);
    setIsRoleSelected(true);
    try {
      // Update the user's role in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ role: roles });

      console.log("User role updated successfully to:", roles); // Log success message
    } catch (error) {
      console.error("Error updating user role:", error);
      // Handle the error as needed
    }
  };

  // Fetch roles array from the document state
  const roles =
    user && user
      ? ((user && user.roles) || []).map((role) => role.toLowerCase())
      : [];
  // delete option
  const handleDeleteRole = async (index) => {
    try {
      // Get the current roles array from the document
      const currentRole = (user && user.roles) || [];

      // Remove the selected option from the array
      const updatedRole = currentRole.filter((_, i) => i !== index);

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRole });
    } catch (error) {
      console.error("Error deleting role:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY ROLE

  // CHANGE PHONE NUMBER VIA OTP
  const changeNumberBack = () => {
    // id_sendotpButton
    document.getElementById("id_sendotpButton").style.display = "block";
    setchangeNumberScroller(false);
  };
  const openChangeNumber = () => {
    setchangeNumberDisplay(true);
  };
  const closeChangeNumber = () => {
    setchangeNumberDisplay(false);
  };

  // --------------------HTML UI Codebase------------------
  return (
    <div className="pgprofile">
      {/* Popup Component */}
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to logout?"}
      />

      {/* START CODE FOR CHANGE NUMBER VIA OTP */}
      <div
        className={
          changeNumberDisplay
            ? "pop-up-change-number-div open"
            : "pop-up-change-number-div"
        }
      >
        <div className="direct-div">
          <span
            onClick={closeChangeNumber}
            className="material-symbols-outlined close-button"
          >
            close
          </span>
          <span style={{ fontSize: "0.8rem" }}>
            Existing no: {user && user.phoneNumber}
          </span>
          <h3 style={{ color: "var(--pink)" }}>Enter New Number</h3>

          <div className="sroll-outter-div">
            <div className="sroll-inner-div">
              <div
                className="scroll-div"
                style={{
                  transform: changeNumberScroller
                    ? "translateX(-100%)"
                    : "translateX(0%)",
                }}
              >
                {error && <Alert variant="danger">{error}</Alert>}

                <form
                  action=""
                  onSubmit={handleSendVerificationCode}
                  className="form-w"
                >
                  <div className="fl_form_field change-number-input">
                    <PhoneInput
                      country={"in"}
                      // onlyCountries={['in', 'us']}
                      value={newPhoneNumber}
                      onChange={setNewPhoneNumber}
                      international
                      keyboardType="phone-pad"
                      // countryCallingCodeEditable={false}
                      // countryCodeEditable={false}
                      // disableCountryCode={true}
                      placeholder="Enter New Mobile Number"
                      inputProps={{
                        name: "newPhoneNumber",
                        required: true,
                        autoFocus: true,
                      }}
                    ></PhoneInput>
                  </div>

                  {/* new code */}
                  <div
                    id="id_sendotpButton"
                    className="change-number-button-div"
                  >
                    <button onClick={closeChangeNumber} className="btn_border">
                      Cancel
                    </button>
                    <button className="btn_fill">Send OTP</button>
                  </div>
                </form>
              </div>

              <div
                className="scroll-div"
                style={{
                  transform: changeNumberScroller
                    ? "translateX(-100%)"
                    : "translateX(0%)",
                }}
              >
                <div>
                  <input
                    type="text"
                    placeholder="Enter verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="pgprofile-change-numb-inpt"
                  />

                  {/* new code */}
                  <div className="change-number-button-div">
                    <button onClick={changeNumberBack} className="btn_border">
                      Back
                    </button>
                    <button
                      onClick={handleChangePhoneNumber}
                      className="btn_fill"
                    >
                      Confirm
                    </button>
                  </div>
                  {/* new code */}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: changeNumberScroller ? "none" : "block",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            id="recaptcha-container"
          ></div>
          <div
            style={{
              display: changeNumberScroller ? "none" : "block",
              height: "80px",
            }}
          ></div>
        </div>
      </div>
      {/* END CODE FOR CHANGE NUMBER VIA OTP */}

      {user && (
        <div className="container">
          <div className="profile_pg">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-7">
                <div className="pp_card">
                  <div className="title">My Profile</div>
                  <div className="mydetail">
                    <div className="left">
                      <div className="my_pic">
                        <img
                          src={user && user.photoURL}
                          className="user_img"
                        ></img>

                        <div className="edit_icon pointer">
                          <input
                            type="file"
                            onChange={handleFileChange}
                            id="profile-upload-input"
                            className="profile-upload-input"
                          />

                          <label
                            htmlFor="profile-upload-input"
                            className="profile-upload-label pointer"
                          >
                            <img src="./assets/img/edit_icon.png"></img>
                          </label>
                        </div>
                      </div>
                      <h3 className="my_name">
                        <div
                          className={`c_name relative ${
                            isProfileEdit
                              ? "cn_editlabel cn_editlabel2"
                              : "cn_label cn_label2"
                          }`}
                        >
                          <input
                            type="text"
                            className="profile-change-name"
                            readOnly={!isProfileEdit}
                            value={
                              isProfileEdit
                                ? userFullName
                                : user && user.fullName
                            }
                            onChange={(e) => setUserFullName(e.target.value)}
                            placeholder="Add your name"
                          />
                          <div className="edit_bg pointer">
                            {!isProfileEdit && (
                              <img
                                src="./assets/img/edit_icon.png"
                                className="ed_icon"
                                onClick={handleProfileEdit}
                                style={{ width: "16px" }}
                              />
                            )}
                            {isProfileEdit && (
                              <img
                                src="./assets/img/done.png"
                                className="ed_icon"
                                onClick={handleProfileSave}
                              />
                            )}
                          </div>
                        </div>
                      </h3>
                    </div>
                    <div className="right">
                      <div className="profile-page-content">
                        <h6>Phone Number</h6>
                        <h4 className="relative">
                          <div
                            className={`c_name ${
                              isnProfileEdit ? "cn_editlabel" : "cn_label"
                            }`}
                          >
                            <input
                              type="number"
                              maxLength={10}
                              readOnly={isnProfileEdit ? false : true}
                              className="profile-change-phone"
                              onChange={(e) =>
                                setUserPhoneNumber(e.target.value)
                              }
                              value={user && user.phoneNumber}
                              ref={phoneNumberInputRef}
                            ></input>
                            <div className="edit_bg pointer">
                              <span
                                className="material-symbols-outlined"
                                onClick={openChangeNumber}
                              >
                                edit
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                      {/* {roles.length > 1 && ( */}
                      {user && user.roles.includes("admin") && (
                        <div className="profile-page-content role">
                          <h6>Role</h6>
                          <div className="radio-container">
                            {roles &&
                              roles.map((roles, index) => (
                                <label
                                  key={index}
                                  className={`radio-container ${
                                    selectedRoleIndex === index
                                      ? "radio_btn_checked"
                                      : "service_details_radion_btn"
                                  }`}
                                  style={{
                                    color:
                                      user && user.role === roles
                                        ? "var(--white-color)"
                                        : "inherit",
                                    backgroundColor:
                                      user && user.role === roles
                                        ? "var(--click-color)"
                                        : "inherit",
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="role"
                                      value={roles}
                                      onChange={() => handleRoleChange(roles)}
                                      className="radio-button"
                                    />
                                    <span
                                      className="material-symbols-outlined"
                                      style={{ fontSize: "21px" }}
                                    >
                                      {user && user.role === roles
                                        ? "done"
                                        : "add"}
                                    </span>
                                    {roles}
                                    {user && user.role === "admin" ? (
                                      <span
                                        class="material-symbols-outlined"
                                        onClick={() => handleDeleteRole(index)}
                                      >
                                        close
                                      </span>
                                    ) : null}
                                  </div>
                                </label>
                              ))}{" "}
                            {isAddRole ? (
                              <div>
                                <input
                                  type="text"
                                  value={AddRole}
                                  onChange={(e) => setAddRole(e.target.value)}
                                />
                                <div className="d-flex">
                                  <button
                                    className="product_edit_save_btn"
                                    onClick={handleSaveAddRole}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="product_edit_save_btn cancel-btn"
                                    onClick={handleCancelAddRole}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                {user && user.role == "admin" && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={handleAddRoleClick}
                                    style={{ fontSize: "24px" }}
                                  >
                                    add_box
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* )} */}
                    </div>
                  </div>

                  <div className="myl_innercard">
                    {appTypeDocument && appTypeDocument.apptype == "lite" ? (
                      <>
                        <div className="myli_single bgdark">
                          <div className="profile-page-content">
                            <h6>Visit Services for more deatils</h6>
                            <Link to="/services">
                              <button className="btn_fill pointer mt-2">
                                Services
                              </button>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="myli_single bgdark">
                          <div className="profile-page-content">
                            <h6>Visit Dashboard for more deatils</h6>
                            <button
                              className="btn_fill pointer mt-2"
                              onClick={showDashboard}
                            >
                              Dashboard
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {appTypeDocument &&
                    appTypeDocument.apptype == "lite" ? null : (
                      <>
                        <div className="myli_single bglight mobile">
                          <div className="profile-page-content">
                            <h6>Total Bookings</h6>
                            <h4>32</h4>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="pp_card rightside">
                  <div className="">
                    <h6 className="more">More</h6>
                    {(appTypeDocument &&
                      appTypeDocument.apptype === "liteplus") ||
                    (appTypeDocument && appTypeDocument.apptype === "pro") ? (
                      <div className="myli_single bglight mt-3 pointer">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                notifications
                              </span>
                            </div>
                          </div>
                          <h6>
                            Notification
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </div>
                    ) : null}
                    <div className="myli_single bglight mb-3 pointer">
                      <Link to="/gallery">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                image
                              </span>
                            </div>
                          </div>
                          <h6>
                            Gallery
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div>
                    <div className="myli_single bglight pointer">
                      <Link to="/contact">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                support
                              </span>
                            </div>
                          </div>
                          <h6>
                            Help & Support
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div>
                    <div className="myli_single bglight mt-3 pointer">
                      <Link to="/faq">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                info
                              </span>
                            </div>
                          </div>
                          <h6>
                            FAQ
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div>

                    {careerDoc && careerDoc.career == true && (
                      <div className="myli_single bglight mt-3 pointer">
                        <Link to="/career">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              Career
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                    )}

                    <div
                      className="myli_single bglight mt-3 pointer"
                      onClick={showPopup}
                    >
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                        </div>
                        <h6>
                          Logout
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
