import React, { useState, useEffect } from "react";
import { useDocument } from "./useDocument"; // Assuming you have a useDocument hook

const useColors = () => {
  const { document: colorsDocument, error: colorsDocumentError } = useDocument(
    "settings",
    "Colors"
  );
  const [colors, setColors] = useState(null);

  useEffect(() => {
    if (colorsDocument) {
      // Extract colors from the LightScreenTheme map
      const lightScreenThemeColors = colorsDocument.LightScreenTheme;
      const clickColor = lightScreenThemeColors?.clickColor;
      const cardBgColor = lightScreenThemeColors?.cardBgColor;
      const headingColor = lightScreenThemeColors?.headingColor;
      const subheadingColor = lightScreenThemeColors?.subheadingColor;

      // Set colors state
      setColors({ clickColor, cardBgColor, headingColor, subheadingColor });
    }
  }, [colorsDocument]);

  return { colors, colorsDocumentError };
};

export default useColors;
