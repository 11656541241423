import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { projectFirestore, projectStorage } from "../../firebase/config.js";
import { useCollection } from "../../firebaseFiles/hooks/useCollection";
import PartnerSlider from "../../components/PartnerSlider.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useRef } from "react";
import Popup from "../../components/Popup";
import ReactSwitch from "react-switch";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FaHeart } from "react-icons/fa";
import TextEditor from "../../components/TextEditor.jsx";

import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import { FaEdit } from "react-icons/fa";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ServiceDetails.css";

const ServiceDetails = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Start firebase code
  const { user } = useAuthContext();
  const { deleteDocument } = useFirestore("services");
  const { productId } = useParams();

  const { document: appTypeDocument, error: appTypeError } = useDocument(
    "settings",
    "AppType"
  );
  const { document: functionTypeDocument, error: functionTypeError } =
    useDocument("settings", "FunctionType");

  const { documents: allBookings, error: bookingsError } =
    useCollection("bookings");
  // End Firebase code

  // delete popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const fileInputRef = useRef(null);
  const [checked, setChecked] = useState(); // Toggle button state
  const [editedName, setEditedName] = useState("");
  const [productName, setProductName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedPrice, setEditedPrice] = useState("");
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [productPrice, setProductPrice] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [isEditingRating, setIsEditingRating] = useState(false);
  const [editedRating, setEditedRating] = useState("");
  const [productDiscount, setProductDiscount] = useState("");
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [editedDiscount, setEditedDiscount] = useState("");
  const [AddOption, setAddOption] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selctedOption, setOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [isAddOption, setIsAddOption] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [editedShortDescription, setEditedShortDescription] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [isEditingShortDescription, setIsEditingShortDescription] =
    useState(false);
  const [productImages, setProductImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null);
  const [isRatingSelected, setIsRatingSelected] = useState(false);
  const [selectedRatingIndex, setSelectedRatingsIndex] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [editedExternalUrl, setEditedExternalUrl] = useState("");
  const [isEditingProductUrl, setIsEditingExternalUrl] = useState(false);
  const [isHeartClicked, setIsHeartClicked] = useState({});

  // Use selectedProductId for fetching document and error
  const { document, error } = useDocument(
    "services",
    selectedProductId || productId
  );

  useEffect(() => {
    // Fetch document data and set editedName
    if (document) {
      setProductName(document.name);
      setEditedName(document.name);
      setProductPrice(document.price);
      setEditedPrice(document.price);
      setProductDescription(document.description);
      setEditedDescription(document.description);
      setProductShortDescription(document.short_description);
      setEditedShortDescription(document.short_description);
      setProductDiscount(document.discount);
      setEditedDiscount(document.discount);
    }
  }, [document]);

  //START CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE
  // Initialize heart status object in useEffect
  useEffect(() => {
    if (document) {
      const localStorageKey = `heartStatus_${document.id}`;
      const storedHeartStatus = localStorage.getItem(localStorageKey);
      if (storedHeartStatus !== null) {
        setIsHeartClicked({ [document.id]: storedHeartStatus === "true" });
      } else {
        setIsHeartClicked({ [document.id]: false });
      }
    }
  }, [document]);

  // Update toggleHeartStatus function
  const toggleHeartStatus = (productId) => {
    const newHeartStatus = !isHeartClicked[productId];
    setIsHeartClicked((prevState) => ({
      ...prevState,
      [productId]: newHeartStatus,
    }));
    localStorage.setItem(`heartStatus_${productId}`, newHeartStatus);
  };

  //END CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE

  // Function to handle image click and update selectedProductId
  const handleImageClick = (productId) => {
    setSelectedProductId(productId); // Update selectedProductId with the clicked product ID
    window.history.replaceState(null, null, `/product/${productId}`);
  };

  //START FETCH IMAGES FROM FIREBASE FOR OWL CAROUSEL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const servicesCollection = await projectFirestore
          .collection("services")
          .get();
        const servicesData = [];

        servicesCollection.forEach((doc) => {
          const product = doc.data();
          servicesData.push({
            id: doc.id,
            name: product.name,
            images: product.images || [], // Assuming "images" is the array of image names
            status: product.status,
          });
        });
        setAllProducts(servicesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  //END FETCH IMAGES FROM FIREBASE FOR OWL CAROUSEL

  //START  OWL CAROUSEL
  useEffect(() => {
    // Initialize Owl Carousel with the fetched products
    window.$(".owl-carousel").owlCarousel({
      className: "owl-theme",
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 5000,
      smartSpeed: 4000,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }, [allProducts]);
  //END OWL CAROUSEL

  if (error) {
    return (
      <div className="error" style={{ marginTop: "100px" }}>
        Data Not Found
      </div>
    );
  }
  if (!document) {
    return <div className="loading">Loading...</div>;
  }

  // START CODE FOR ADD NEW IMAGES
  const handleAddMoreImages = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsConfirmVisible(true);
    }
  };

  const handleConfirmUpload = async () => {
    if (selectedImage) {
      setIsUploading(true); // Set isUploading to false when upload completes
      try {
        const file = fileInputRef.current.files[0];
        const storageRef = projectStorage.ref(
          `product_img/${productId}/${file.name}`
        );
        await storageRef.put(file);

        const downloadURL = await storageRef.getDownloadURL();
        const updatedImages = [...(document.images || []), downloadURL];

        await projectFirestore
          .collection("services")
          .doc(productId)
          .update({ images: updatedImages });
        setProductImages(updatedImages);

        setSelectedImage(null);
        setIsConfirmVisible(false);
        setIsUploading(false); // Set isUploading to false when upload completes
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const images = document.images || [];
  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setIsEditingName(true);
        break;
      case "rating":
        setIsEditingRating(true);
        break;
      case "price":
        setIsEditingPrice(true);
        break;
      case "discount":
        setIsEditingDiscount(true);
        break;
      case "shortdescription":
        setIsEditingShortDescription(true);
        break;
      case "producturl":
        setIsEditingExternalUrl(true);
        break;
      case "description":
        setIsEditingDescription(true);
        break;
      default:
        break;
    }
  };

  const handleSaveClick = async (fieldName) => {
    try {
      switch (fieldName) {
        case "name":
          setIsEditingName(false);
          break;
        case "price":
          setIsEditingPrice(false);
          break;
        case "discount":
          setIsEditingDiscount(false);
          break;
        case "shortdescription":
          setIsEditingShortDescription(false);
          break;
        case "externalurl":
          setIsEditingExternalUrl(false);
          break;
        case "description":
          setIsEditingDescription(false);
          break;
        // Add more cases for other fields if needed
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setEditedName(document.name);
        setIsEditingName(false);
        break;
      case "rating":
        setEditedRating(document.rating);
        setIsEditingRating(false);
        break;
      case "price":
        setEditedPrice(document.price);
        setIsEditingPrice(false);
        break;
      case "discount":
        setEditedDiscount(document.discount);
        setIsEditingDiscount(false);
        break;
      case "shortdescription":
        setEditedShortDescription(document.short_description);
        setIsEditingShortDescription(false);
        break;
      case "externalurl":
        setEditedExternalUrl(document.external_url);
        setIsEditingExternalUrl(false);
        break;
      case "description":
        setEditedDescription(document.description);
        setIsEditingDescription(false);
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  // START CODE FOR SHARE SERVICE
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };
  // END CODE FOR SHARE SERVICE

  // START CODE FOR CHANGE SERVICE STATUS
  const handleToggleChange = (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Update the status in Firestore
    projectFirestore
      .collection("services")
      .doc(productId)
      .update({ status: newStatus })
      .then(() => {
        console.log("Product status updated successfully");
        setChecked(newStatus === "active"); // Update the toggle state based on the new status
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };
  // END CODE FOR CHANGE SERVICE STATUS

  // delete document code
  const handleDeleteProduct = async () => {
    try {
      // Open the delete confirmation popup
      setIsDeletePopupOpen(true);
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };
  const handleConfirmDeleteProduct = async () => {
    try {
      await deleteDocument(productId);
      // Redirect to the services page
      window.location.href = "/services";
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };

  // START CODE FOR CATEGORY OPTIONS
  const handleAddOptionClick = () => {
    setIsAddOption(true);
  };
  const handleSaveAddOption = async () => {
    try {
      // Get the current options array from the document
      const currentOptions = document.options || [];

      // Add the new option to the array
      const updatedOptions = [...currentOptions, AddOption];

      // Update the options array in Firestore
      await projectFirestore
        .collection("services")
        .doc(productId)
        .update({ options: updatedOptions });

      // Reset the AddOption state and close the input field
      setAddOption("");
      setIsAddOption(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  const handleCancelAddOption = () => {
    setIsAddOption(false);
  };
  const handleOptionChange = (index) => {
    setSelectedOption(!selectedOption);
    setIsOptionSelected(true);
    setSelectedOptionIndex(index);
  };
  // Fetch options array from the document state
  const options = document.options || [];
  // delete option
  const handleDeleteOption = async (index) => {
    try {
      // Get the current options array from the document
      const currentOptions = document.options || [];

      // Remove the selected option from the array
      const updatedOptions = currentOptions.filter((_, i) => i !== index);

      // Update the options array in Firestore
      await projectFirestore
        .collection("services")
        .doc(productId)
        .update({ options: updatedOptions });
    } catch (error) {
      console.error("Error deleting option:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY OPTIONS

  // RATING CODE START
  const handleRatingChange = async (ratings) => {
    setSelectedRating(ratings);
    setIsRatingSelected(true);
    try {
      // Update the user's rating in Firestore
      await projectFirestore
        .collection("services")
        .doc(document.id)
        .update({ rating: ratings });

      console.log("User role updated successfully to:", ratings); // Log success message
    } catch (error) {
      console.error("Error updating user role:", error);
      // Handle the error as needed
    }
  };

  // Fetch roles array from the document state
  const ratings = document
    ? (document.ratings || []).map((rating) => rating.toLowerCase())
    : [];
  // END RATING CODE

  // code for count total bookings for selected product
  const filteredBookings = allBookings
    ? allBookings.filter((booking) => booking.selectedProductId === productId)
    : [];

  return (
    <>
      <div className="services-details-parent-div">
        <Popup
          showPopupFlag={isDeletePopupOpen}
          setShowPopupFlag={setIsDeletePopupOpen}
          msg="Are you sure you want to delete this service?"
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              handleConfirmDeleteProduct();
            }
          }}
        />
        <div className="container">
          <div className="owl-carousel owl-theme carousel_services more-products-carosel">
            {allProducts &&
              allProducts
                .filter((product) => product.status === "active") // Filter by active status
                .map((product) => (
                  <div
                    key={product.id}
                    className="item carousel_img_parent"
                    onClick={() => handleImageClick(product.id)}
                  >
                    <img
                      src={product.images[0]} // Use the URL of the first image
                      alt={product.name}
                      className="more_services_carousel_img"
                    />
                    <h2 className="more_services_img_heading">
                      {/* <div>{product.name}</div> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.name.toString("html"),
                        }}
                      ></p>
                    </h2>
                  </div>
                ))}
          </div>
          <div
            className="d-flex justify-content-center product-details-pg-srvc-heading"
            style={{
              backgroundColor: "var(--click-color)",
              height: "100px",
              borderRadius: "20px",
            }}
          >
            <div>
              <h3>
                <span
                  dangerouslySetInnerHTML={{
                    __html: document.name.toString("html"),
                  }}
                ></span>
              </h3>
            </div>
          </div>

          <div className="services-images-slider-div-child">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="pcs_image_area">
                  <div className="bigimage_container">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        maxWidth: "600px",
                      }}
                    >
                      {user && user.role === "admin" && (
                        <div className="gallery-img-delete-icon">
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </div>
                      )}
                      <Gallery
                        style={{ background: "red" }}
                        items={images
                          .filter((url) => url)
                          .map((url, index) => ({
                            original: url,
                            thumbnail: url,
                            originalAlt: `Image ${index + 1}`,
                            thumbnailAlt: `Thumbnail ${index + 1}`,
                          }))}
                        slideDuration={1000}
                      />
                    </div>
                    {user && user.role === "admin" && (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <div className="d-flex">
                          {!isUploading && (
                            <button
                              className="btn_fill me-2"
                              onClick={handleAddMoreImages}
                            >
                              {isConfirmVisible
                                ? "Replace Image"
                                : "Add More Images"}
                            </button>
                          )}

                          {selectedImage && (
                            <button
                              className="btn_fill"
                              onClick={handleConfirmUpload}
                              disabled={!isConfirmVisible || isUploading} // Disable button when uploading
                            >
                              {isUploading ? "Uploading..." : "Confirm"}
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {selectedImage && (
                      <div style={{ textAlign: "center", marginTop: "15px" }}>
                        <img
                          src={selectedImage}
                          alt="Selected img"
                          style={{ maxWidth: "100px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 services_details_right_dv">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Name:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingName ? (
                            <div>
                              <TextEditor
                                initialDescription={editedName}
                                docFieldName="name"
                                documentId={productId}
                                collectionName="services"
                                onSave={() => handleSaveClick("name")}
                                onCancel={() => handleCancelClick("name")}
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: document.name.toString("html"),
                                }}
                              ></p>
                              {!isEditingName &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() => handleEditClick("name")}
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    {user && document.rating && user.role === "admin" ? (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Rating:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <MDBRow>
                                <div className="content role">
                                  <div className="radio-container">
                                    {/* Render all ratings arrays for admin */}
                                    {ratings.map((rating, index) => (
                                      <label
                                        key={index}
                                        className={`radio-container ${
                                          selectedRatingIndex === index
                                            ? "radio_btn_checked"
                                            : "service_details_radion_btn"
                                        }`}
                                        style={{
                                          color:
                                            document &&
                                            document.rating === rating
                                              ? "var(--white-color)"
                                              : "inherit",
                                          backgroundColor:
                                            document &&
                                            document.rating === rating
                                              ? "var(--click-color)"
                                              : "inherit",
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <input
                                            type="radio"
                                            name="rating"
                                            value={rating}
                                            onChange={() =>
                                              handleRatingChange(rating)
                                            }
                                            className="radio-button"
                                          />
                                          <span
                                            className="material-symbols-outlined"
                                            style={{ fontSize: "21px" }}
                                          >
                                            {document &&
                                            document.rating === rating
                                              ? "done"
                                              : "add"}
                                          </span>
                                          {rating}
                                        </div>
                                      </label>
                                    ))}
                                  </div>
                                </div>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      document.rating && (
                        <>
                          <MDBRow>
                            <MDBCol sm="3">
                              <MDBCardText>Rating:</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="9">
                              <MDBCardText className="text-muted">
                                <MDBRow>
                                  <div className="content role">
                                    <div className="radio-container">
                                      {/* Render only active rating for non-admin users */}
                                      {document.rating && (
                                        <label
                                          className={
                                            "radio-container radio_btn_checked"
                                          }
                                        >
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="radio"
                                              className="radio-button"
                                            />
                                            <span
                                              className="material-symbols-outlined"
                                              style={{ fontSize: "21px" }}
                                            >
                                              done
                                            </span>
                                            {document.rating}
                                          </div>
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                </MDBRow>
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )
                    )}
                    {((user && user.role === "admin") ||
                      (document.price && document.price !== "")) && (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Price:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {isEditingPrice ? (
                                <div>
                                  <div>
                                    <TextEditor
                                      initialDescription={editedPrice}
                                      docFieldName="price"
                                      documentId={productId}
                                      collectionName="services"
                                      onSave={() => handleSaveClick("price")}
                                      onCancel={() =>
                                        handleCancelClick("price")
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: document.price.toString("html"),
                                    }}
                                  ></p>
                                  &nbsp;&nbsp;
                                  <del style={{ color: "red" }}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          document.discount.toString("html"),
                                      }}
                                    ></p>
                                  </del>
                                  {!isEditingPrice &&
                                    user &&
                                    user.role === "admin" && (
                                      <FaEdit
                                        style={{
                                          fontSize: 20,
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          color: "var(--pink)",
                                        }}
                                        onClick={() => handleEditClick("price")}
                                      />
                                    )}
                                </div>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}

                    {((user && user.role === "admin") ||
                      (document.discount && document.discount !== "")) && (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Discount:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {isEditingDiscount ? (
                                <div>
                                  <TextEditor
                                    initialDescription={editedDiscount}
                                    docFieldName="discount"
                                    documentId={productId}
                                    collectionName="services"
                                    onSave={() => handleSaveClick("discount")}
                                    onCancel={() =>
                                      handleCancelClick("discount")
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="d-flex align-items-center">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        document.discount.toString("html"),
                                    }}
                                  ></p>
                                  {!isEditingName &&
                                    user &&
                                    user.role == "admin" && (
                                      <FaEdit
                                        style={{
                                          fontSize: 20,
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          color: "var(--pink)",
                                        }}
                                        onClick={() =>
                                          handleEditClick("discount")
                                        }
                                      />
                                    )}
                                </div>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Short Description:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingShortDescription ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedShortDescription}
                                  docFieldName="short_description"
                                  documentId={productId}
                                  collectionName="services"
                                  onSave={() =>
                                    handleSaveClick("shortdescription")
                                  }
                                  onCancel={() =>
                                    handleCancelClick("shortdescription")
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      document.short_description.toString(
                                        "html"
                                      ),
                                  }}
                                ></p>
                                {!isEditingShortDescription &&
                                  user &&
                                  user.role == "admin" && (
                                    <FaEdit
                                      style={{
                                        fontSize: 20,
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        color: "var(--pink)",
                                      }}
                                      onClick={() =>
                                        handleEditClick("shortdescription")
                                      }
                                    />
                                  )}
                              </div>
                            </>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    {(user && user.role === "admin") ||
                    (document && document.external_url !== "") ? (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Demo:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div>
                                {isEditingProductUrl ? (
                                  <div>
                                    <TextEditor
                                      initialDescription={editedExternalUrl}
                                      docFieldName="external_url"
                                      documentId={productId}
                                      collectionName="services"
                                      onSave={() =>
                                        handleSaveClick("externalurl")
                                      }
                                      onCancel={() =>
                                        handleCancelClick("externalurl")
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          document.external_url.toString(
                                            "html"
                                          ),
                                      }}
                                    ></p>
                                    {!isEditingProductUrl &&
                                      user &&
                                      user.role === "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--pink)",
                                          }}
                                          onClick={() =>
                                            handleEditClick("producturl")
                                          }
                                        />
                                      )}
                                  </div>
                                )}
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : null}

                    {appTypeDocument &&
                    appTypeDocument.apptype === "lite" ? null : (
                      <>
                        <hr />
                        <MDBRow>
                          <div className="radio-container">
                            {options.map((option, index) => (
                              <label
                                key={index}
                                className={`radio-container ${
                                  selectedOptionIndex === index
                                    ? "radio_btn_checked"
                                    : " service_details_radion_btn"
                                }`}
                              >
                                <div className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="option"
                                    value={option}
                                    onChange={() => handleOptionChange(index)}
                                    className="radio-button"
                                  />
                                  <span
                                    className="material-symbols-outlined"
                                    style={{ fontSize: "21px" }}
                                  >
                                    {selectedOptionIndex === index
                                      ? "done"
                                      : "add"}
                                  </span>
                                  {selctedOption && options}
                                  {user && user.role === "admin" ? (
                                    <span
                                      class="material-symbols-outlined"
                                      onClick={() => handleDeleteOption(index)}
                                    >
                                      close
                                    </span>
                                  ) : null}
                                </div>
                              </label>
                            ))}
                            {isAddOption ? (
                              <div>
                                <input
                                  type="text"
                                  value={AddOption}
                                  onChange={(e) => setAddOption(e.target.value)}
                                />
                                <div className="d-flex">
                                  <button
                                    className="product_edit_save_btn"
                                    onClick={handleSaveAddOption}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="product_edit_save_btn cancel-btn"
                                    onClick={handleCancelAddOption}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                {user && user.role == "admin" && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={handleAddOptionClick}
                                    style={{ fontSize: "24px" }}
                                  >
                                    add_box
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </MDBRow>
                        <hr />
                      </>
                    )}
                    {user && user.role == "admin" && (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Status: </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex">
                                <div>
                                  <p style={{ marginTop: "2px" }}> Inactive</p>
                                </div>
                                <ReactSwitch
                                  checked={document.status === "active"}
                                  onChange={() =>
                                    handleToggleChange(
                                      document.id,
                                      document.status
                                    )
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p style={{ marginTop: "2px" }}>Active</p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </MDBCardBody>
                </MDBCard>
                <div className="d-flex align-items-center namesd">
                  <MDBCol className="d-flex align-items-center">
                    <MDBCardText style={{ cursor: "pointer" }}>
                      <span
                        className="material-symbols-outlined"
                        onClick={shareURL}
                        style={{ fontSize: "23px", padding: "6px 0px" }}
                      >
                        share
                      </span>
                    </MDBCardText>
                    <MDBCardText
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <FaHeart
                        style={{
                          fontSize: 20,
                          cursor: "pointer",
                          color: isHeartClicked[document.id] ? "red" : "grey",
                        }}
                        onClick={() => toggleHeartStatus(document.id)}
                      />
                    </MDBCardText>

                    {user && user.role == "admin" && (
                      <MDBCardText
                        onClick={handleDeleteProduct}
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "23px", padding: "6px 0px" }}
                        >
                          delete
                        </span>
                      </MDBCardText>
                    )}

                    {appTypeDocument &&
                    appTypeDocument.apptype === "lite" ? null : (
                      <>
                        <MDBCardText
                          style={{
                            display: "flex",
                            padding: "7px 0px 0px 10px",
                          }}
                        >
                          <h4 style={{ fontSize: "15px" }}>
                            | &nbsp; {filteredBookings.length}+ Bookings
                          </h4>
                        </MDBCardText>
                      </>
                    )}
                  </MDBCol>

                  {appTypeDocument && appTypeDocument.apptype === "lite" ? (
                    <>
                      {user ? (
                        functionTypeDocument &&
                        functionTypeDocument.function == "ecommerce" ? (
                          <>
                            <div className="more-div-big-icon-div-inner pointer">
                              <Link to="/contact">
                                <button
                                  type="submit"
                                  className="booking_services_book_now_btn"
                                >
                                  Check Out
                                </button>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="more-div-big-icon-div-inner pointer">
                              <Link to={`/basicaddbooking/${document.id}`}>
                                <button
                                  type="submit"
                                  className="booking_services_book_now_btn"
                                  disabled={!isOptionSelected}
                                >
                                  Book Now
                                </button>
                              </Link>
                            </div>
                          </>
                        )
                      ) : (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to="/login">
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Login
                            </button>
                          </Link>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {user ? (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to={`/basicaddbooking/${document.id}`}>
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                              disabled={!isOptionSelected}
                            >
                              Check Out
                            </button>
                          </Link>
                        </div>
                      ) : (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to="/login">
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Check Out
                            </button>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {appTypeDocument &&
                appTypeDocument.apptype === "lite" ? null : (
                  <>
                    {!isOptionSelected && (
                      <p style={{ color: "red" }}>
                        Please select an option before checking out
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="container services_details_bottom_details_parent">
            <MDBRow className="justify-content-center align-items-center">
              <MDBCard style={{ borderRadius: "15px" }}>
                <MDBCardBody className="p-4">
                  <MDBTypography tag="h3">
                    <strong style={{ color: "var(--heading-color)" }}>
                      Description
                    </strong>
                  </MDBTypography>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-start align-items-center">
                    <MDBCardText className="mb-0">
                      <MDBCardText className="text-muted">
                        {isEditingDescription ? (
                          <div>
                            <div>
                              <TextEditor
                                initialDescription={editedDescription}
                                documentId={productId}
                                docFieldName="description"
                                collectionName="services"
                                onSave={() => handleSaveClick("description")}
                                onCancel={() =>
                                  handleCancelClick("description")
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: document.description.toString("html"),
                              }}
                            ></p>
                            {!isEditingDescription &&
                              user &&
                              user.role == "admin" && (
                                <FaEdit
                                  style={{
                                    fontSize: 20,
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "var(--pink)",
                                  }}
                                  onClick={() => handleEditClick("description")}
                                />
                              )}
                          </div>
                        )}
                      </MDBCardText>
                    </MDBCardText>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
            {/* </section> */}
          </div>
        </div>
      </div>
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </>
  );
};

export default ServiceDetails;
