import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../firebaseFiles/hooks/useAuthContext";
import { timestamp } from "../../firebase/config";
import { useFirestore } from "../../firebaseFiles/hooks/useFirestore";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../components/Popup";
import { useCollection } from "../../firebaseFiles/hooks/useCollection";
import { useDocument } from "../../firebaseFiles/hooks/useDocument";
import { useLocation } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

import "./AddBooking.css";
import { useEffect } from "react";

export default function BasicAddBooking() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { productId } = useParams();
  const { document: serviceDocument, error: serviceError } = useDocument(
    "services",
    productId
  );
  const { documents: allBookings, error: bookingsError } =
    useCollection("bookings");

  // Check if allBookings is not null before filtering
  const filteredBookings = allBookings
    ? allBookings.filter((booking) => booking.selectedProductId === productId)
    : [];

  const shifts = [
    "Morning 8AM-12PM",
    "Afternoon 12PM-4PM",
    "Evening 4PM-8PM",
    "Night 8PM-9PM",
  ];
  const currentDate = new Date();
  const dateOptions = [];
  for (let i = 0; i < 5; i++) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);
    dateOptions.push(date);
  }

  // Function to format the date as "dd"
  const formatDate = (date) => {
    const dayOfMonth = date.toLocaleDateString(undefined, { day: "2-digit" });
    return `${dayOfMonth}`;
  };

  // Function to format the date as "dd Mon'yy (Day)"
  const formatDateForAvailableSlots = (date) => {
    const dayOfMonth = date.toLocaleDateString(undefined, { day: "2-digit" });
    const month = date.toLocaleDateString(undefined, { month: "short" });
    const year = date.toLocaleDateString(undefined, { year: "2-digit" });
    const dayName = getDayName(date);
    return `${dayOfMonth} ${month}'${year} (${dayName})`;
  };

  // Function to get the full day name
  const getDayName = (date) => {
    return date.toLocaleDateString(undefined, { weekday: "short" });
  };

  // funtion to toggle am pm btn
  const [selectedPeriod, setSelectedPeriod] = useState("AM");
  const [halfHourSlots, setHalfHourSlots] = useState([]);
  const [checked, setChecked] = useState(true); // Toggle button state
  const [selectedShifts, setSelectedShifts] = useState(false);

  useEffect(() => {
    // Code for generating half-hour time slots based on selectedPeriod
    const generateHalfHourTimeSlots = (period) => {
      const timeSlots = [];
      let currentHour = period === "AM" ? 9 : 12;
      let currentMinute = 0;

      while (
        !(currentHour === (period === "AM" ? 12 : 18) && currentMinute === 0)
      ) {
        const formattedHour = currentHour === 12 ? 12 : currentHour % 12;
        const ampm = currentHour < 12 ? "" : "";
        const formattedMinute = currentMinute === 0 ? "00" : "30";

        timeSlots.push(`${formattedHour}:${formattedMinute} ${ampm}`);

        currentMinute += 30;
        if (currentMinute === 60) {
          currentMinute = 0;
          currentHour += 1;
        }
      }
      return timeSlots;
    };

    setHalfHourSlots(generateHalfHourTimeSlots(selectedPeriod));
  }, [selectedPeriod]);

  const handleChange = (val) => {
    // Toggle button change handler
    setChecked(val);

    // Update the selected period based on the toggle button state
    if (val) {
      setSelectedPeriod("AM");
    } else {
      setSelectedPeriod("PM");
    }
  };

  const searchParams = new URLSearchParams(location.search);
  // const doctorName = searchParams.get("doctor") || "";
  const doctorImageURL = searchParams.get("imageURL") || "";
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { addDocument, response } = useFirestore("bookings");
  // const [selectedProduct, getProductName] = useState(doctorName);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [status, setStatus] = useState("pending");
  const [formError, setFormError] = useState(null);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [customerName, setCustomerName] = useState(user.fullName);
  const [customerEmail, setCustomerEmail] = useState(user.email);
  const [customerPhone, setCustomerPhone] = useState(user.phoneNumber);
  const [addOnMessage, setAddOnMessage] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  console.log("message", addOnMessage);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);

    // Confirm the booking here
    const booking = {
      productName: serviceDocument.name,
      price: serviceDocument.price,
      status,
      doctorImageURL: serviceDocument.product_img_1,
      userName: user.fullName,
      userID: user.uid,
      status,
      addOnMessage,
      selectedShifts,
      BookingAmount: "2199",
      bookedDate: timestamp.fromDate(new Date(bookingDate)),
      paymentstatus: "unpaid",
      selectedProductId: productId,
    };

    await addDocument(booking);
    if (!response.error) {
      navigate("/bookinglist");
    }
  };

  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  useEffect(() => {
    if (popupReturn) {
      //Logout
      // logout();
    }
  }, [popupReturn]);
  const handleShiftsChange = (shifts) => {
    setSelectedShifts(shifts);
  };
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };
  const handleTimeSlotSelection = (slot) => {
    setSelectedTimeSlot(slot);
  };
  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <>
      {/* Popup Component */}
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to Book?"}
      />
      <div className="add_booking">
        <div className="container-fluid">
          <div className="row">
            {/* Doctors Details Section */}
            <section className="add_booking_doctor_parent">
              <MDBContainer className="addbooking_dr_data_parent">
                <MDBRow>
                  <MDBCol lg="4">
                    <MDBCard className="mb-4">
                      <MDBCardBody className="text-center">
                        <div className="addbooking_dr_img_parent">
                          <img
                            src={
                              serviceDocument && serviceDocument.product_img_1
                            }
                            alt={serviceDocument && serviceDocument.name}
                            className="addbooking_dr_img"
                          />
                        </div>
                        <p className="text-muted mb-1">
                          {serviceDocument && serviceDocument.name}
                        </p>
                        {/* <p className="text-muted mb-4"> ⭐4.8(63 Reviews)</p> */}
                        <div className="d-flex justify-content-center mb-2">
                          <div className="addbooking_dr_details_upper">
                            <span class="material-symbols-outlined addbooking_group_icon">
                              group
                            </span>
                            <h6 style={{ borderRight: "2px solid grey" }}>
                              {filteredBookings.length}+ <br />
                              bookings
                            </h6>
                            <span class="material-symbols-outlined addbooking_reward_icon">
                              currency_rupee
                            </span>
                            <h6>{serviceDocument && serviceDocument.price}</h6>
                          </div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                    {/* <section className="faq sect_padding add_booking_colleps">
                      <div className="container">
                        <div className="add_booking_colleps_child">
                          <div class="accordion" id="a4accordion_section">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="a4headingOne">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#a4collapseOne"
                                  aria-expanded="true"
                                  aria-controls="a4collapseOne"
                                >
                                  Other Details
                                </button>
                              </h2>
                              <div
                                id="a4collapseOne"
                                class="accordion-collapse collapse"
                                aria-labelledby="a4headingOne"
                                data-bs-parent="#a4accordion_section"
                              >
                                <p className="booking-collaps-heading">
                                  Service
                                </p>

                                <div class="accordion-body">
                                  Orthodontics In Dental Stream
                                </div>

                                <p className="booking-collaps-heading">
                                  Working
                                </p>
                                <div class="accordion-body">
                                  Mon - Fri 09:00 AM - 08:00 PM
                                </div>

                                <p className="booking-collaps-heading">About</p>
                                <div class="accordion-body">
                                  Accomplished Invisalign Certified Orthodontist
                                  With DHA Registration And 5 Years Of
                                  Experience
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> */}
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "40px" }}
                    >
                      <Link to="/bookinglist">
                        <button className="btn_fill">Booking History</button>
                      </Link>
                    </div>
                  </MDBCol>
                  <MDBCol lg="8">
                    <div className="mb-4 dr-details--lower">
                      {/* Add Booking Section */}
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 addbooking-bcard select_slot add_booking_timing_parent">
                            <div className="col-sm-12">
                              <div className="row">
                                <div>
                                  <input
                                    type="date"
                                    value={
                                      bookingDate.toISOString().split("T")[0]
                                    }
                                    min={new Date().toISOString().split("T")[0]}
                                    onChange={(event) =>
                                      setBookingDate(
                                        new Date(event.target.value)
                                      )
                                    }
                                    style={{
                                      borderRadius: "8px",
                                      border: "1px solid grey",
                                      padding: "5px 10px",
                                      margin: "10px 8px",
                                    }}
                                  />
                                </div>{" "}
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                  <div>
                                    {" "}
                                    {dateOptions.map((date, index) => (
                                      <button
                                        key={index}
                                        type="button"
                                        onClick={() => setBookingDate(date)}
                                        className={`${
                                          bookingDate &&
                                          date.toDateString() ===
                                            bookingDate.toDateString()
                                            ? "selectedDate" // Apply the class when selected
                                            : "add-booking-days-button "
                                        }`}
                                      >
                                        <div className="button-content">
                                          <div className="day-name">
                                            {getDayName(date)}
                                          </div>
                                          <div className="date">
                                            {formatDate(date)}
                                          </div>
                                        </div>
                                      </button>
                                    ))}
                                  </div>{" "}
                                  <div
                                    className="addbooking-ampm-button-container"
                                    style={{ marginTop: "15px" }}
                                  >
                                    {shifts.map((shifts) => (
                                      <button
                                        key={shifts}
                                        type="button"
                                        className={`half-hour-button ${
                                          selectedShifts === shifts
                                            ? "selected"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleShiftsChange(shifts)
                                        }
                                      >
                                        {shifts}
                                      </button>
                                    ))}
                                    <div className="bci_fields">
                                      <div className="form_field">
                                        <textarea
                                          type="textarea"
                                          onChange={(e) =>
                                            setAddOnMessage(e.target.value)
                                          }
                                          value={addOnMessage}
                                          placeholder="Purpose"
                                        />
                                        <div className="field_icon">
                                          <span class="material-symbols-outlined">
                                            add_comment
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-end">
                                    {selectedShifts ? (
                                      // Selected shift is truthy, allow form submission
                                      <button
                                        type="submit"
                                        className="btn_fill"
                                        style={{
                                          backgroundColor: "var(--pink)",
                                        }}
                                      >
                                        Submit
                                      </button>
                                    ) : (
                                      // No shift selected, display an error message
                                      <p className="error">
                                        Please select a shift before booking.
                                      </p>
                                    )}
                                    {formError && (
                                      <p className="error">{formError}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
